import { AnimationType } from "../Animation"

export enum Pkm {
  ABOMASNOW = "ABOMASNOW",
  ABRA = "ABRA",
  ABSOL = "ABSOL",
  AEGISLASH = "AEGISLASH",
  AEGISLASH_BLADE = "AEGISLASH_BLADE",
  AERODACTYL = "AERODACTYL",
  AGGRON = "AGGRON",
  AIPOM = "AIPOM",
  ALAKAZAM = "ALAKAZAM",
  ALOLAN_DIGLETT = "ALOLAN_DIGLETT",
  ALOLAN_DUGTRIO = "ALOLAN_DUGTRIO",
  ALOLAN_EXEGGUTOR = "ALOLAN_EXEGGUTOR",
  ALOLAN_GEODUDE = "ALOLAN_GEODUDE",
  ALOLAN_GOLEM = "ALOLAN_GOLEM",
  ALOLAN_GRAVELER = "ALOLAN_GRAVELER",
  ALOLAN_GRIMER = "ALOLAN_GRIMER",
  ALOLAN_MAROWAK = "ALOLAN_MAROWAK",
  ALOLAN_MEOWTH = "ALOLAN_MEOWTH",
  ALOLAN_MUK = "ALOLAN_MUK",
  ALOLAN_NINETALES = "ALOLAN_NINETALES",
  ALOLAN_PERSIAN = "ALOLAN_PERSIAN",
  ALOLAN_RAICHU = "ALOLAN_RAICHU",
  ALOLAN_RATICATE = "ALOLAN_RATICATE",
  ALOLAN_RATTATA = "ALOLAN_RATTATA",
  ALOLAN_VULPIX = "ALOLAN_VULPIX",
  ALTARIA = "ALTARIA",
  AMAURA = "AMAURA",
  AMBIPOM = "AMBIPOM",
  AMPHAROS = "AMPHAROS",
  ANNIHILAPE = "ANNIHILAPE",
  ANORITH = "ANORITH",
  APPLETUN = "APPLETUN",
  APPLIN = "APPLIN",
  ARAQUANID = "ARAQUANID",
  ARBOK = "ARBOK",
  ARCANINE = "ARCANINE",
  ARCEUS = "ARCEUS",
  ARCHEN = "ARCHEN",
  ARCHEOPS = "ARCHEOPS",
  ARCTIBAX = "ARCTIBAX",
  ARIADOS = "ARIADOS",
  ARMALDO = "ARMALDO",
  ARON = "ARON",
  ARROKUDA = "ARROKUDA",
  ARTICUNO = "ARTICUNO",
  AUDINO = "AUDINO",
  AURORUS = "AURORUS",
  AXEW = "AXEW",
  AZELF = "AZELF",
  AZUMARILL = "AZUMARILL",
  AZURILL = "AZURILL",
  BAGON = "BAGON",
  BALTOY = "BALTOY",
  BANETTE = "BANETTE",
  BARBARACLE = "BARBARACLE",
  BARBOACH = "BARBOACH",
  BASTIODON = "BASTIODON",
  BAXCALIBUR = "BAXCALIBUR",
  BAYLEEF = "BAYLEEF",
  BEAUTIFLY = "BEAUTIFLY",
  BEEDRILL = "BEEDRILL",
  BEHEEYEM = "BEHEEYEM",
  BELDUM = "BELDUM",
  BELLOSSOM = "BELLOSSOM",
  BELLSPROUT = "BELLSPROUT",
  BIBAREL = "BIBAREL",
  BIDOOF = "BIDOOF",
  BINACLE = "BINACLE",
  BISHARP = "BISHARP",
  BLACEPHALON = "BLACEPHALON",
  BLASTOISE = "BLASTOISE",
  BLAZIKEN = "BLAZIKEN",
  BLISSEY = "BLISSEY",
  BONSLEY = "BONSLEY",
  BOUNSWEET = "BOUNSWEET",
  BRAIXEN = "BRAIXEN",
  BRAVIARY = "BRAVIARY",
  BRELOOM = "BRELOOM",
  BRIONNE = "BRIONNE",
  BRONZONG = "BRONZONG",
  BRONZOR = "BRONZOR",
  BRUXISH = "BRUXISH",
  BUDEW = "BUDEW",
  BUIZEL = "BUIZEL",
  BULBASAUR = "BULBASAUR",
  BUNEARY = "BUNEARY",
  BURMY_PLANT = "BURMY_PLANT",
  BURMY_SANDY = "BURMY_SANDY",
  BURMY_TRASH = "BURMY_TRASH",
  BUTTERFREE = "BUTTERFREE",
  CACNEA = "CACNEA",
  CACTURNE = "CACTURNE",
  CAMERUPT = "CAMERUPT",
  CARBINK = "CARBINK",
  CARNIVINE = "CARNIVINE",
  //CARRACOSTA = "CARRACOSTA",
  CARVANHA = "CARVANHA",
  CASCOON = "CASCOON",
  CASTFORM = "CASTFORM",
  CASTFORM_HAIL = "CASTFORM_HAIL",
  CASTFORM_RAIN = "CASTFORM_RAIN",
  CASTFORM_SUN = "CASTFORM_SUN",
  CATERPIE = "CATERPIE",
  CELEBI = "CELEBI",
  CENTISKORCH = "CENTISKORCH",
  CHANDELURE = "CHANDELURE",
  CHANSEY = "CHANSEY",
  CHARIZARD = "CHARIZARD",
  CHARJABUG = "CHARJABUG",
  CHARMANDER = "CHARMANDER",
  CHARMELEON = "CHARMELEON",
  CHATOT = "CHATOT",
  CHERRIM = "CHERRIM",
  CHERRIM_SUNLIGHT = "CHERRIM_SUNLIGHT",
  CHERRUBI = "CHERRUBI",
  CHIKORITA = "CHIKORITA",
  CHIMCHAR = "CHIMCHAR",
  CHIMECHO = "CHIMECHO",
  CHINCHOU = "CHINCHOU",
  CHINGLING = "CHINGLING",
  CINDERACE = "CINDERACE",
  CLAMPERL = "CLAMPERL",
  CLAUNCHER = "CLAUNCHER",
  CLAWITZER = "CLAWITZER",
  CLAYDOL = "CLAYDOL",
  CLEFABLE = "CLEFABLE",
  CLEFAIRY = "CLEFAIRY",
  CLEFFA = "CLEFFA",
  CLODSIRE = "CLODSIRE",
  CLOYSTER = "CLOYSTER",
  COBALION = "COBALION",
  COMBEE = "COMBEE",
  COMBUSKEN = "COMBUSKEN",
  COMFEY = "COMFEY",
  CONKELDURR = "CONKELDURR",
  CORPHISH = "CORPHISH",
  CORSOLA = "CORSOLA",
  CORVIKNIGHT = "CORVIKNIGHT",
  CORVISQUIRE = "CORVISQUIRE",
  COSMOEM = "COSMOEM",
  COSMOG = "COSMOG",
  CRABOMINABLE = "CRABOMINABLE",
  CRABRAWLER = "CRABRAWLER",
  CRADILY = "CRADILY",
  CRAMORANT = "CRAMORANT",
  CRANIDOS = "CRANIDOS",
  CRAWDAUNT = "CRAWDAUNT",
  CRESSELIA = "CRESSELIA",
  CROAGUNK = "CROAGUNK",
  CROBAT = "CROBAT",
  CROCALOR = "CROCALOR",
  CROCONAW = "CROCONAW",
  CUBONE = "CUBONE",
  CURSOLA = "CURSOLA",
  CUTIEFLY = "CUTIEFLY",
  CYCLIZAR = "CYCLIZAR",
  CYNDAQUIL = "CYNDAQUIL",
  DARKRAI = "DARKRAI",
  DARMANITAN = "DARMANITAN",
  DARMANITAN_ZEN = "DARMANITAN_ZEN",
  DARTIX = "DARTIX",
  DARUMAKA = "DARUMAKA",
  DECIDUEYE = "DECIDUEYE",
  DEERLING = "DEERLING",
  DEFAULT = "DEFAULT",
  DEINO = "DEINO",
  DELIBIRD = "DELIBIRD",
  DELPHOX = "DELPHOX",
  DEOXYS = "DEOXYS",
  DEOXYS_ATTACK = "DEOXYS_ATTACK",
  DEOXYS_DEFENSE = "DEOXYS_DEFENSE",
  DEOXYS_SPEED = "DEOXYS_SPEED",
  DEWGONG = "DEWGONG",
  DEWOTT = "DEWOTT",
  DEWPIDER = "DEWPIDER",
  DHELMISE = "DHELMISE",
  DIALGA = "DIALGA",
  DIANCIE = "DIANCIE",
  DIGLETT = "DIGLETT",
  DITTO = "DITTO",
  DODRIO = "DODRIO",
  DODUO = "DODUO",
  DONPHAN = "DONPHAN",
  DOUBLADE = "DOUBLADE",
  DRACOVISH = "DRACOVISH",
  DRAGAPULT = "DRAGAPULT",
  DRAGONAIR = "DRAGONAIR",
  DRAGONITE = "DRAGONITE",
  DRAKLOAK = "DRAKLOAK",
  DRAPION = "DRAPION",
  DRATINI = "DRATINI",
  DREEPY = "DREEPY",
  DRIFBLIM = "DRIFBLIM",
  DRIFLOON = "DRIFLOON",
  DRIZZILE = "DRIZZILE",
  DROWZEE = "DROWZEE",
  DRUDDIGON = "DRUDDIGON",
  DUGTRIO = "DUGTRIO",
  DUOSION = "DUOSION",
  DURANT = "DURANT",
  DUSCLOPS = "DUSCLOPS",
  DUSKNOIR = "DUSKNOIR",
  DUSKULL = "DUSKULL",
  DUSTOX = "DUSTOX",
  EEVEE = "EEVEE",
  EGG = "EGG",
  EKANS = "EKANS",
  ELECTABUZZ = "ELECTABUZZ",
  ELECTIVIRE = "ELECTIVIRE",
  ELECTRIKE = "ELECTRIKE",
  ELECTRODE = "ELECTRODE",
  ELEKID = "ELEKID",
  ELGYEM = "ELGYEM",
  EMBOAR = "EMBOAR",
  EMPOLEON = "EMPOLEON",
  ENAMORUS = "ENAMORUS",
  ENTEI = "ENTEI",
  ESPEON = "ESPEON",
  ETERNATUS = "ETERNATUS",
  EXEGGCUTE = "EXEGGCUTE",
  EXEGGUTOR = "EXEGGUTOR",
  EXPLOUD = "EXPLOUD",
  FARFETCH_D = "FARFETCH_D",
  FEAROW = "FEAROW",
  FEEBAS = "FEEBAS",
  FENNEKIN = "FENNEKIN",
  FERALIGATR = "FERALIGATR",
  FERROSEED = "FERROSEED",
  FERROTHORN = "FERROTHORN",
  FINNEON = "FINNEON",
  FLABEBE = "FLABEBE",
  FLAFFY = "FLAFFY",
  FLAREON = "FLAREON",
  FLETCHINDER = "FLETCHINDER",
  FLETCHLING = "FLETCHLING",
  FLOATZEL = "FLOATZEL",
  FLOETTE = "FLOETTE",
  FLORGES = "FLORGES",
  FLYGON = "FLYGON",
  FORRETRESS = "FORRETRESS",
  FRAXURE = "FRAXURE",
  FRIGIBAX = "FRIGIBAX",
  FROAKIE = "FROAKIE",
  FROGADIER = "FROGADIER",
  FROSLASS = "FROSLASS",
  FROSMOTH = "FROSMOTH",
  FUECOCO = "FUECOCO",
  FURRET = "FURRET",
  GABITE = "GABITE",
  GALAR_CORSOLA = "GALAR_CORSOLA",
  GALARIAN_ARTICUNO = "GALARIAN_ARTICUNO",
  GALARIAN_ZAPDOS = "GALARIAN_ZAPDOS",
  GALARIAN_MOLTRES = "GALARIAN_MOLTRES",
  GALARIAN_PONYTA = "GALARIAN_PONYTA",
  GALARIAN_RAPIDASH = "GALARIAN_RAPIDASH",
  GALVANTULA = "GALVANTULA",
  GARBODOR = "GARBODOR",
  GARCHOMP = "GARCHOMP",
  GARDEVOIR = "GARDEVOIR",
  GASTLY = "GASTLY",
  GASTRODON_EAST_SEA = "GASTRODON_EAST_SEA",
  GASTRODON_WEST_SEA = "GASTRODON_WEST_SEA",
  GENESECT = "GENESECT",
  GENGAR = "GENGAR",
  GEODUDE = "GEODUDE",
  GHOLDENGO = "GHOLDENGO",
  GIBLE = "GIBLE",
  GIMMIGHOUL = "GIMMIGHOUL",
  GIRATINA = "GIRATINA",
  GLACEON = "GLACEON",
  GLALIE = "GLALIE",
  GLIGAR = "GLIGAR",
  GLISCOR = "GLISCOR",
  GLIMMET = "GLIMMET",
  GLIMMORA = "GLIMMORA",
  GLOOM = "GLOOM",
  GOLBAT = "GOLBAT",
  GOLDEEN = "GOLDEEN",
  GOLDUCK = "GOLDUCK",
  GOLEM = "GOLEM",
  GOLETT = "GOLETT",
  GOLURK = "GOLURK",
  GOODRA = "GOODRA",
  GOOMY = "GOOMY",
  GOREBYSS = "GOREBYSS",
  GOTHITA = "GOTHITA",
  GOTHITELLE = "GOTHITELLE",
  GOTHORITA = "GOTHORITA",
  GOURGEIST = "GOURGEIST",
  GRANBULL = "GRANBULL",
  GRAVELER = "GRAVELER",
  GRENINJA = "GRENINJA",
  GRIMER = "GRIMER",
  GRIMMSNARL = "GRIMMSNARL",
  GROTLE = "GROTLE",
  GROUDON = "GROUDON",
  GROVYLE = "GROVYLE",
  GROWLITHE = "GROWLITHE",
  GRUBBIN = "GRUBBIN",
  GRUMPIG = "GRUMPIG",
  GURDURR = "GURDURR",
  GUZZLORD = "GUZZLORD",
  GYARADOS = "GYARADOS",
  HAKAMO_O = "HAKAMO_O",
  HAPPINY = "HAPPINY",
  HARIYAMA = "HARIYAMA",
  HATENNA = "HATENNA",
  HATTERENE = "HATTERENE",
  HATTREM = "HATTREM",
  HAUNTER = "HAUNTER",
  HAWLUCHA = "HAWLUCHA",
  HAXORUS = "HAXORUS",
  HEATMOR = "HEATMOR",
  HEATRAN = "HEATRAN",
  HELIOLISK = "HELIOLISK",
  HELIOPTILE = "HELIOPTILE",
  HERACROSS = "HERACROSS",
  HERDIER = "HERDIER",
  HIPPODOWN = "HIPPODOWN",
  HIPPOPOTAS = "HIPPOPOTAS",
  HISUI_ARCANINE = "HISUI_ARCANINE",
  HISUI_ELECTRODE = "HISUI_ELECTRODE",
  HISUI_GOODRA = "HISUI_GOODRA",
  HISUI_GROWLITHE = "HISUI_GROWLITHE",
  HISUI_SLIGGOO = "HISUI_SLIGGOO",
  HISUI_SNEASEL = "HISUI_SNEASEL",
  HISUI_VOLTORB = "HISUI_VOLTORB",
  HISUI_ZOROARK = "HISUI_ZOROARK",
  HISUI_ZORUA = "HISUI_ZORUA",
  HISUIAN_QWILFISH = "HISUIAN_QWILFISH",
  HISUIAN_TYPHLOSION = "HISUIAN_TYPHLOSION",
  HITMONCHAN = "HITMONCHAN",
  HITMONLEE = "HITMONLEE",
  HITMONTOP = "HITMONTOP",
  HO_OH = "HO_OH",
  HONCHKROW = "HONCHKROW",
  HONEDGE = "HONEDGE",
  HOOPA = "HOOPA",
  HOOPA_UNBOUND = "HOOPA_UNBOUND",
  HOOTHOOT = "HOOTHOOT",
  HOPPIP = "HOPPIP",
  HORSEA = "HORSEA",
  HOUNDOOM = "HOUNDOOM",
  HOUNDOUR = "HOUNDOUR",
  HUNTAIL = "HUNTAIL",
  HYDREIGON = "HYDREIGON",
  HYPNO = "HYPNO",
  IGGLYBUFF = "IGGLYBUFF",
  ILLUMISE = "ILLUMISE",
  IMPIDIMP = "IMPIDIMP",
  INCINEROAR = "INCINEROAR",
  INFERNAPE = "INFERNAPE",
  INTELEON = "INTELEON",
  IRON_BUNDLE = "IRON_BUNDLE",
  IRON_HANDS = "IRON_HANDS",
  IVYSAUR = "IVYSAUR",
  JANGMO_O = "JANGMO_O",
  JIGGLYPUFF = "JIGGLYPUFF",
  JIRACHI = "JIRACHI",
  JOLTEON = "JOLTEON",
  JOLTIK = "JOLTIK",
  JUMPLUFF = "JUMPLUFF",
  JYNX = "JYNX",
  KABUTO = "KABUTO",
  KABUTOPS = "KABUTOPS",
  KADABRA = "KADABRA",
  KAKUNA = "KAKUNA",
  KANGASKHAN = "KANGASKHAN",
  KARTANA = "KARTANA",
  KECLEON = "KECLEON",
  KELDEO = "KELDEO",
  KILOWATTREL = "KILOWATTREL",
  KINGAMBIT = "KINGAMBIT",
  KINGDRA = "KINGDRA",
  KINGLER = "KINGLER",
  KIRLIA = "KIRLIA",
  KLEAVOR = "KLEAVOR",
  KLEFKI = "KLEFKI",
  KOFFING = "KOFFING",
  KOMMO_O = "KOMMO_O",
  KRABBY = "KRABBY",
  KRICKETOT = "KRICKETOT",
  KRICKETUNE = "KRICKETUNE",
  KROKOROK = "KROKOROK",
  KROOKODILE = "KROOKODILE",
  KYOGRE = "KYOGRE",
  KYUREM = "KYUREM",
  LAIRON = "LAIRON",
  LAMPENT = "LAMPENT",
  LANDORUS = "LANDORUS",
  LANTURN = "LANTURN",
  LAPRAS = "LAPRAS",
  LARVESTA = "LARVESTA",
  LARVITAR = "LARVITAR",
  LATIAS = "LATIAS",
  LATIOS = "LATIOS",
  LEAFEON = "LEAFEON",
  LEAVANNY = "LEAVANNY",
  LICKILICKY = "LICKILICKY",
  LICKITUNG = "LICKITUNG",
  LIEPARD = "LIEPARD",
  LILEEP = "LILEEP",
  LILIGANT = "LILIGANT",
  LILLIPUP = "LILLIPUP",
  LINOONE = "LINOONE",
  LITTEN = "LITTEN",
  LITWICK = "LITWICK",
  LOMBRE = "LOMBRE",
  LOPUNNY = "LOPUNNY",
  LOTAD = "LOTAD",
  LOUDRED = "LOUDRED",
  LUCARIO = "LUCARIO",
  LUDICOLO = "LUDICOLO",
  LUGIA = "LUGIA",
  LUMINEON = "LUMINEON",
  LUNALA = "LUNALA",
  LUNATONE = "LUNATONE",
  LUVDISC = "LUVDISC",
  LUXIO = "LUXIO",
  LUXRAY = "LUXRAY",
  LYCANROC_DAY = "LYCANROC_DAY",
  LYCANROC_DUSK = "LYCANROC_DUSK",
  LYCANROC_NIGHT = "LYCANROC_NIGHT",
  MACHAMP = "MACHAMP",
  MACHOKE = "MACHOKE",
  MACHOP = "MACHOP",
  MAGBY = "MAGBY",
  MAGCARGO = "MAGCARGO",
  MAGEARNA = "MAGEARNA",
  MAGIKARP = "MAGIKARP",
  MAGMAR = "MAGMAR",
  MAGMORTAR = "MAGMORTAR",
  MAGNEMITE = "MAGNEMITE",
  MAGNETON = "MAGNETON",
  MAGNEZONE = "MAGNEZONE",
  MAKUHITA = "MAKUHITA",
  MAMOSWINE = "MAMOSWINE",
  MANAPHY = "MANAPHY",
  MANECTRIC = "MANECTRIC",
  MANKEY = "MANKEY",
  MANTINE = "MANTINE",
  MANTYKE = "MANTYKE",
  MARACTUS = "MARACTUS",
  MAREEP = "MAREEP",
  MARILL = "MARILL",
  MAROWAK = "MAROWAK",
  MARSHADOW = "MARSHADOW",
  MARSHTOMP = "MARSHTOMP",
  MAUSHOLD_FOUR = "MAUSHOLD_FOUR",
  MAUSHOLD_THREE = "MAUSHOLD_THREE",
  MAWILE = "MAWILE",
  MEDICHAM = "MEDICHAM",
  MEDITITE = "MEDITITE",
  MEGA_ABOMASNOW = "MEGA_ABOMASNOW",
  MEGA_ALTARIA = "MEGA_ALTARIA",
  MEGA_BANETTE = "MEGA_BANETTE",
  MEGA_CAMERUPT = "MEGA_CAMERUPT",
  MEGA_HOUNDOOM = "MEGA_HOUNDOOM",
  MEGA_LOPUNNY = "MEGA_LOPUNNY",
  MEGA_MANECTRIC = "MEGA_MANECTRIC",
  MEGA_RAYQUAZA = "MEGA_RAYQUAZA",
  MEGA_SABLEYE = "MEGA_SABLEYE",
  MEGA_STEELIX = "MEGA_STEELIX",
  MEGANIUM = "MEGANIUM",
  MELMETAL = "MELMETAL",
  MELOETTA = "MELOETTA",
  MEOWTH = "MEOWTH",
  MESPRIT = "MESPRIT",
  METAGROSS = "METAGROSS",
  METANG = "METANG",
  METAPOD = "METAPOD",
  MEW = "MEW",
  MEWTWO = "MEWTWO",
  MIENFOO = "MIENFOO",
  MIENSHAO = "MIENSHAO",
  MIGHTYENA = "MIGHTYENA",
  MILOTIC = "MILOTIC",
  MILTANK = "MILTANK",
  MIME_JR = "MIME_JR",
  MIMIKYU = "MIMIKYU",
  MIMIKYU_BUSTED = "MIMIKYU_BUSTED",
  MINIOR = "MINIOR",
  MINIOR_KERNEL_BLUE = "MINIOR_KERNEL_BLUE",
  MINIOR_KERNEL_GREEN = "MINIOR_KERNEL_GREEN",
  MINIOR_KERNEL_ORANGE = "MINIOR_KERNEL_ORANGE",
  MINIOR_KERNEL_RED = "MINIOR_KERNEL_RED",
  MINUN = "MINUN",
  MISDREAVUS = "MISDREAVUS",
  MISMAGIUS = "MISMAGIUS",
  MOLTRES = "MOLTRES",
  MONFERNO = "MONFERNO",
  MORGREM = "MORGREM",
  MORPEKO = "MORPEKO",
  MORPEKO_HANGRY = "MORPEKO_HANGRY",
  MOTHIM = "MOTHIM",
  MR_MIME = "MR_MIME",
  MUDKIP = "MUDKIP",
  MUK = "MUK",
  MUNCHLAX = "MUNCHLAX",
  MUNNA = "MUNNA",
  MURKROW = "MURKROW",
  MUSHARNA = "MUSHARNA",
  NAGANADEL = "NAGANADEL",
  NATU = "NATU",
  NECROZMA = "NECROZMA",
  NICKIT = "NICKIT",
  NIDOKING = "NIDOKING",
  NIDOQUEEN = "NIDOQUEEN",
  NIDORANF = "NIDORANF",
  NIDORANM = "NIDORANM",
  NIDORINA = "NIDORINA",
  NIDORINO = "NIDORINO",
  NIHILEGO = "NIHILEGO",
  NINCADA = "NINCADA",
  NINETALES = "NINETALES",
  NINJASK = "NINJASK",
  NOCTOWL = "NOCTOWL",
  NOIBAT = "NOIBAT",
  NOIVERN = "NOIVERN",
  NOSEPASS = "NOSEPASS",
  NUMEL = "NUMEL",
  NUZLEAF = "NUZLEAF",
  OCTILLERY = "OCTILLERY",
  ODDISH = "ODDISH",
  OGERPON_CORNERSTONE = "OGERPON_CORNERSTONE",
  OGERPON_CORNERSTONE_MASK = "OGERPON_CORNERSTONE_MASK",
  OGERPON_HEARTHFLAME = "OGERPON_HEARTHFLAME",
  OGERPON_HEARTHFLAME_MASK = "OGERPON_HEARTHFLAME_MASK",
  OGERPON_TEAL = "OGERPON_TEAL",
  OGERPON_TEAL_MASK = "OGERPON_TEAL_MASK",
  OGERPON_WELLSPRING = "OGERPON_WELLSPRING",
  OGERPON_WELLSPRING_MASK = "OGERPON_WELLSPRING_MASK",
  OMANYTE = "OMANYTE",
  OMASTAR = "OMASTAR",
  ONIX = "ONIX",
  ORIGIN_GIRATINA = "ORIGIN_GIRATINA",
  OSHAWOTT = "OSHAWOTT",
  OVERQWIL = "OVERQWIL",
  PALDEA_WOOPER = "PALDEA_WOOPER",
  PALKIA = "PALKIA",
  PALOSSAND = "PALOSSAND",
  PALPITOAD = "PALPITOAD",
  PARAS = "PARAS",
  PARASECT = "PARASECT",
  PATRAT = "PATRAT",
  PAWMI = "PAWMI",
  PAWMO = "PAWMO",
  PAWMOT = "PAWMOT",
  PAWNIARD = "PAWNIARD",
  PELIPPER = "PELIPPER",
  PERSIAN = "PERSIAN",
  PETILIL = "PETILIL",
  PHANPY = "PHANPY",
  PHANTUMP = "PHANTUMP",
  PHEROMOSA = "PHEROMOSA",
  PHIONE = "PHIONE",
  PICHU = "PICHU",
  PIDGEOT = "PIDGEOT",
  PIDGEOTTO = "PIDGEOTTO",
  PIDGEY = "PIDGEY",
  PIGNITE = "PIGNITE",
  PIKACHU = "PIKACHU",
  //PIKIPEK = "PIKIPEK",
  PILLAR_WOOD = "PILLAR_WOOD",
  PILLAR_IRON = "PILLAR_IRON",
  PILLAR_CONCRETE = "PILLAR_CONCRETE",
  PILOSWINE = "PILOSWINE",
  PINECO = "PINECO",
  PINSIR = "PINSIR",
  PIPLUP = "PIPLUP",
  PIROUETTE_MELOETTA = "PIROUETTE_MELOETTA",
  PLUSLE = "PLUSLE",
  POIPOLE = "POIPOLE",
  POLITOED = "POLITOED",
  POLIWAG = "POLIWAG",
  POLIWHIRL = "POLIWHIRL",
  POLIWRATH = "POLIWRATH",
  POLTEAGEIST = "POLTEAGEIST",
  PONYTA = "PONYTA",
  POOCHYENA = "POOCHYENA",
  POPPLIO = "POPPLIO",
  PORYGON = "PORYGON",
  PORYGON_2 = "PORYGON_2",
  PORYGON_Z = "PORYGON_Z",
  PRIMAL_GROUDON = "PRIMAL_GROUDON",
  PRIMAL_KYOGRE = "PRIMAL_KYOGRE",
  PRIMARINA = "PRIMARINA",
  PRIMEAPE = "PRIMEAPE",
  PRINPLUP = "PRINPLUP",
  PROBOPASS = "PROBOPASS",
  PSYDUCK = "PSYDUCK",
  PUMPKABOO = "PUMPKABOO",
  PUPITAR = "PUPITAR",
  PURRLOIN = "PURRLOIN",
  PYUKUMUKU = "PYUKUMUKU",
  QUILAVA = "QUILAVA",
  RABOOT = "RABOOT",
  RAICHU = "RAICHU",
  RAIKOU = "RAIKOU",
  RALTS = "RALTS",
  RAMPARDOS = "RAMPARDOS",
  RAPIDASH = "RAPIDASH",
  RATICATE = "RATICATE",
  RATTATA = "RATTATA",
  RAYQUAZA = "RAYQUAZA",
  REGICE = "REGICE",
  REGIDRAGO = "REGIDRAGO",
  REGIELEKI = "REGIELEKI",
  REGIGIGAS = "REGIGIGAS",
  REGIROCK = "REGIROCK",
  REGISTEEL = "REGISTEEL",
  RELICANTH = "RELICANTH",
  REMORAID = "REMORAID",
  RESHIRAM = "RESHIRAM",
  REUNICLUS = "REUNICLUS",
  RHYDON = "RHYDON",
  RHYHORN = "RHYHORN",
  RHYPERIOR = "RHYPERIOR",
  RIBOMBEE = "RIBOMBEE",
  RIOLU = "RIOLU",
  ROCKRUFF = "ROCKRUFF",
  ROOKIDEE = "ROOKIDEE",
  ROSELIA = "ROSELIA",
  ROSERADE = "ROSERADE",
  ROTOM = "ROTOM",
  ROTOM_DRONE = "ROTOM_DRONE",
  ROWLET = "ROWLET",
  RUFFLET = "RUFFLET",
  SABLEYE = "SABLEYE",
  SALAMENCE = "SALAMENCE",
  SALANDIT = "SALANDIT",
  SALAZZLE = "SALAZZLE",
  STUFFUL = "STUFFUL",
  BEWEAR = "BEWEAR",
  SAMUROTT = "SAMUROTT",
  SANDILE = "SANDILE",
  SANDSHREW = "SANDSHREW",
  SANDSLASH = "SANDSLASH",
  SANDYGAST = "SANDYGAST",
  SAWSBUCK = "SAWSBUCK",
  SCEPTILE = "SCEPTILE",
  SCIZOR = "SCIZOR",
  SCOLIPEDE = "SCOLIPEDE",
  SCORBUNNY = "SCORBUNNY",
  SCRAFTY = "SCRAFTY",
  SCRAGGY = "SCRAGGY",
  SCYTHER = "SCYTHER",
  SEADRA = "SEADRA",
  SEAKING = "SEAKING",
  SEALEO = "SEALEO",
  SEEDOT = "SEEDOT",
  SEEL = "SEEL",
  SEISMITOAD = "SEISMITOAD",
  SENTRET = "SENTRET",
  SERPERIOR = "SERPERIOR",
  SERVINE = "SERVINE",
  SEVIPER = "SEVIPER",
  SEWADDLE = "SEWADDLE",
  SHADOW_LUGIA = "SHADOW_LUGIA",
  SHARPEDO = "SHARPEDO",
  SHAYMIN = "SHAYMIN",
  SHAYMIN_SKY = "SHAYMIN_SKY",
  SHEDINJA = "SHEDINJA",
  SHELGON = "SHELGON",
  SHELLDER = "SHELLDER",
  SHELLOS_EAST_SEA = "SHELLOS_EAST_SEA",
  SHELLOS_WEST_SEA = "SHELLOS_WEST_SEA",
  SHIELDON = "SHIELDON",
  SHIFTRY = "SHIFTRY",
  SHINX = "SHINX",
  SHROOMISH = "SHROOMISH",
  SHUCKLE = "SHUCKLE",
  SHUPPET = "SHUPPET",
  SIGILYPH = "SIGILYPH",
  SILCOON = "SILCOON",
  SILVALLY = "SILVALLY",
  SINISTEA = "SINISTEA",
  SIZZLIPEDE = "SIZZLIPEDE",
  SKARMORY = "SKARMORY",
  SKELEDIRGE = "SKELEDIRGE",
  SKIPLOOM = "SKIPLOOM",
  SKORUPI = "SKORUPI",
  SKUNTANK = "SKUNTANK",
  SLAKING = "SLAKING",
  SLAKOTH = "SLAKOTH",
  SLIGOO = "SLIGOO",
  SLOWBRO = "SLOWBRO",
  SLOWKING = "SLOWKING",
  SLOWPOKE = "SLOWPOKE",
  SLUGMA = "SLUGMA",
  SMEARGLE = "SMEARGLE",
  SMOOCHUM = "SMOOCHUM",
  SNEASEL = "SNEASEL",
  SNEASLER = "SNEASLER",
  SNIVY = "SNIVY",
  SNOM = "SNOM",
  SNORLAX = "SNORLAX",
  SNORUNT = "SNORUNT",
  SNOVER = "SNOVER",
  SNUBULL = "SNUBULL",
  SOBBLE = "SOBBLE",
  SOLGALEO = "SOLGALEO",
  SOLOSIS = "SOLOSIS",
  SOLROCK = "SOLROCK",
  SPEAROW = "SPEAROW",
  SPECTRIER = "SPECTRIER",
  SPHEAL = "SPHEAL",
  SPINARAK = "SPINARAK",
  SPINDA = "SPINDA",
  SPIRITOMB = "SPIRITOMB",
  SPOINK = "SPOINK",
  SQUIRTLE = "SQUIRTLE",
  STAKATAKA = "STAKATAKA",
  STANTLER = "STANTLER",
  STARAPTOR = "STARAPTOR",
  STARAVIA = "STARAVIA",
  STARLY = "STARLY",
  STARMIE = "STARMIE",
  STARYU = "STARYU",
  STEELIX = "STEELIX",
  STEENEE = "STEENEE",
  STONJOURNER = "STONJOURNER",
  STOUTLAND = "STOUTLAND",
  STUNKY = "STUNKY",
  SUBSTITUTE = "SUBSTITUTE",
  SUDOWOODO = "SUDOWOODO",
  SUICUNE = "SUICUNE",
  SUNFLORA = "SUNFLORA",
  SUNKERN = "SUNKERN",
  SWABLU = "SWABLU",
  SWADLOON = "SWADLOON",
  SWAMPERT = "SWAMPERT",
  SWINUB = "SWINUB",
  SWOOBAT = "SWOOBAT",
  SYLVEON = "SYLVEON",
  TALONFLAME = "TALONFLAME",
  TANDEMAUS = "TANDEMAUS",
  TANGELA = "TANGELA",
  TANGROWTH = "TANGROWTH",
  TAPU_BULU = "TAPU_BULU",
  TAPU_FINI = "TAPU_FINI",
  TAPU_KOKO = "TAPU_KOKO",
  TAPU_LELE = "TAPU_LELE",
  TAUROS = "TAUROS",
  TEDDIURSA = "TEDDIURSA",
  TENTACOOL = "TENTACOOL",
  TENTACRUEL = "TENTACRUEL",
  TEPIG = "TEPIG",
  TERRAKION = "TERRAKION",
  THIEVUL = "THIEVUL",
  THUNDURUS = "THUNDURUS",
  TIMBURR = "TIMBURR",
  TINKATINK = "TINKATINK",
  TINKATON = "TINKATON",
  TINKATUFF = "TINKATUFF",
  //TIRTOUGA = "TIRTOUGA",
  TOGEKISS = "TOGEKISS",
  TOGEPI = "TOGEPI",
  TOGETIC = "TOGETIC",
  TORCHIC = "TORCHIC",
  TORKOAL = "TORKOAL",
  TORNADUS = "TORNADUS",
  TORRACAT = "TORRACAT",
  TORTERRA = "TORTERRA",
  TOTODILE = "TOTODILE",
  //TOUCANNON = "TOUCANNON",
  TOXEL = "TOXEL",
  TOXICROAK = "TOXICROAK",
  TOXTRICITY = "TOXTRICITY",
  TRAPINCH = "TRAPINCH",
  TREECKO = "TREECKO",
  TREVENANT = "TREVENANT",
  TROPIUS = "TROPIUS",
  TRUBBISH = "TRUBBISH",
  //TRUMBEAK = "TRUMBEAK",
  TSAREENA = "TSAREENA",
  TURTONATOR = "TURTONATOR",
  TURTWIG = "TURTWIG",
  TYMPOLE = "TYMPOLE",
  TYPE_NULL = "TYPE_NULL",
  TYPHLOSION = "TYPHLOSION",
  TYRANITAR = "TYRANITAR",
  TYRANTRUM = "TYRANTRUM",
  TYROGUE = "TYROGUE",
  TYRUNT = "TYRUNT",
  ULTRA_NECROZMA = "ULTRA_NECROZMA",
  UMBREON = "UMBREON",
  UNOWN_A = "UNOWN_A",
  UNOWN_B = "UNOWN_B",
  UNOWN_C = "UNOWN_C",
  UNOWN_D = "UNOWN_D",
  UNOWN_E = "UNOWN_E",
  UNOWN_EXCLAMATION = "UNOWN_EXCLAMATION",
  UNOWN_F = "UNOWN_F",
  UNOWN_G = "UNOWN_G",
  UNOWN_H = "UNOWN_H",
  UNOWN_I = "UNOWN_I",
  UNOWN_J = "UNOWN_J",
  UNOWN_K = "UNOWN_K",
  UNOWN_L = "UNOWN_L",
  UNOWN_M = "UNOWN_M",
  UNOWN_N = "UNOWN_N",
  UNOWN_O = "UNOWN_O",
  UNOWN_P = "UNOWN_P",
  UNOWN_Q = "UNOWN_Q",
  UNOWN_QUESTION = "UNOWN_QUESTION",
  UNOWN_R = "UNOWN_R",
  UNOWN_S = "UNOWN_S",
  UNOWN_T = "UNOWN_T",
  UNOWN_U = "UNOWN_U",
  UNOWN_V = "UNOWN_V",
  UNOWN_W = "UNOWN_W",
  UNOWN_X = "UNOWN_X",
  UNOWN_Y = "UNOWN_Y",
  UNOWN_Z = "UNOWN_Z",
  URSALUNA = "URSALUNA",
  URSALUNA_BLOODMOON = "URSALUNA_BLOODMOON",
  URSARING = "URSARING",
  UXIE = "UXIE",
  VANILLISH = "VANILLISH",
  VANILLITE = "VANILLITE",
  VANILLUXE = "VANILLUXE",
  VAPOREON = "VAPOREON",
  VENIPEDE = "VENIPEDE",
  VENOMOTH = "VENOMOTH",
  VENONAT = "VENONAT",
  VENUSAUR = "VENUSAUR",
  VESPIQUEEN = "VESPIQUEEN",
  VIBRAVA = "VIBRAVA",
  VICTINI = "VICTINI",
  VICTREEBEL = "VICTREEBEL",
  VIGOROTH = "VIGOROTH",
  VIKAVOLT = "VIKAVOLT",
  VILEPLUME = "VILEPLUME",
  VIRIZION = "VIRIZION",
  VOLBEAT = "VOLBEAT",
  VOLCANION = "VOLCANION",
  VOLCARONA = "VOLCARONA",
  VOLTORB = "VOLTORB",
  VULPIX = "VULPIX",
  WAILMER = "WAILMER",
  WAILORD = "WAILORD",
  WALREIN = "WALREIN",
  WARTORTLE = "WARTORTLE",
  WATCHOG = "WATCHOG",
  WATTREL = "WATTREL",
  WEAVILE = "WEAVILE",
  WEEDLE = "WEEDLE",
  WEEPINBELL = "WEEPINBELL",
  WEEZING = "WEEZING",
  WHIRLIPEDE = "WHIRLIPEDE",
  WHISCASH = "WHISCASH",
  WHISMUR = "WHISMUR",
  WIGGLYTUFF = "WIGGLYTUFF",
  WINGULL = "WINGULL",
  WISHIWASHI = "WISHIWASHI",
  WISHIWASHI_SCHOOL = "WISHIWASHI_SCHOOL",
  WOBBUFFET = "WOBBUFFET",
  WOOBAT = "WOOBAT",
  WORMADAM_PLANT = "WORMADAM_PLANT",
  WORMADAM_SANDY = "WORMADAM_SANDY",
  WORMADAM_TRASH = "WORMADAM_TRASH",
  WURMPLE = "WURMPLE",
  WYNAUT = "WYNAUT",
  XATU = "XATU",
  XERNEAS = "XERNEAS",
  XURKITREE = "XURKITREE",
  YANMA = "YANMA",
  YANMEGA = "YANMEGA",
  YVELTAL = "YVELTAL",
  ZANGOOSE = "ZANGOOSE",
  ZAPDOS = "ZAPDOS",
  ZEKROM = "ZEKROM",
  ZERAORA = "ZERAORA",
  ZIGZAGOON = "ZIGZAGOON",
  ZOROARK = "ZOROARK",
  ZORUA = "ZORUA",
  ZUBAT = "ZUBAT",
  ZWEILOUS = "ZWEILOUS",
  ZYGARDE_10 = "ZYGARDE_10",
  ZYGARDE_50 = "ZYGARDE_50",
  ZYGARDE_100 = "ZYGARDE_100",
  VULLABY = "VULLABY",
  MANDIBUZZ = "MANDIBUZZ",
  INKAY = "INKAY",
  MALAMAR = "MALAMAR"
}

export const PkmIndex: { [key in Pkm]: string } = {
  [Pkm.DEFAULT]: "0000",
  [Pkm.EGG]: "0000-0004",
  [Pkm.SUBSTITUTE]: "0000-0001",

  // Gen 1

  [Pkm.BULBASAUR]: "0001",
  [Pkm.IVYSAUR]: "0002",
  [Pkm.VENUSAUR]: "0003",
  [Pkm.CHARMANDER]: "0004",
  [Pkm.CHARMELEON]: "0005",
  [Pkm.CHARIZARD]: "0006",
  [Pkm.SQUIRTLE]: "0007",
  [Pkm.WARTORTLE]: "0008",
  [Pkm.BLASTOISE]: "0009",
  [Pkm.CATERPIE]: "0010",
  [Pkm.METAPOD]: "0011",
  [Pkm.BUTTERFREE]: "0012",
  [Pkm.WEEDLE]: "0013",
  [Pkm.KAKUNA]: "0014",
  [Pkm.BEEDRILL]: "0015",
  [Pkm.PIDGEY]: "0016",
  [Pkm.PIDGEOTTO]: "0017",
  [Pkm.PIDGEOT]: "0018",
  [Pkm.RATTATA]: "0019",
  [Pkm.ALOLAN_RATTATA]: "0019-0001",
  [Pkm.RATICATE]: "0020",
  [Pkm.ALOLAN_RATICATE]: "0020-0001",
  [Pkm.SPEAROW]: "0021",
  [Pkm.FEAROW]: "0022",
  [Pkm.EKANS]: "0023",
  [Pkm.ARBOK]: "0024",
  [Pkm.PIKACHU]: "0025",
  [Pkm.RAICHU]: "0026",
  [Pkm.ALOLAN_RAICHU]: "0026-0001",
  [Pkm.SANDSHREW]: "0027",
  [Pkm.SANDSLASH]: "0028",
  [Pkm.NIDORANF]: "0029",
  [Pkm.NIDORINA]: "0030",
  [Pkm.NIDOQUEEN]: "0031",
  [Pkm.NIDORANM]: "0032",
  [Pkm.NIDORINO]: "0033",
  [Pkm.NIDOKING]: "0034",
  [Pkm.CLEFAIRY]: "0035",
  [Pkm.CLEFABLE]: "0036",
  [Pkm.VULPIX]: "0037",
  [Pkm.NINETALES]: "0038",
  [Pkm.ALOLAN_VULPIX]: "0037-0001",
  [Pkm.ALOLAN_NINETALES]: "0038-0001",
  [Pkm.JIGGLYPUFF]: "0039",
  [Pkm.WIGGLYTUFF]: "0040",
  [Pkm.ZUBAT]: "0041",
  [Pkm.GOLBAT]: "0042",
  [Pkm.ODDISH]: "0043",
  [Pkm.GLOOM]: "0044",
  [Pkm.VILEPLUME]: "0045",
  [Pkm.PARAS]: "0046",
  [Pkm.PARASECT]: "0047",
  [Pkm.VENONAT]: "0048",
  [Pkm.VENOMOTH]: "0049",
  [Pkm.DIGLETT]: "0050",
  [Pkm.ALOLAN_DIGLETT]: "0050-0001",
  [Pkm.DUGTRIO]: "0051",
  [Pkm.ALOLAN_DUGTRIO]: "0051-0001",
  [Pkm.MEOWTH]: "0052",
  [Pkm.PERSIAN]: "0053",
  [Pkm.ALOLAN_MEOWTH]: "0052-0001",
  [Pkm.ALOLAN_PERSIAN]: "0053-0001",
  [Pkm.PSYDUCK]: "0054",
  [Pkm.GOLDUCK]: "0055",
  [Pkm.MANKEY]: "0056",
  [Pkm.PRIMEAPE]: "0057",
  [Pkm.GROWLITHE]: "0058",
  [Pkm.ARCANINE]: "0059",
  [Pkm.HISUI_GROWLITHE]: "0058-0001",
  [Pkm.HISUI_ARCANINE]: "0059-0001",
  [Pkm.POLIWAG]: "0060",
  [Pkm.POLIWHIRL]: "0061",
  [Pkm.POLIWRATH]: "0062",
  [Pkm.ABRA]: "0063",
  [Pkm.KADABRA]: "0064",
  [Pkm.ALAKAZAM]: "0065",
  [Pkm.MACHOP]: "0066",
  [Pkm.MACHOKE]: "0067",
  [Pkm.MACHAMP]: "0068",
  [Pkm.BELLSPROUT]: "0069",
  [Pkm.WEEPINBELL]: "0070",
  [Pkm.VICTREEBEL]: "0071",
  [Pkm.TENTACOOL]: "0072",
  [Pkm.TENTACRUEL]: "0073",
  [Pkm.GEODUDE]: "0074",
  [Pkm.GRAVELER]: "0075",
  [Pkm.GOLEM]: "0076",
  [Pkm.ALOLAN_GEODUDE]: "0074-0001",
  [Pkm.ALOLAN_GRAVELER]: "0075-0001",
  [Pkm.ALOLAN_GOLEM]: "0076-0001",
  [Pkm.PONYTA]: "0077",
  [Pkm.RAPIDASH]: "0078",
  [Pkm.GALARIAN_PONYTA]: "0077-0001",
  [Pkm.GALARIAN_RAPIDASH]: "0078-0001",
  [Pkm.SLOWPOKE]: "0079",
  [Pkm.SLOWBRO]: "0080",
  [Pkm.MAGNEMITE]: "0081",
  [Pkm.MAGNETON]: "0082",
  [Pkm.FARFETCH_D]: "0083",
  [Pkm.DODUO]: "0084",
  [Pkm.DODRIO]: "0085",
  [Pkm.SEEL]: "0086",
  [Pkm.DEWGONG]: "0087",
  [Pkm.GRIMER]: "0088",
  [Pkm.MUK]: "0089",
  [Pkm.ALOLAN_GRIMER]: "0088-0001",
  [Pkm.ALOLAN_MUK]: "0089-0001",
  [Pkm.SHELLDER]: "0090",
  [Pkm.CLOYSTER]: "0091",
  [Pkm.GASTLY]: "0092",
  [Pkm.HAUNTER]: "0093",
  [Pkm.GENGAR]: "0094",
  [Pkm.ONIX]: "0095",
  [Pkm.DROWZEE]: "0096",
  [Pkm.HYPNO]: "0097",
  [Pkm.KRABBY]: "0098",
  [Pkm.KINGLER]: "0099",
  [Pkm.VOLTORB]: "0100",
  [Pkm.HISUI_VOLTORB]: "0100-0001",
  [Pkm.ELECTRODE]: "0101",
  [Pkm.HISUI_ELECTRODE]: "0101-0001",
  [Pkm.EXEGGCUTE]: "0102",
  [Pkm.EXEGGUTOR]: "0103",
  [Pkm.ALOLAN_EXEGGUTOR]: "0103-0001",
  [Pkm.CUBONE]: "0104",
  [Pkm.MAROWAK]: "0105",
  [Pkm.ALOLAN_MAROWAK]: "0105-0001",
  [Pkm.HITMONLEE]: "0106",
  [Pkm.HITMONCHAN]: "0107",
  [Pkm.LICKITUNG]: "0108",
  [Pkm.KOFFING]: "0109",
  [Pkm.WEEZING]: "0110",
  [Pkm.RHYHORN]: "0111",
  [Pkm.RHYDON]: "0112",
  [Pkm.CHANSEY]: "0113",
  [Pkm.TANGELA]: "0114",
  [Pkm.KANGASKHAN]: "0115",
  [Pkm.HORSEA]: "0116",
  [Pkm.SEADRA]: "0117",
  [Pkm.GOLDEEN]: "0118",
  [Pkm.SEAKING]: "0119",
  [Pkm.STARYU]: "0120",
  [Pkm.STARMIE]: "0121",
  [Pkm.MR_MIME]: "0122",
  [Pkm.SCYTHER]: "0123",
  [Pkm.JYNX]: "0124",
  [Pkm.ELECTABUZZ]: "0125",
  [Pkm.MAGMAR]: "0126",
  [Pkm.PINSIR]: "0127",
  [Pkm.TAUROS]: "0128",
  [Pkm.MAGIKARP]: "0129",
  [Pkm.GYARADOS]: "0130",
  [Pkm.LAPRAS]: "0131",
  [Pkm.DITTO]: "0132",
  [Pkm.EEVEE]: "0133",
  [Pkm.VAPOREON]: "0134",
  [Pkm.JOLTEON]: "0135",
  [Pkm.FLAREON]: "0136",
  [Pkm.PORYGON]: "0137",
  [Pkm.OMANYTE]: "0138",
  [Pkm.OMASTAR]: "0139",
  [Pkm.KABUTO]: "0140",
  [Pkm.KABUTOPS]: "0141",
  [Pkm.AERODACTYL]: "0142",
  [Pkm.SNORLAX]: "0143",
  [Pkm.ARTICUNO]: "0144",
  [Pkm.GALARIAN_ARTICUNO]: "0144-0001",
  [Pkm.ZAPDOS]: "0145",
  [Pkm.GALARIAN_ZAPDOS]: "0145-0001",
  [Pkm.MOLTRES]: "0146",
  [Pkm.GALARIAN_MOLTRES]: "0146-0001",
  [Pkm.DRATINI]: "0147",
  [Pkm.DRAGONAIR]: "0148",
  [Pkm.DRAGONITE]: "0149",
  [Pkm.MEWTWO]: "0150",
  [Pkm.MEW]: "0151",

  // Gen 2
  [Pkm.CHIKORITA]: "0152",
  [Pkm.BAYLEEF]: "0153",
  [Pkm.MEGANIUM]: "0154",
  [Pkm.CYNDAQUIL]: "0155",
  [Pkm.QUILAVA]: "0156",
  [Pkm.TYPHLOSION]: "0157",
  [Pkm.HISUIAN_TYPHLOSION]: "0157-0001",
  [Pkm.TOTODILE]: "0158",
  [Pkm.CROCONAW]: "0159",
  [Pkm.FERALIGATR]: "0160",
  [Pkm.SENTRET]: "0161",
  [Pkm.FURRET]: "0162",
  [Pkm.HOOTHOOT]: "0163",
  [Pkm.NOCTOWL]: "0164",
  //[Pkm.LEDYBA]: "0165",
  //[Pkm.LEDIAN]: "0166",
  [Pkm.SPINARAK]: "0167",
  [Pkm.ARIADOS]: "0168",
  [Pkm.CROBAT]: "0169",
  [Pkm.CHINCHOU]: "0170",
  [Pkm.LANTURN]: "0171",
  [Pkm.PICHU]: "0172",
  [Pkm.CLEFFA]: "0173",
  [Pkm.IGGLYBUFF]: "0174",
  [Pkm.TOGEPI]: "0175",
  [Pkm.TOGETIC]: "0176",
  [Pkm.NATU]: "0177",
  [Pkm.XATU]: "0178",
  [Pkm.MAREEP]: "0179",
  [Pkm.FLAFFY]: "0180",
  [Pkm.AMPHAROS]: "0181",
  [Pkm.BELLOSSOM]: "0182",
  [Pkm.MARILL]: "0183",
  [Pkm.AZUMARILL]: "0184",
  [Pkm.SUDOWOODO]: "0185",
  [Pkm.POLITOED]: "0186",
  [Pkm.HOPPIP]: "0187",
  [Pkm.SKIPLOOM]: "0188",
  [Pkm.JUMPLUFF]: "0189",
  [Pkm.AIPOM]: "0190",
  [Pkm.SUNKERN]: "0191",
  [Pkm.SUNFLORA]: "0192",
  [Pkm.YANMA]: "0193",
  //[Pkm.WOOPER]: "0194",
  [Pkm.PALDEA_WOOPER]: "0194-0002",
  //[Pkm.QUAGSIRE]: "0195",
  [Pkm.ESPEON]: "0196",
  [Pkm.UMBREON]: "0197",
  [Pkm.MURKROW]: "0198",
  [Pkm.SLOWKING]: "0199",
  [Pkm.MISDREAVUS]: "0200",
  [Pkm.UNOWN_A]: "0201",
  [Pkm.UNOWN_B]: "0201-0001",
  [Pkm.UNOWN_C]: "0201-0002",
  [Pkm.UNOWN_D]: "0201-0003",
  [Pkm.UNOWN_E]: "0201-0004",
  [Pkm.UNOWN_F]: "0201-0005",
  [Pkm.UNOWN_G]: "0201-0006",
  [Pkm.UNOWN_H]: "0201-0007",
  [Pkm.UNOWN_I]: "0201-0008",
  [Pkm.UNOWN_J]: "0201-0009",
  [Pkm.UNOWN_K]: "0201-0010",
  [Pkm.UNOWN_L]: "0201-0011",
  [Pkm.UNOWN_M]: "0201-0012",
  [Pkm.UNOWN_N]: "0201-0013",
  [Pkm.UNOWN_O]: "0201-0014",
  [Pkm.UNOWN_P]: "0201-0015",
  [Pkm.UNOWN_Q]: "0201-0016",
  [Pkm.UNOWN_R]: "0201-0017",
  [Pkm.UNOWN_S]: "0201-0018",
  [Pkm.UNOWN_T]: "0201-0019",
  [Pkm.UNOWN_U]: "0201-0020",
  [Pkm.UNOWN_V]: "0201-0021",
  [Pkm.UNOWN_W]: "0201-0022",
  [Pkm.UNOWN_X]: "0201-0023",
  [Pkm.UNOWN_Y]: "0201-0024",
  [Pkm.UNOWN_Z]: "0201-0025",
  [Pkm.UNOWN_QUESTION]: "0201-0027",
  [Pkm.UNOWN_EXCLAMATION]: "0201-0026",
  [Pkm.WOBBUFFET]: "0202",
  //[Pkm.GIRAFARIG]: "0203",
  [Pkm.PINECO]: "0204",
  [Pkm.FORRETRESS]: "0205",
  //[Pkm.DUNSPARCE]: "0206",
  [Pkm.GLIGAR]: "0207",
  [Pkm.STEELIX]: "0208",
  [Pkm.MEGA_STEELIX]: "0208-0001",
  [Pkm.SNUBULL]: "0209",
  [Pkm.GRANBULL]: "0210",
  //[Pkm.QWILFISH]: "0211",
  [Pkm.HISUIAN_QWILFISH]: "0211-0001",
  [Pkm.SCIZOR]: "0212",
  [Pkm.SHUCKLE]: "0213",
  [Pkm.HERACROSS]: "0214",
  [Pkm.SNEASEL]: "0215",
  [Pkm.HISUI_SNEASEL]: "0215-0001",
  [Pkm.TEDDIURSA]: "0216",
  [Pkm.URSARING]: "0217",
  [Pkm.SLUGMA]: "0218",
  [Pkm.MAGCARGO]: "0219",
  [Pkm.SWINUB]: "0220",
  [Pkm.PILOSWINE]: "0221",
  [Pkm.CORSOLA]: "0222",
  [Pkm.GALAR_CORSOLA]: "0222-0001",
  [Pkm.REMORAID]: "0223",
  [Pkm.OCTILLERY]: "0224",
  [Pkm.DELIBIRD]: "0225",
  [Pkm.MANTINE]: "0226",
  [Pkm.SKARMORY]: "0227",
  [Pkm.HOUNDOUR]: "0228",
  [Pkm.HOUNDOOM]: "0229",
  [Pkm.MEGA_HOUNDOOM]: "0229-0001",
  [Pkm.KINGDRA]: "0230",
  [Pkm.PHANPY]: "0231",
  [Pkm.DONPHAN]: "0232",
  [Pkm.PORYGON_2]: "0233",
  [Pkm.STANTLER]: "0234",
  [Pkm.SMEARGLE]: "0235",
  [Pkm.TYROGUE]: "0236",
  [Pkm.HITMONTOP]: "0237",
  [Pkm.SMOOCHUM]: "0238",
  [Pkm.ELEKID]: "0239",
  [Pkm.MAGBY]: "0240",
  [Pkm.MILTANK]: "0241",
  [Pkm.BLISSEY]: "0242",
  [Pkm.RAIKOU]: "0243",
  [Pkm.ENTEI]: "0244",
  [Pkm.SUICUNE]: "0245",
  [Pkm.LARVITAR]: "0246",
  [Pkm.PUPITAR]: "0247",
  [Pkm.TYRANITAR]: "0248",
  [Pkm.LUGIA]: "0249",
  [Pkm.SHADOW_LUGIA]: "0249-0001",
  [Pkm.HO_OH]: "0250",
  [Pkm.CELEBI]: "0251",

  // Gen 3
  [Pkm.TREECKO]: "0252",
  [Pkm.GROVYLE]: "0253",
  [Pkm.SCEPTILE]: "0254",
  [Pkm.TORCHIC]: "0255",
  [Pkm.COMBUSKEN]: "0256",
  [Pkm.BLAZIKEN]: "0257",
  [Pkm.MUDKIP]: "0258",
  [Pkm.MARSHTOMP]: "0259",
  [Pkm.SWAMPERT]: "0260",
  [Pkm.POOCHYENA]: "0261",
  [Pkm.MIGHTYENA]: "0262",
  [Pkm.ZIGZAGOON]: "0263",
  [Pkm.LINOONE]: "0264",
  [Pkm.WURMPLE]: "0265",
  [Pkm.SILCOON]: "0266",
  [Pkm.BEAUTIFLY]: "0267",
  [Pkm.CASCOON]: "0268",
  [Pkm.DUSTOX]: "0269",
  [Pkm.LOTAD]: "0270",
  [Pkm.LOMBRE]: "0271",
  [Pkm.LUDICOLO]: "0272",
  [Pkm.SEEDOT]: "0273",
  [Pkm.NUZLEAF]: "0274",
  [Pkm.SHIFTRY]: "0275",
  //[Pkm.TAILLOW]: "0276",
  //[Pkm.SWELLOW]: "0277",
  [Pkm.WINGULL]: "0278",
  [Pkm.PELIPPER]: "0279",
  [Pkm.RALTS]: "0280",
  [Pkm.KIRLIA]: "0281",
  [Pkm.GARDEVOIR]: "0282",
  //[Pkm.SURSKIT]: "0283",
  //[Pkm.MASQUERAIN]: "0284",
  [Pkm.SHROOMISH]: "0285",
  [Pkm.BRELOOM]: "0286",
  [Pkm.SLAKOTH]: "0287",
  [Pkm.VIGOROTH]: "0288",
  [Pkm.SLAKING]: "0289",
  [Pkm.NINCADA]: "0290",
  [Pkm.NINJASK]: "0291",
  [Pkm.SHEDINJA]: "0292",
  [Pkm.WHISMUR]: "0293",
  [Pkm.LOUDRED]: "0294",
  [Pkm.EXPLOUD]: "0295",
  [Pkm.MAKUHITA]: "0296",
  [Pkm.HARIYAMA]: "0297",
  [Pkm.AZURILL]: "0298",
  [Pkm.NOSEPASS]: "0299",
  //[Pkm.SKITTY]: "0300",
  //[Pkm.DELCATTY]: "0301",
  [Pkm.SABLEYE]: "0302",
  [Pkm.MEGA_SABLEYE]: "0302-0001",
  [Pkm.MAWILE]: "0303",
  [Pkm.ARON]: "0304",
  [Pkm.LAIRON]: "0305",
  [Pkm.AGGRON]: "0306",
  [Pkm.MEDITITE]: "0307",
  [Pkm.MEDICHAM]: "0308",
  [Pkm.ELECTRIKE]: "0309",
  [Pkm.MANECTRIC]: "0310",
  [Pkm.MEGA_MANECTRIC]: "0310-0001",
  [Pkm.PLUSLE]: "0311",
  [Pkm.MINUN]: "0312",
  [Pkm.VOLBEAT]: "0313",
  [Pkm.ILLUMISE]: "0314",
  [Pkm.ROSELIA]: "0315",
  //[Pkm.GULPIN]: "0316",
  //[Pkm.SWALOT]: "0317",
  [Pkm.CARVANHA]: "0318",
  [Pkm.SHARPEDO]: "0319",
  [Pkm.WAILMER]: "0320",
  [Pkm.WAILORD]: "0321",
  [Pkm.NUMEL]: "0322",
  [Pkm.CAMERUPT]: "0323",
  [Pkm.MEGA_CAMERUPT]: "0323-0001",
  [Pkm.TORKOAL]: "0324",
  [Pkm.SPOINK]: "0325",
  [Pkm.GRUMPIG]: "0326",
  [Pkm.SPINDA]: "0327",
  [Pkm.TRAPINCH]: "0328",
  [Pkm.VIBRAVA]: "0329",
  [Pkm.FLYGON]: "0330",
  [Pkm.CACNEA]: "0331",
  [Pkm.CACTURNE]: "0332",
  [Pkm.SWABLU]: "0333",
  [Pkm.ALTARIA]: "0334",
  [Pkm.MEGA_ALTARIA]: "0334-0001",
  [Pkm.ZANGOOSE]: "0335",
  [Pkm.SEVIPER]: "0336",
  [Pkm.LUNATONE]: "0337",
  [Pkm.SOLROCK]: "0338",
  [Pkm.BARBOACH]: "0339",
  [Pkm.WHISCASH]: "0340",
  [Pkm.CORPHISH]: "0341",
  [Pkm.CRAWDAUNT]: "0342",
  [Pkm.BALTOY]: "0343",
  [Pkm.CLAYDOL]: "0344",
  [Pkm.LILEEP]: "0345",
  [Pkm.CRADILY]: "0346",
  [Pkm.ANORITH]: "0347",
  [Pkm.ARMALDO]: "0348",
  [Pkm.FEEBAS]: "0349",
  [Pkm.MILOTIC]: "0350",
  [Pkm.CASTFORM]: "0351",
  [Pkm.CASTFORM_SUN]: "0351-0001",
  [Pkm.CASTFORM_RAIN]: "0351-0002",
  [Pkm.CASTFORM_HAIL]: "0351-0003",
  [Pkm.KECLEON]: "0352",
  [Pkm.SHUPPET]: "0353",
  [Pkm.BANETTE]: "0354",
  [Pkm.MEGA_BANETTE]: "0354-0001",
  [Pkm.DUSKULL]: "0355",
  [Pkm.DUSCLOPS]: "0356",
  [Pkm.TROPIUS]: "0357",
  [Pkm.CHIMECHO]: "0358",
  [Pkm.ABSOL]: "0359",
  [Pkm.WYNAUT]: "0360",
  [Pkm.SNORUNT]: "0361",
  [Pkm.GLALIE]: "0362",
  [Pkm.SPHEAL]: "0363",
  [Pkm.SEALEO]: "0364",
  [Pkm.WALREIN]: "0365",
  [Pkm.CLAMPERL]: "0366",
  [Pkm.HUNTAIL]: "0367",
  [Pkm.GOREBYSS]: "0368",
  [Pkm.RELICANTH]: "0369",
  [Pkm.LUVDISC]: "0370",
  [Pkm.BAGON]: "0371",
  [Pkm.SHELGON]: "0372",
  [Pkm.SALAMENCE]: "0373",
  [Pkm.BELDUM]: "0374",
  [Pkm.METANG]: "0375",
  [Pkm.METAGROSS]: "0376",
  [Pkm.REGICE]: "0378",
  [Pkm.REGIROCK]: "0377",
  [Pkm.REGISTEEL]: "0379",
  [Pkm.LATIAS]: "0380",
  [Pkm.LATIOS]: "0381",
  [Pkm.KYOGRE]: "0382",
  [Pkm.GROUDON]: "0383",
  [Pkm.RAYQUAZA]: "0384",
  [Pkm.PRIMAL_KYOGRE]: "0382-0001",
  [Pkm.PRIMAL_GROUDON]: "0383-0001",
  [Pkm.MEGA_RAYQUAZA]: "0384-0001",
  [Pkm.JIRACHI]: "0385",
  [Pkm.DEOXYS]: "0386",
  [Pkm.DEOXYS_DEFENSE]: "0386-0002",
  [Pkm.DEOXYS_ATTACK]: "0386-0001",
  [Pkm.DEOXYS_SPEED]: "0386-0003",

  // Gen 4

  [Pkm.TURTWIG]: "0387",
  [Pkm.GROTLE]: "0388",
  [Pkm.TORTERRA]: "0389",
  [Pkm.CHIMCHAR]: "0390",
  [Pkm.MONFERNO]: "0391",
  [Pkm.INFERNAPE]: "0392",
  [Pkm.PIPLUP]: "0393",
  [Pkm.PRINPLUP]: "0394",
  [Pkm.EMPOLEON]: "0395",
  [Pkm.STARLY]: "0396",
  [Pkm.STARAVIA]: "0397",
  [Pkm.STARAPTOR]: "0398",
  [Pkm.BIDOOF]: "0399",
  [Pkm.BIBAREL]: "0400",
  [Pkm.KRICKETOT]: "0401",
  [Pkm.KRICKETUNE]: "0402",
  [Pkm.SHINX]: "0403",
  [Pkm.LUXIO]: "0404",
  [Pkm.LUXRAY]: "0405",
  [Pkm.BUDEW]: "0406",
  [Pkm.ROSERADE]: "0407",
  [Pkm.CRANIDOS]: "0408",
  [Pkm.RAMPARDOS]: "0409",
  [Pkm.SHIELDON]: "0410",
  [Pkm.BASTIODON]: "0411",
  [Pkm.BURMY_PLANT]: "0412",
  [Pkm.BURMY_SANDY]: "0412-0001",
  [Pkm.BURMY_TRASH]: "0412-0002",
  [Pkm.WORMADAM_PLANT]: "0413",
  [Pkm.WORMADAM_SANDY]: "0413-0001",
  [Pkm.WORMADAM_TRASH]: "0413-0002",
  [Pkm.MOTHIM]: "0414",
  [Pkm.COMBEE]: "0415",
  [Pkm.VESPIQUEEN]: "0416",
  //[Pkm.PACHIRISU]: "0417",
  [Pkm.BUIZEL]: "0418",
  [Pkm.FLOATZEL]: "0419",
  [Pkm.CHERRUBI]: "0420",
  [Pkm.CHERRIM]: "0421",
  [Pkm.CHERRIM_SUNLIGHT]: "0421-0001",
  [Pkm.SHELLOS_WEST_SEA]: "0422",
  [Pkm.GASTRODON_WEST_SEA]: "0423",
  [Pkm.SHELLOS_EAST_SEA]: "0422-0001",
  [Pkm.GASTRODON_EAST_SEA]: "0423-0001",
  [Pkm.AMBIPOM]: "0424",
  [Pkm.DRIFLOON]: "0425",
  [Pkm.DRIFBLIM]: "0426",
  [Pkm.BUNEARY]: "0427",
  [Pkm.LOPUNNY]: "0428",
  [Pkm.MEGA_LOPUNNY]: "0428-0001",
  [Pkm.MISMAGIUS]: "0429",
  [Pkm.HONCHKROW]: "0430",
  //[Pkm.GLAMEOW]: "0431",
  //[Pkm.PURUGLY]: "0432",
  [Pkm.CHINGLING]: "0433",
  [Pkm.STUNKY]: "0434",
  [Pkm.SKUNTANK]: "0435",
  [Pkm.BRONZOR]: "0436",
  [Pkm.BRONZONG]: "0437",
  [Pkm.BONSLEY]: "0438",
  [Pkm.MIME_JR]: "0439",
  [Pkm.HAPPINY]: "0440",
  [Pkm.CHATOT]: "0441",
  [Pkm.SPIRITOMB]: "0442",
  [Pkm.GIBLE]: "0443",
  [Pkm.GABITE]: "0444",
  [Pkm.GARCHOMP]: "0445",
  [Pkm.MUNCHLAX]: "0446",
  [Pkm.RIOLU]: "0447",
  [Pkm.LUCARIO]: "0448",
  [Pkm.HIPPOPOTAS]: "0449",
  [Pkm.HIPPODOWN]: "0450",
  [Pkm.SKORUPI]: "0451",
  [Pkm.DRAPION]: "0452",
  [Pkm.CROAGUNK]: "0453",
  [Pkm.TOXICROAK]: "0454",
  [Pkm.CARNIVINE]: "0455",
  [Pkm.FINNEON]: "0456",
  [Pkm.LUMINEON]: "0457",
  [Pkm.MANTYKE]: "0458",
  [Pkm.SNOVER]: "0459",
  [Pkm.ABOMASNOW]: "0460",
  [Pkm.MEGA_ABOMASNOW]: "0460-0001",
  [Pkm.WEAVILE]: "0461",
  [Pkm.MAGNEZONE]: "0462",
  [Pkm.LICKILICKY]: "0463",
  [Pkm.RHYPERIOR]: "0464",
  [Pkm.TANGROWTH]: "0465",
  [Pkm.ELECTIVIRE]: "0466",
  [Pkm.MAGMORTAR]: "0467",
  [Pkm.TOGEKISS]: "0468",
  [Pkm.YANMEGA]: "0469",
  [Pkm.LEAFEON]: "0470",
  [Pkm.GLACEON]: "0471",
  [Pkm.GLISCOR]: "0472",
  [Pkm.MAMOSWINE]: "0473",
  [Pkm.PORYGON_Z]: "0474",
  //[Pkm.GALLADE]: "0475",
  [Pkm.PROBOPASS]: "0476",
  [Pkm.DUSKNOIR]: "0477",
  [Pkm.FROSLASS]: "0478",
  [Pkm.ROTOM]: "0479",
  [Pkm.ROTOM_DRONE]: "0479-0008",
  [Pkm.UXIE]: "0480",
  [Pkm.MESPRIT]: "0481",
  [Pkm.AZELF]: "0482",
  [Pkm.DIALGA]: "0483",
  [Pkm.PALKIA]: "0484",
  [Pkm.HEATRAN]: "0485",
  [Pkm.REGIGIGAS]: "0486",
  [Pkm.GIRATINA]: "0487",
  [Pkm.ORIGIN_GIRATINA]: "0487-0001",
  [Pkm.CRESSELIA]: "0488",
  [Pkm.PHIONE]: "0489",
  [Pkm.MANAPHY]: "0490",
  [Pkm.DARKRAI]: "0491",
  [Pkm.SHAYMIN]: "0492",
  [Pkm.SHAYMIN_SKY]: "0492-0001",
  [Pkm.ARCEUS]: "0493",

  // Gen 5

  [Pkm.VICTINI]: "0494",
  [Pkm.SNIVY]: "0495",
  [Pkm.SERVINE]: "0496",
  [Pkm.SERPERIOR]: "0497",
  [Pkm.TEPIG]: "0498",
  [Pkm.PIGNITE]: "0499",
  [Pkm.EMBOAR]: "0500",
  [Pkm.OSHAWOTT]: "0501",
  [Pkm.DEWOTT]: "0502",
  [Pkm.SAMUROTT]: "0503",
  [Pkm.PATRAT]: "0504",
  [Pkm.WATCHOG]: "0505",
  [Pkm.LILLIPUP]: "0506",
  [Pkm.HERDIER]: "0507",
  [Pkm.STOUTLAND]: "0508",
  [Pkm.PURRLOIN]: "0509",
  [Pkm.LIEPARD]: "0510",
  //[Pkm.PANSAGE]: "0511",
  //[Pkm.SIMISAGE]: "0512",
  //[Pkm.PANSEAR]: "0513",
  //[Pkm.SIMISEAR]: "0514",
  //[Pkm.PANPOUR]: "0515",
  //[Pkm.SIMIPOUR]: "0516",
  [Pkm.MUNNA]: "0517",
  [Pkm.MUSHARNA]: "0518",
  //[Pkm.PIDOVE]: "0519",
  //[Pkm.TRANQUILL]: "0520",
  //[Pkm.UNFEZANT]: "0521",
  //[Pkm.BLITZLE]: "0522",
  //[Pkm.ZEBSTRIKA]: "0523",
  //[Pkm.ROGGENROLA]: "0524",
  //[Pkm.BOLDORE]: "0525",
  //[Pkm.GIGALITH]: "0526",
  [Pkm.WOOBAT]: "0527",
  [Pkm.SWOOBAT]: "0528",
  //[Pkm.DRILBUR]: "0529",
  //[Pkm.EXCADRILL]: "0530",
  [Pkm.AUDINO]: "0531",
  [Pkm.TIMBURR]: "0532",
  [Pkm.PILLAR_WOOD]: "0532-0002",
  [Pkm.GURDURR]: "0533",
  [Pkm.PILLAR_IRON]: "0533-0002",
  [Pkm.CONKELDURR]: "0534",
  [Pkm.PILLAR_CONCRETE]: "0534-0002",
  [Pkm.TYMPOLE]: "0535",
  [Pkm.PALPITOAD]: "0536",
  [Pkm.SEISMITOAD]: "0537",
  //[Pkm.THROH]: "0538",
  //[Pkm.SAWK]: "0539",
  [Pkm.SEWADDLE]: "0540",
  [Pkm.SWADLOON]: "0541",
  [Pkm.LEAVANNY]: "0542",
  [Pkm.VENIPEDE]: "0543",
  [Pkm.WHIRLIPEDE]: "0544",
  [Pkm.SCOLIPEDE]: "0545",
  //[Pkm.COTTONEE]: "0546",
  //[Pkm.WHIMSICOTT]: "0547",
  [Pkm.PETILIL]: "0548",
  [Pkm.LILIGANT]: "0549",
  //[Pkm.BASCULIN]: "0550",
  [Pkm.SANDILE]: "0551",
  [Pkm.KROKOROK]: "0552",
  [Pkm.KROOKODILE]: "0553",
  [Pkm.DARUMAKA]: "0554",
  [Pkm.DARMANITAN]: "0555",
  [Pkm.DARMANITAN_ZEN]: "0555-0001",
  [Pkm.MARACTUS]: "0556",
  //[Pkm.DWEBBLE]: "0557",
  //[Pkm.CRUSTLE]: "0558",
  [Pkm.SCRAGGY]: "0559",
  [Pkm.SCRAFTY]: "0560",
  [Pkm.SIGILYPH]: "0561",
  //[Pkm.YAMASK]: "0562",
  //[Pkm.COFAGRIGUS]: "0563",
  //[Pkm.TIRTOUGA]: "0564",
  //[Pkm.CARRACOSTA]: "0565",
  [Pkm.ARCHEN]: "0566",
  [Pkm.ARCHEOPS]: "0567",
  [Pkm.TRUBBISH]: "0568",
  [Pkm.GARBODOR]: "0569",
  [Pkm.ZORUA]: "0570",
  [Pkm.ZOROARK]: "0571",
  [Pkm.HISUI_ZORUA]: "0570-0001",
  [Pkm.HISUI_ZOROARK]: "0571-0001",
  //[Pkm.MINCCINO]: "0572",
  //[Pkm.CINCCINO]: "0573",
  [Pkm.GOTHITA]: "0574",
  [Pkm.GOTHORITA]: "0575",
  [Pkm.GOTHITELLE]: "0576",
  [Pkm.SOLOSIS]: "0577",
  [Pkm.DUOSION]: "0578",
  [Pkm.REUNICLUS]: "0579",
  //[Pkm.DUCKLETT]: "0580",
  //[Pkm.SWANNA]: "0581",
  [Pkm.VANILLITE]: "0582",
  [Pkm.VANILLISH]: "0583",
  [Pkm.VANILLUXE]: "0584",
  [Pkm.DEERLING]: "0585",
  [Pkm.SAWSBUCK]: "0586",
  //[Pkm.EMOLGA]: "0587",
  //[Pkm.KARRABLAST]: "0588",
  //[Pkm.ESCAVALIER]: "0589",
  //[Pkm.FOONGUS]: "0590",
  //[Pkm.AMOONGUSS]: "0591",
  //[Pkm.FRILLISH]: "0592",
  //[Pkm.JELLICENT]: "0593",
  //[Pkm.ALOMOMOLA]: "0594",
  [Pkm.JOLTIK]: "0595",
  [Pkm.GALVANTULA]: "0596",
  [Pkm.FERROSEED]: "0597",
  [Pkm.FERROTHORN]: "0598",
  //[Pkm.KLINK]: "0599",
  //[Pkm.KLANG]: "0600",
  //[Pkm.KLINKLANG]: "0601",
  //[Pkm.TYNAMO]: "0602",
  //[Pkm.EELEKTRIK]: "0603",
  //[Pkm.EELEKTROSS]: "0604",
  [Pkm.ELGYEM]: "0605",
  [Pkm.BEHEEYEM]: "0606",
  [Pkm.LITWICK]: "0607",
  [Pkm.LAMPENT]: "0608",
  [Pkm.CHANDELURE]: "0609",
  [Pkm.AXEW]: "0610",
  [Pkm.FRAXURE]: "0611",
  [Pkm.HAXORUS]: "0612",
  //[Pkm.CUBCHOO]: "0613",
  //[Pkm.BEARTIC]: "0614",
  //[Pkm.CRYOGONAL]: "0615",
  //[Pkm.SHELMET]: "0616",
  //[Pkm.ACCELGOR]: "0617",
  //[Pkm.STUNFISK]: "0618",
  [Pkm.MIENFOO]: "0619",
  [Pkm.MIENSHAO]: "0620",
  [Pkm.DRUDDIGON]: "0621",
  [Pkm.GOLETT]: "0622",
  [Pkm.GOLURK]: "0623",
  [Pkm.PAWNIARD]: "0624",
  [Pkm.BISHARP]: "0625",
  [Pkm.RUFFLET]: "0627",
  [Pkm.BRAVIARY]: "0628",
  [Pkm.VULLABY]: "0629",
  [Pkm.MANDIBUZZ]: "0630",
  [Pkm.HEATMOR]: "0631",
  [Pkm.DURANT]: "0632",
  [Pkm.DEINO]: "0633",
  [Pkm.ZWEILOUS]: "0634",
  [Pkm.HYDREIGON]: "0635",
  [Pkm.LARVESTA]: "0636",
  [Pkm.VOLCARONA]: "0637",
  [Pkm.COBALION]: "0638",
  [Pkm.TERRAKION]: "0639",
  [Pkm.VIRIZION]: "0640",
  [Pkm.TORNADUS]: "0641",
  [Pkm.THUNDURUS]: "0642",
  [Pkm.RESHIRAM]: "0643",
  [Pkm.ZEKROM]: "0644",
  [Pkm.LANDORUS]: "0645",
  [Pkm.KYUREM]: "0646",
  [Pkm.KELDEO]: "0647",
  [Pkm.MELOETTA]: "0648",
  [Pkm.PIROUETTE_MELOETTA]: "0648-0001",
  [Pkm.GENESECT]: "0649",

  // Gen 6

  //[Pkm.CHESTPIN]: "0650",
  //[Pkm.QUILLADIN]: "0651",
  //[Pkm.CHESNAUGHT]: "0652",
  [Pkm.FENNEKIN]: "0653",
  [Pkm.BRAIXEN]: "0654",
  [Pkm.DELPHOX]: "0655",
  [Pkm.FROAKIE]: "0656",
  [Pkm.FROGADIER]: "0657",
  [Pkm.GRENINJA]: "0658",
  //[Pkm.BUNNELBY]: "0659",
  //[Pkm.DIGGERSBY]: "0660",
  [Pkm.FLETCHLING]: "0661",
  [Pkm.FLETCHINDER]: "0662",
  [Pkm.TALONFLAME]: "0663",
  //[Pkm.SCATTERBUG]: "0664",
  //[Pkm.SPEWPA]: "0665",
  //[Pkm.VIVILLON]: "0666",
  //[Pkm.LITLEO]: "0667",
  //[Pkm.PYROAR]: "0668",
  [Pkm.FLABEBE]: "0669",
  [Pkm.FLOETTE]: "0670",
  [Pkm.FLORGES]: "0671",
  //[Pkm.SKIDDO]: "0672",
  //[Pkm.GOGOAT]: "0673",
  //[Pkm.PANCHAM]: "0674",
  //[Pkm.PANGORO]: "0675",
  //[Pkm.FURFROU]: "0676",
  //[Pkm.ESPURR]: "0677",
  //[Pkm.MEOWSTIC]: "0678",
  [Pkm.HONEDGE]: "0679",
  [Pkm.DOUBLADE]: "0680",
  [Pkm.AEGISLASH]: "0681",
  [Pkm.AEGISLASH_BLADE]: "0681-0001",
  //[Pkm.SPRITZEE]: "0682",
  //[Pkm.AROMATISSE]: "0683",
  //[Pkm.SWIRLIX]: "0684",
  //[Pkm.SLURPUFF]: "0685",
  [Pkm.INKAY]: "0686",
  [Pkm.MALAMAR]: "0687",
  [Pkm.BINACLE]: "0688",
  [Pkm.BARBARACLE]: "0689",
  //[Pkm.SKRELP]: "0690",
  //[Pkm.DRAGALGE]: "0691",
  [Pkm.CLAUNCHER]: "0692",
  [Pkm.CLAWITZER]: "0693",
  [Pkm.HELIOPTILE]: "0694",
  [Pkm.HELIOLISK]: "0695",
  [Pkm.TYRUNT]: "0696",
  [Pkm.TYRANTRUM]: "0697",
  [Pkm.AMAURA]: "0698",
  [Pkm.AURORUS]: "0699",
  [Pkm.SYLVEON]: "0700",
  [Pkm.HAWLUCHA]: "0701",
  //[Pkm.DEDENNE]: "0702",
  [Pkm.CARBINK]: "0703",
  [Pkm.GOOMY]: "0704",
  [Pkm.SLIGOO]: "0705",
  [Pkm.GOODRA]: "0706",
  [Pkm.HISUI_SLIGGOO]: "0705-0001",
  [Pkm.HISUI_GOODRA]: "0706-0001",
  [Pkm.KLEFKI]: "0707",
  [Pkm.PHANTUMP]: "0708",
  [Pkm.TREVENANT]: "0709",
  [Pkm.PUMPKABOO]: "0710",
  [Pkm.GOURGEIST]: "0711",
  //[Pkm.BERGMITE]: "0712",
  //[Pkm.AVALUGG]: "0713",
  [Pkm.NOIBAT]: "0714",
  [Pkm.NOIVERN]: "0715",
  [Pkm.XERNEAS]: "0716",
  [Pkm.YVELTAL]: "0717",
  [Pkm.ZYGARDE_10]: "0718-0002",
  [Pkm.ZYGARDE_50]: "0718",
  [Pkm.ZYGARDE_100]: "0718-0001",
  [Pkm.DIANCIE]: "0719",
  [Pkm.HOOPA]: "0720",
  [Pkm.HOOPA_UNBOUND]: "0720-0001",
  [Pkm.VOLCANION]: "0721",
  [Pkm.ROWLET]: "0722",
  [Pkm.DARTIX]: "0723",
  [Pkm.DECIDUEYE]: "0724",
  [Pkm.LITTEN]: "0725",
  [Pkm.TORRACAT]: "0726",
  [Pkm.INCINEROAR]: "0727",
  [Pkm.POPPLIO]: "0728",
  [Pkm.BRIONNE]: "0729",
  [Pkm.PRIMARINA]: "0730",
  //[Pkm.PIKIPEK]: "0731",
  //[Pkm.TRUMBEAK]: "0732",
  //[Pkm.TOUCANNON]: "0733",
  //[Pkm.YUNGOOS]: "0734",
  //[Pkm.GUMSHOOS]: "0735",
  [Pkm.GRUBBIN]: "0736",
  [Pkm.CHARJABUG]: "0737",
  [Pkm.VIKAVOLT]: "0738",
  [Pkm.CRABRAWLER]: "0739",
  [Pkm.CRABOMINABLE]: "0740",
  //[Pkm.ORICORIO]: "0741",
  [Pkm.CUTIEFLY]: "0742",
  [Pkm.RIBOMBEE]: "0743",
  [Pkm.ROCKRUFF]: "0744",
  [Pkm.LYCANROC_DAY]: "0745",
  [Pkm.LYCANROC_DUSK]: "0745-0002",
  [Pkm.LYCANROC_NIGHT]: "0745-0001",
  [Pkm.WISHIWASHI]: "0746",
  [Pkm.WISHIWASHI_SCHOOL]: "0746-0001",
  //[Pkm.MAREANIE]: "0747",
  //[Pkm.TOXAPEX]: "0748",
  //[Pkm.MUDBRAY]: "0749",
  //[Pkm.MUDSDALE]: "0750",
  [Pkm.DEWPIDER]: "0751",
  [Pkm.ARAQUANID]: "0752",
  //[Pkm.FOMANTIS]: "0753",
  //[Pkm.LURANTIS]: "0754",
  //[Pkm.MORELULL]: "0755",
  //[Pkm.SHIINOTIC]: "0756",
  [Pkm.SALANDIT]: "0757",
  [Pkm.SALAZZLE]: "0758",
  [Pkm.STUFFUL]: "0759",
  [Pkm.BEWEAR]: "0760",
  [Pkm.BOUNSWEET]: "0761",
  [Pkm.STEENEE]: "0762",
  [Pkm.TSAREENA]: "0763",
  [Pkm.COMFEY]: "0764",
  //[Pkm.ORANGURU]: "0765",
  //[Pkm.PASSIMIAN]: "0766",
  //[Pkm.WIMPOD]: "0767",
  //[Pkm.GOLISOPOD]: "0768",
  [Pkm.SANDYGAST]: "0769",
  [Pkm.PALOSSAND]: "0770",
  [Pkm.PYUKUMUKU]: "0771",
  [Pkm.TYPE_NULL]: "0772",
  [Pkm.SILVALLY]: "0773",
  [Pkm.MINIOR]: "0774",
  [Pkm.MINIOR_KERNEL_RED]: "0774-0001",
  [Pkm.MINIOR_KERNEL_BLUE]: "0774-0005",
  [Pkm.MINIOR_KERNEL_ORANGE]: "0774-0002",
  [Pkm.MINIOR_KERNEL_GREEN]: "0774-0004",
  //[Pkm.KOMALA]: "0775",
  [Pkm.TURTONATOR]: "0776",
  //[Pkm.TOGEDAMARU]: "0777",
  [Pkm.MIMIKYU]: "0778",
  [Pkm.MIMIKYU_BUSTED]: "0778-0001",
  [Pkm.BRUXISH]: "0779",
  //[Pkm.DRAMPA]: "0780",
  [Pkm.DHELMISE]: "0781",
  [Pkm.JANGMO_O]: "0782",
  [Pkm.HAKAMO_O]: "0783",
  [Pkm.KOMMO_O]: "0784",
  [Pkm.TAPU_KOKO]: "0785",
  [Pkm.TAPU_LELE]: "0786",
  [Pkm.TAPU_BULU]: "0787",
  [Pkm.TAPU_FINI]: "0788",
  [Pkm.COSMOG]: "0789",
  [Pkm.COSMOEM]: "0790",
  [Pkm.SOLGALEO]: "0791",
  [Pkm.LUNALA]: "0792",
  [Pkm.NIHILEGO]: "0793",
  //[Pkm.BUZZWOLE]: "0794",
  [Pkm.PHEROMOSA]: "0795",
  [Pkm.XURKITREE]: "0796",
  //[Pkm.CELESTEELA]: "0797",
  [Pkm.KARTANA]: "0798",
  [Pkm.GUZZLORD]: "0799",
  [Pkm.NECROZMA]: "0800",
  [Pkm.ULTRA_NECROZMA]: "0800-0003",
  [Pkm.MAGEARNA]: "0801",
  [Pkm.MARSHADOW]: "0802",
  [Pkm.POIPOLE]: "0803",
  [Pkm.NAGANADEL]: "0804",
  [Pkm.STAKATAKA]: "0805",
  [Pkm.BLACEPHALON]: "0806",
  [Pkm.ZERAORA]: "0807",
  //[Pkm.MELTAN]: "0808",
  [Pkm.MELMETAL]: "0809",

  // Gen 8

  //[Pkm.GROOKEY]: "0810",
  //[Pkm.THWACKEY]: "0811",
  //[Pkm.RILLABOOM]: "0812",
  [Pkm.SCORBUNNY]: "0813",
  [Pkm.RABOOT]: "0814",
  [Pkm.CINDERACE]: "0815",
  [Pkm.SOBBLE]: "0816",
  [Pkm.DRIZZILE]: "0817",
  [Pkm.INTELEON]: "0818",
  //[Pkm.SKWOVET]: "0819",
  //[Pkm.GREEDENT]: "0820",
  [Pkm.ROOKIDEE]: "0821",
  [Pkm.CORVISQUIRE]: "0822",
  [Pkm.CORVIKNIGHT]: "0823",
  //[Pkm.BLIPBUG]: "0824",
  //[Pkm.DOTTLE]: "0825",
  //[Pkm.ORBEETLE]: "0826",
  [Pkm.NICKIT]: "0827",
  [Pkm.THIEVUL]: "0828",
  //[Pkm.GOSSEIFLUR]: "0829",
  //[Pkm.ELDEGOSS]: "0830",
  //[Pkm.WOOLOO]: "0831",
  //[Pkm.DUBWOOL]: "0832",
  //[Pkm.CHEWTLE]: "0833",
  //[Pkm.DREDNAW]: "0834",
  //[Pkm.YAMPER]: "0835",
  //[Pkm.BOLTUND]: "0836",
  //[Pkm.ROLYCOLY]: "0837",
  //[Pkm.CARKOL]: "0838",
  //[Pkm.COALOSSAL]: "0839",
  [Pkm.APPLIN]: "0840",
  //[Pkm.FLAPPLE]: "0841",
  [Pkm.APPLETUN]: "0842",
  //[Pkm.SILICOBRA]: "0843",
  //[Pkm.SANDACONDA]: "0844",
  [Pkm.CRAMORANT]: "0845",
  [Pkm.ARROKUDA]: "0846",
  //[Pkm.BARRASKEWDA]: "0847",
  [Pkm.TOXEL]: "0848",
  [Pkm.TOXTRICITY]: "0849",
  [Pkm.SIZZLIPEDE]: "0850",
  [Pkm.CENTISKORCH]: "0851",
  //[Pkm.CLOBBOPUS]: "0852",
  //[Pkm.GRAPPLOCT]: "0853",
  [Pkm.SINISTEA]: "0854",
  [Pkm.POLTEAGEIST]: "0855",
  [Pkm.HATENNA]: "0856",
  [Pkm.HATTREM]: "0857",
  [Pkm.HATTERENE]: "0858",
  [Pkm.IMPIDIMP]: "0859",
  [Pkm.MORGREM]: "0860",
  [Pkm.GRIMMSNARL]: "0861",
  //[Pkm.OBSTAGOON]: "0862",
  //[Pkm.PERRSERKER]: "0863",
  [Pkm.CURSOLA]: "0864",
  //[Pkm.SIRFETCHD]: "0865",
  //[Pkm.MR_RIME]: "0866",
  //[Pkm.RUNERIGUS]: "0867",
  //[Pkm.MILCERY]: "0868",
  //[Pkm.ALPREMIE]: "0869",
  //[Pkm.FALINKS]: "0870",
  //[Pkm.PINCURCHIN]: "0871",
  [Pkm.SNOM]: "0872",
  [Pkm.FROSMOTH]: "0873",
  [Pkm.STONJOURNER]: "0874",
  //[Pkm.EISCUE]: "0875",
  //[Pkm.INDEEDEE]: "0876",
  [Pkm.MORPEKO]: "0877",
  [Pkm.MORPEKO_HANGRY]: "0877-0001",
  //[Pkm.CUFANT]: "0878",
  //[Pkm.COPPERAJAH]: "0879",
  //[Pkm.DRACOZOLT]: "0880",
  //[Pkm.ARCTOZOLT]: "0881",
  [Pkm.DRACOVISH]: "0882",
  //[Pkm.ARCTOVISH]: "0883",
  //[Pkm.DURALUDON]: "0884",
  [Pkm.DREEPY]: "0885",
  [Pkm.DRAKLOAK]: "0886",
  [Pkm.DRAGAPULT]: "0887",
  //[Pkm.ZACIAN]: "0888",
  //[Pkm.ZACIAN_CROWNED]: "0888-0001",
  //[Pkm.ZAMAZENTA]: "0889",
  //[Pkm.ZAMAZENTA_CROWNED]: "0889-0001",
  [Pkm.ETERNATUS]: "0890",
  //[Pkm.ETERNATUS_ETERNAMAX]: "0890-0001",
  //[Pkm.KUBFU]: "0891",
  //[Pkm.URSHIFU_SINGLE]: "0892",
  //[Pkm.URSHIFU_RAPID]: "0892-0001",
  //[Pkm.ZARUDE]: "0893",
  [Pkm.REGIELEKI]: "0894",
  [Pkm.REGIDRAGO]: "0895",
  //[Pkm.GLASTRIER]: "0896",
  [Pkm.SPECTRIER]: "0897",
  //[Pkm.CALYREX]: "0898",
  //[Pkm.CALYREX_ICE]: "0898-0001",
  //[Pkm.CALYREX_SHADOW]: "0898-0002",
  //[Pkm.WYRDEER]: "0899",
  [Pkm.KLEAVOR]: "0900",
  [Pkm.URSALUNA]: "0901",
  [Pkm.URSALUNA_BLOODMOON]: "0901-0001",
  //[Pkm.SNOMERAID]: "0902",
  [Pkm.SNEASLER]: "0903",
  [Pkm.OVERQWIL]: "0904",
  [Pkm.ENAMORUS]: "0905",

  // Gen 9

  //[Pkm.SPRIGATITO]: "0906",
  //[Pkm.FLORAGATO]: "0907",
  //[Pkm.MEOWSCARADA]: "0908",
  [Pkm.FUECOCO]: "0909",
  [Pkm.CROCALOR]: "0910",
  [Pkm.SKELEDIRGE]: "0911",
  //[Pkm.QUAXLY]: "0912",
  //[Pkm.QUAXWELL]: "0913",
  //[Pkm.QUAQUAVAL]: "0914",
  //[Pkm.LECHONK]: "0915",
  //[Pkm.OINKOLOGNE]: "0916",
  //[Pkm.TAROUNTULA]: "0917",
  //[Pkm.SPIDOPS]: "0918",
  //[Pkm.NYMBLE]: "0919",
  //[Pkm.LOKIX]: "0920",
  [Pkm.PAWMI]: "0921",
  [Pkm.PAWMO]: "0922",
  [Pkm.PAWMOT]: "0923",
  [Pkm.TANDEMAUS]: "0924",
  [Pkm.MAUSHOLD_THREE]: "0925-0001",
  [Pkm.MAUSHOLD_FOUR]: "0925",
  //[Pkm.FIDOUGH]: "0926",
  //[Pkm.DACHSBUN]: "0927",
  //[Pkm.SMOLIVE]: "0928",
  //[Pkm.DOLLIV]: "0929",
  //[Pkm.ARBOLIVA]: "0930",
  //[Pkm.SQUAWKABILLY]: "0931",
  //[Pkm.NACLI]: "0932",
  //[Pkm.NACLSTACK]: "0933",
  //[Pkm.GARGANACL]: "0934",
  //[Pkm.CHARCADET]: "0935",
  //[Pkm.ARMAROUGE]: "0936",
  //[Pkm.CERULEDGE]: "0937",
  //[Pkm.TABDULB]: "0938",
  //[Pkm.BELLIBOLT]: "0939",
  [Pkm.WATTREL]: "0940",
  [Pkm.KILOWATTREL]: "0941",
  //[Pkm.MASCHIFF]: "0942",
  //[Pkm.MABOSSTIFF]: "0943",
  //[Pkm.SHROODLE]: "0944",
  //[Pkm.GRAFAIAI]: "0945",
  //[Pkm.BRAMBLIN]: "0946",
  //[Pkm.BRAMBLEGHAST]: "0947",
  //[Pkm.TOEDSCOOL]: "0948",
  //[Pkm.TOEDSCRUEL]: "0949",
  //[Pkm.KLAWF]: "0950",
  //[Pkm.CAPSAKID]: "0951",
  //[Pkm.SCOVILLAIN]: "0952",
  //[Pkm.RELLOR]: "0953",
  //[Pkm.RABSCA]: "0954",
  //[Pkm.FLITTLE]: "0955",
  //[Pkm.ESPATHRA]: "0956",
  [Pkm.TINKATINK]: "0957",
  [Pkm.TINKATUFF]: "0958",
  [Pkm.TINKATON]: "0959",
  //[Pkm.WIGLETT]: "0960",
  //[Pkm.WUGTRIO]: "0961",
  //[Pkm.BOMBIRDIER]: "0962",
  //[Pkm.FINIZEN]: "0963",
  //[Pkm.PALAFIN]: "0964",
  //[Pkm.PALAFIN_HERO]: "0964-0001",
  //[Pkm.VAROOM]: "0965",
  //[Pkm.REVAVROOM]: "0966",
  [Pkm.CYCLIZAR]: "0967",
  //[Pkm.ORTHWOEM]: "0968",
  [Pkm.GLIMMET]: "0969",
  [Pkm.GLIMMORA]: "0970",
  //[Pkm.GREAVARD]: "0971",
  //[Pkm.HOUNDSTONE]: "0972",
  //[Pkm.FLAMIGO]: "0973",
  //[Pkm.CETODDLE]: "0974",
  //[Pkm.CETITAN]: "0975",
  //[Pkm.VELUZA]: "0976",
  //[Pkm.DONDOZO]: "0977",
  //[Pkm.TATSUGIRI]: "0978",
  [Pkm.ANNIHILAPE]: "0979",
  [Pkm.CLODSIRE]: "0980",
  //[Pkm.FARIGIRAF]: "0981",
  //[Pkm.DUDUNSPARCE]: "0982",
  [Pkm.KINGAMBIT]: "0983",
  //[Pkm.GREAT_TUSK]: "0984",
  //[Pkm.SCREAM_TAIL]: "0985",
  //[Pkm.BRUTE_BONNET]: "0986",
  //[Pkm.FLUTTER_MANE]: "0987",
  //[Pkm.SLITHER_WING]: "0988",
  //[Pkm.SANDY_SHOCKS]: "0989",
  //[Pkm.IRON_THREADS]: "0990",
  [Pkm.IRON_BUNDLE]: "0991",
  [Pkm.IRON_HANDS]: "0992",
  //[Pkm.IRON_JUGULIS]: "0993",
  //[Pkm.IRON_MOTH]: "0994",
  //[Pkm.IRON_THORNS]: "0995",
  [Pkm.FRIGIBAX]: "0996",
  [Pkm.ARCTIBAX]: "0997",
  [Pkm.BAXCALIBUR]: "0998",
  [Pkm.GIMMIGHOUL]: "0999-0001",
  [Pkm.GHOLDENGO]: "1000",

  [Pkm.OGERPON_TEAL]: "1017",
  [Pkm.OGERPON_TEAL_MASK]: "1017-0001",
  [Pkm.OGERPON_WELLSPRING]: "1017-0002",
  [Pkm.OGERPON_WELLSPRING_MASK]: "1017-0003",
  [Pkm.OGERPON_HEARTHFLAME]: "1017-0004",
  [Pkm.OGERPON_HEARTHFLAME_MASK]: "1017-0005",
  [Pkm.OGERPON_CORNERSTONE]: "1017-0006",
  [Pkm.OGERPON_CORNERSTONE_MASK]: "1017-0007"
}

export const PkmByIndex: { [index: string]: Pkm } = Object.fromEntries(
  Object.entries(PkmIndex).map(([pkm, index]) => [index, pkm as Pkm])
)

export const PkmFamily: { [key in Pkm]: Pkm } = {
  [Pkm.EGG]: Pkm.EGG,
  [Pkm.BULBASAUR]: Pkm.BULBASAUR,
  [Pkm.IVYSAUR]: Pkm.BULBASAUR,
  [Pkm.VENUSAUR]: Pkm.BULBASAUR,
  [Pkm.CHARMANDER]: Pkm.CHARMANDER,
  [Pkm.CHARMELEON]: Pkm.CHARMANDER,
  [Pkm.CHARIZARD]: Pkm.CHARMANDER,
  [Pkm.SQUIRTLE]: Pkm.SQUIRTLE,
  [Pkm.WARTORTLE]: Pkm.SQUIRTLE,
  [Pkm.BLASTOISE]: Pkm.SQUIRTLE,
  [Pkm.SLOWPOKE]: Pkm.SLOWPOKE,
  [Pkm.SLOWBRO]: Pkm.SLOWPOKE,
  [Pkm.SLOWKING]: Pkm.SLOWPOKE,
  [Pkm.GEODUDE]: Pkm.GEODUDE,
  [Pkm.GRAVELER]: Pkm.GEODUDE,
  [Pkm.GOLEM]: Pkm.GEODUDE,
  [Pkm.AZURILL]: Pkm.AZURILL,
  [Pkm.MARILL]: Pkm.AZURILL,
  [Pkm.AZUMARILL]: Pkm.AZURILL,
  [Pkm.ZUBAT]: Pkm.ZUBAT,
  [Pkm.GOLBAT]: Pkm.ZUBAT,
  [Pkm.CROBAT]: Pkm.ZUBAT,
  [Pkm.AMPHAROS]: Pkm.MAREEP,
  [Pkm.MAREEP]: Pkm.MAREEP,
  [Pkm.FLAFFY]: Pkm.MAREEP,
  [Pkm.CLEFFA]: Pkm.CLEFFA,
  [Pkm.CLEFAIRY]: Pkm.CLEFFA,
  [Pkm.CLEFABLE]: Pkm.CLEFFA,
  [Pkm.IGGLYBUFF]: Pkm.IGGLYBUFF,
  [Pkm.JIGGLYPUFF]: Pkm.IGGLYBUFF,
  [Pkm.WIGGLYTUFF]: Pkm.IGGLYBUFF,
  [Pkm.CATERPIE]: Pkm.CATERPIE,
  [Pkm.METAPOD]: Pkm.CATERPIE,
  [Pkm.BUTTERFREE]: Pkm.CATERPIE,
  [Pkm.WEEDLE]: Pkm.WEEDLE,
  [Pkm.KAKUNA]: Pkm.WEEDLE,
  [Pkm.BEEDRILL]: Pkm.WEEDLE,
  [Pkm.PIDGEY]: Pkm.PIDGEY,
  [Pkm.PIDGEOTTO]: Pkm.PIDGEY,
  [Pkm.PIDGEOT]: Pkm.PIDGEY,
  [Pkm.HOPPIP]: Pkm.HOPPIP,
  [Pkm.SKIPLOOM]: Pkm.HOPPIP,
  [Pkm.JUMPLUFF]: Pkm.HOPPIP,
  [Pkm.SEEDOT]: Pkm.SEEDOT,
  [Pkm.NUZLEAF]: Pkm.SEEDOT,
  [Pkm.SHIFTRY]: Pkm.SEEDOT,
  [Pkm.STARLY]: Pkm.STARLY,
  [Pkm.STARAVIA]: Pkm.STARLY,
  [Pkm.STARAPTOR]: Pkm.STARLY,
  [Pkm.CHIKORITA]: Pkm.CHIKORITA,
  [Pkm.BAYLEEF]: Pkm.CHIKORITA,
  [Pkm.MEGANIUM]: Pkm.CHIKORITA,
  [Pkm.CYNDAQUIL]: Pkm.CYNDAQUIL,
  [Pkm.QUILAVA]: Pkm.CYNDAQUIL,
  [Pkm.TYPHLOSION]: Pkm.CYNDAQUIL,
  [Pkm.TOTODILE]: Pkm.TOTODILE,
  [Pkm.CROCONAW]: Pkm.TOTODILE,
  [Pkm.FERALIGATR]: Pkm.TOTODILE,
  [Pkm.TREECKO]: Pkm.TREECKO,
  [Pkm.GROVYLE]: Pkm.TREECKO,
  [Pkm.SCEPTILE]: Pkm.TREECKO,
  [Pkm.TORCHIC]: Pkm.TORCHIC,
  [Pkm.COMBUSKEN]: Pkm.TORCHIC,
  [Pkm.BLAZIKEN]: Pkm.TORCHIC,
  [Pkm.MUDKIP]: Pkm.MUDKIP,
  [Pkm.MARSHTOMP]: Pkm.MUDKIP,
  [Pkm.SWAMPERT]: Pkm.MUDKIP,
  [Pkm.TURTWIG]: Pkm.TURTWIG,
  [Pkm.GROTLE]: Pkm.TURTWIG,
  [Pkm.TORTERRA]: Pkm.TURTWIG,
  [Pkm.CHIMCHAR]: Pkm.CHIMCHAR,
  [Pkm.MONFERNO]: Pkm.CHIMCHAR,
  [Pkm.INFERNAPE]: Pkm.CHIMCHAR,
  [Pkm.PIPLUP]: Pkm.PIPLUP,
  [Pkm.PRINPLUP]: Pkm.PIPLUP,
  [Pkm.EMPOLEON]: Pkm.PIPLUP,
  [Pkm.NIDORANF]: Pkm.NIDORANF,
  [Pkm.NIDORINA]: Pkm.NIDORANF,
  [Pkm.NIDOQUEEN]: Pkm.NIDORANF,
  [Pkm.NIDORANM]: Pkm.NIDORANM,
  [Pkm.NIDORINO]: Pkm.NIDORANM,
  [Pkm.NIDOKING]: Pkm.NIDORANM,
  [Pkm.PICHU]: Pkm.PICHU,
  [Pkm.PIKACHU]: Pkm.PICHU,
  [Pkm.RAICHU]: Pkm.PICHU,
  [Pkm.MACHOP]: Pkm.MACHOP,
  [Pkm.MACHOKE]: Pkm.MACHOP,
  [Pkm.MACHAMP]: Pkm.MACHOP,
  [Pkm.HORSEA]: Pkm.HORSEA,
  [Pkm.SEADRA]: Pkm.HORSEA,
  [Pkm.KINGDRA]: Pkm.HORSEA,
  [Pkm.TRAPINCH]: Pkm.TRAPINCH,
  [Pkm.VIBRAVA]: Pkm.TRAPINCH,
  [Pkm.FLYGON]: Pkm.TRAPINCH,
  [Pkm.SPHEAL]: Pkm.SPHEAL,
  [Pkm.SEALEO]: Pkm.SPHEAL,
  [Pkm.WALREIN]: Pkm.SPHEAL,
  [Pkm.ARON]: Pkm.ARON,
  [Pkm.LAIRON]: Pkm.ARON,
  [Pkm.AGGRON]: Pkm.ARON,
  [Pkm.MAGNEMITE]: Pkm.MAGNEMITE,
  [Pkm.MAGNETON]: Pkm.MAGNEMITE,
  [Pkm.MAGNEZONE]: Pkm.MAGNEMITE,
  [Pkm.RHYHORN]: Pkm.RHYHORN,
  [Pkm.RHYDON]: Pkm.RHYHORN,
  [Pkm.RHYPERIOR]: Pkm.RHYHORN,
  [Pkm.TOGEPI]: Pkm.TOGEPI,
  [Pkm.TOGETIC]: Pkm.TOGEPI,
  [Pkm.TOGEKISS]: Pkm.TOGEPI,
  [Pkm.DUSKULL]: Pkm.DUSKULL,
  [Pkm.DUSCLOPS]: Pkm.DUSKULL,
  [Pkm.DUSKNOIR]: Pkm.DUSKULL,
  [Pkm.LOTAD]: Pkm.LOTAD,
  [Pkm.LOMBRE]: Pkm.LOTAD,
  [Pkm.LUDICOLO]: Pkm.LOTAD,
  [Pkm.SHINX]: Pkm.SHINX,
  [Pkm.LUXIO]: Pkm.SHINX,
  [Pkm.LUXRAY]: Pkm.SHINX,
  [Pkm.POLIWAG]: Pkm.POLIWAG,
  [Pkm.POLIWHIRL]: Pkm.POLIWAG,
  [Pkm.POLITOED]: Pkm.POLIWAG,
  [Pkm.ABRA]: Pkm.ABRA,
  [Pkm.KADABRA]: Pkm.ABRA,
  [Pkm.ALAKAZAM]: Pkm.ABRA,
  [Pkm.GASTLY]: Pkm.GASTLY,
  [Pkm.HAUNTER]: Pkm.GASTLY,
  [Pkm.GENGAR]: Pkm.GASTLY,
  [Pkm.DRATINI]: Pkm.DRATINI,
  [Pkm.DRAGONAIR]: Pkm.DRATINI,
  [Pkm.DRAGONITE]: Pkm.DRATINI,
  [Pkm.LARVITAR]: Pkm.LARVITAR,
  [Pkm.PUPITAR]: Pkm.LARVITAR,
  [Pkm.TYRANITAR]: Pkm.LARVITAR,
  [Pkm.SLAKOTH]: Pkm.SLAKOTH,
  [Pkm.VIGOROTH]: Pkm.SLAKOTH,
  [Pkm.SLAKING]: Pkm.SLAKOTH,
  [Pkm.RALTS]: Pkm.RALTS,
  [Pkm.KIRLIA]: Pkm.RALTS,
  [Pkm.GARDEVOIR]: Pkm.RALTS,
  [Pkm.BAGON]: Pkm.BAGON,
  [Pkm.SHELGON]: Pkm.BAGON,
  [Pkm.SALAMENCE]: Pkm.BAGON,
  [Pkm.BELDUM]: Pkm.BELDUM,
  [Pkm.METANG]: Pkm.BELDUM,
  [Pkm.METAGROSS]: Pkm.BELDUM,
  [Pkm.GIBLE]: Pkm.GIBLE,
  [Pkm.GABITE]: Pkm.GIBLE,
  [Pkm.GARCHOMP]: Pkm.GIBLE,
  [Pkm.ELEKID]: Pkm.ELEKID,
  [Pkm.ELECTABUZZ]: Pkm.ELEKID,
  [Pkm.ELECTIVIRE]: Pkm.ELEKID,
  [Pkm.MAGBY]: Pkm.MAGBY,
  [Pkm.MAGMAR]: Pkm.MAGBY,
  [Pkm.MAGMORTAR]: Pkm.MAGBY,
  [Pkm.MUNCHLAX]: Pkm.MUNCHLAX,
  [Pkm.SNORLAX]: Pkm.MUNCHLAX,
  [Pkm.GROWLITHE]: Pkm.GROWLITHE,
  [Pkm.ARCANINE]: Pkm.GROWLITHE,
  [Pkm.HISUI_GROWLITHE]: Pkm.HISUI_GROWLITHE,
  [Pkm.HISUI_ARCANINE]: Pkm.HISUI_GROWLITHE,
  [Pkm.ONIX]: Pkm.ONIX,
  [Pkm.STEELIX]: Pkm.ONIX,
  [Pkm.MEGA_STEELIX]: Pkm.ONIX,
  [Pkm.SCYTHER]: Pkm.SCYTHER,
  [Pkm.SCIZOR]: Pkm.SCYTHER,
  [Pkm.KLEAVOR]: Pkm.SCYTHER,
  [Pkm.RIOLU]: Pkm.RIOLU,
  [Pkm.LUCARIO]: Pkm.RIOLU,
  [Pkm.EEVEE]: Pkm.EEVEE,
  [Pkm.VAPOREON]: Pkm.VAPOREON,
  [Pkm.JOLTEON]: Pkm.JOLTEON,
  [Pkm.FLAREON]: Pkm.FLAREON,
  [Pkm.ESPEON]: Pkm.ESPEON,
  [Pkm.UMBREON]: Pkm.UMBREON,
  [Pkm.LEAFEON]: Pkm.LEAFEON,
  [Pkm.SYLVEON]: Pkm.SYLVEON,
  [Pkm.GLACEON]: Pkm.GLACEON,
  [Pkm.MEDITITE]: Pkm.MEDITITE,
  [Pkm.MEDICHAM]: Pkm.MEDITITE,
  [Pkm.NUMEL]: Pkm.NUMEL,
  [Pkm.CAMERUPT]: Pkm.NUMEL,
  [Pkm.MEGA_CAMERUPT]: Pkm.NUMEL,
  [Pkm.DITTO]: Pkm.DITTO,
  [Pkm.DARKRAI]: Pkm.DARKRAI,
  [Pkm.LITWICK]: Pkm.LITWICK,
  [Pkm.LAMPENT]: Pkm.LITWICK,
  [Pkm.CHANDELURE]: Pkm.LITWICK,
  [Pkm.BELLSPROUT]: Pkm.BELLSPROUT,
  [Pkm.WEEPINBELL]: Pkm.BELLSPROUT,
  [Pkm.VICTREEBEL]: Pkm.BELLSPROUT,
  [Pkm.SWINUB]: Pkm.SWINUB,
  [Pkm.PILOSWINE]: Pkm.SWINUB,
  [Pkm.MAMOSWINE]: Pkm.SWINUB,
  [Pkm.SNORUNT]: Pkm.SNORUNT,
  [Pkm.GLALIE]: Pkm.SNORUNT,
  [Pkm.FROSLASS]: Pkm.SNORUNT,
  [Pkm.SNOVER]: Pkm.SNOVER,
  [Pkm.ABOMASNOW]: Pkm.SNOVER,
  [Pkm.MEGA_ABOMASNOW]: Pkm.SNOVER,
  [Pkm.VANILLITE]: Pkm.VANILLITE,
  [Pkm.VANILLISH]: Pkm.VANILLITE,
  [Pkm.VANILLUXE]: Pkm.VANILLITE,
  [Pkm.LARVESTA]: Pkm.LARVESTA,
  [Pkm.VOLCARONA]: Pkm.LARVESTA,
  [Pkm.LANDORUS]: Pkm.LANDORUS,
  [Pkm.TORNADUS]: Pkm.TORNADUS,
  [Pkm.THUNDURUS]: Pkm.THUNDURUS,
  [Pkm.ENAMORUS]: Pkm.ENAMORUS,
  [Pkm.KELDEO]: Pkm.KELDEO,
  [Pkm.TERRAKION]: Pkm.TERRAKION,
  [Pkm.VIRIZION]: Pkm.VIRIZION,
  [Pkm.COBALION]: Pkm.COBALION,
  [Pkm.MANAPHY]: Pkm.PHIONE,
  [Pkm.ROTOM]: Pkm.ROTOM,
  [Pkm.ROTOM_DRONE]: Pkm.ROTOM_DRONE,
  [Pkm.SPIRITOMB]: Pkm.SPIRITOMB,
  [Pkm.ABSOL]: Pkm.ABSOL,
  [Pkm.LAPRAS]: Pkm.LAPRAS,
  [Pkm.LATIAS]: Pkm.LATIAS,
  [Pkm.LATIOS]: Pkm.LATIOS, // not the same family than latias so they count as two for their synergies
  [Pkm.MESPRIT]: Pkm.MESPRIT,
  [Pkm.AZELF]: Pkm.AZELF,
  [Pkm.UXIE]: Pkm.UXIE,
  [Pkm.MEWTWO]: Pkm.MEWTWO,
  [Pkm.KYUREM]: Pkm.KYUREM,
  [Pkm.RESHIRAM]: Pkm.RESHIRAM,
  [Pkm.ZEKROM]: Pkm.ZEKROM,
  [Pkm.CELEBI]: Pkm.CELEBI,
  [Pkm.VICTINI]: Pkm.VICTINI,
  [Pkm.JIRACHI]: Pkm.JIRACHI,
  [Pkm.ARCEUS]: Pkm.ARCEUS,
  [Pkm.SHAYMIN]: Pkm.SHAYMIN,
  [Pkm.CRESSELIA]: Pkm.CRESSELIA,
  [Pkm.HEATRAN]: Pkm.HEATRAN,
  [Pkm.HO_OH]: Pkm.HO_OH,
  [Pkm.REGICE]: Pkm.REGICE,
  [Pkm.REGISTEEL]: Pkm.REGISTEEL,
  [Pkm.REGIROCK]: Pkm.REGIROCK,
  [Pkm.ARTICUNO]: Pkm.ARTICUNO,
  [Pkm.GALARIAN_ARTICUNO]: Pkm.GALARIAN_ARTICUNO,
  [Pkm.ZAPDOS]: Pkm.ZAPDOS,
  [Pkm.GALARIAN_ZAPDOS]: Pkm.GALARIAN_ZAPDOS,
  [Pkm.MOLTRES]: Pkm.MOLTRES,
  [Pkm.GALARIAN_MOLTRES]: Pkm.GALARIAN_MOLTRES,
  [Pkm.AERODACTYL]: Pkm.AERODACTYL,
  [Pkm.GROUDON]: Pkm.GROUDON,
  [Pkm.KYOGRE]: Pkm.KYOGRE,
  [Pkm.RAYQUAZA]: Pkm.RAYQUAZA,
  [Pkm.MEGA_RAYQUAZA]: Pkm.RAYQUAZA,
  [Pkm.PALKIA]: Pkm.PALKIA,
  [Pkm.DIALGA]: Pkm.DIALGA,
  [Pkm.GIRATINA]: Pkm.GIRATINA,
  [Pkm.SUICUNE]: Pkm.SUICUNE,
  [Pkm.ENTEI]: Pkm.ENTEI,
  [Pkm.RAIKOU]: Pkm.RAIKOU,
  [Pkm.REGIGIGAS]: Pkm.REGIGIGAS,
  [Pkm.MAGIKARP]: Pkm.MAGIKARP,
  [Pkm.GYARADOS]: Pkm.MAGIKARP,
  [Pkm.RATTATA]: Pkm.RATTATA,
  [Pkm.ALOLAN_RATTATA]: Pkm.ALOLAN_RATTATA,
  [Pkm.RATICATE]: Pkm.RATTATA,
  [Pkm.ALOLAN_RATICATE]: Pkm.ALOLAN_RATTATA,
  [Pkm.LUGIA]: Pkm.LUGIA,
  [Pkm.SHADOW_LUGIA]: Pkm.LUGIA,
  [Pkm.CARVANHA]: Pkm.CARVANHA,
  [Pkm.SWABLU]: Pkm.SWABLU,
  [Pkm.PRIMAL_GROUDON]: Pkm.GROUDON,
  [Pkm.PRIMAL_KYOGRE]: Pkm.KYOGRE,
  [Pkm.FEAROW]: Pkm.SPEAROW,
  [Pkm.SPEAROW]: Pkm.SPEAROW,
  [Pkm.MEOWTH]: Pkm.MEOWTH,
  [Pkm.PERSIAN]: Pkm.MEOWTH,
  [Pkm.ALOLAN_MEOWTH]: Pkm.ALOLAN_MEOWTH,
  [Pkm.ALOLAN_PERSIAN]: Pkm.ALOLAN_MEOWTH,
  [Pkm.DEINO]: Pkm.DEINO,
  [Pkm.ZWEILOUS]: Pkm.DEINO,
  [Pkm.HYDREIGON]: Pkm.DEINO,
  [Pkm.SANDILE]: Pkm.SANDILE,
  [Pkm.KROKOROK]: Pkm.SANDILE,
  [Pkm.KROOKODILE]: Pkm.SANDILE,
  [Pkm.SOLOSIS]: Pkm.SOLOSIS,
  [Pkm.DUOSION]: Pkm.SOLOSIS,
  [Pkm.REUNICLUS]: Pkm.SOLOSIS,
  [Pkm.ODDISH]: Pkm.ODDISH,
  [Pkm.GLOOM]: Pkm.ODDISH,
  [Pkm.VILEPLUME]: Pkm.ODDISH,
  [Pkm.BELLOSSOM]: Pkm.ODDISH,
  [Pkm.AMAURA]: Pkm.AMAURA,
  [Pkm.AURORUS]: Pkm.AMAURA,
  [Pkm.ANORITH]: Pkm.ANORITH,
  [Pkm.ARMALDO]: Pkm.ANORITH,
  [Pkm.ARCHEN]: Pkm.ARCHEN,
  [Pkm.ARCHEOPS]: Pkm.ARCHEN,
  [Pkm.SHIELDON]: Pkm.SHIELDON,
  [Pkm.BASTIODON]: Pkm.SHIELDON,
  //[Pkm.TIRTOUGA]: Pkm.TIRTOUGA,
  //[Pkm.CARRACOSTA]: Pkm.TIRTOUGA,
  [Pkm.LILEEP]: Pkm.LILEEP,
  [Pkm.CRADILY]: Pkm.LILEEP,
  [Pkm.KABUTO]: Pkm.KABUTO,
  [Pkm.KABUTOPS]: Pkm.KABUTO,
  [Pkm.OMANYTE]: Pkm.OMANYTE,
  [Pkm.OMASTAR]: Pkm.OMANYTE,
  [Pkm.CRANIDOS]: Pkm.CRANIDOS,
  [Pkm.RAMPARDOS]: Pkm.CRANIDOS,
  [Pkm.TYRUNT]: Pkm.TYRUNT,
  [Pkm.TYRANTRUM]: Pkm.TYRUNT,
  [Pkm.BUDEW]: Pkm.BUDEW,
  [Pkm.ROSELIA]: Pkm.BUDEW,
  [Pkm.ROSERADE]: Pkm.BUDEW,
  [Pkm.BUNEARY]: Pkm.BUNEARY,
  [Pkm.LOPUNNY]: Pkm.BUNEARY,
  [Pkm.MEGA_LOPUNNY]: Pkm.BUNEARY,
  [Pkm.AXEW]: Pkm.AXEW,
  [Pkm.FRAXURE]: Pkm.AXEW,
  [Pkm.HAXORUS]: Pkm.AXEW,
  [Pkm.VENIPEDE]: Pkm.VENIPEDE,
  [Pkm.WHIRLIPEDE]: Pkm.VENIPEDE,
  [Pkm.SCOLIPEDE]: Pkm.VENIPEDE,
  [Pkm.PORYGON]: Pkm.PORYGON,
  [Pkm.PORYGON_2]: Pkm.PORYGON,
  [Pkm.PORYGON_Z]: Pkm.PORYGON,
  [Pkm.ELECTRIKE]: Pkm.ELECTRIKE,
  [Pkm.MANECTRIC]: Pkm.ELECTRIKE,
  [Pkm.MEGA_MANECTRIC]: Pkm.ELECTRIKE,
  [Pkm.SHUPPET]: Pkm.SHUPPET,
  [Pkm.BANETTE]: Pkm.SHUPPET,
  [Pkm.MEGA_BANETTE]: Pkm.SHUPPET,
  [Pkm.HONEDGE]: Pkm.HONEDGE,
  [Pkm.DOUBLADE]: Pkm.HONEDGE,
  [Pkm.AEGISLASH]: Pkm.HONEDGE,
  [Pkm.AEGISLASH_BLADE]: Pkm.HONEDGE,
  [Pkm.CUBONE]: Pkm.CUBONE,
  [Pkm.MAROWAK]: Pkm.CUBONE,
  [Pkm.ALOLAN_MAROWAK]: Pkm.CUBONE,
  [Pkm.WHISMUR]: Pkm.WHISMUR,
  [Pkm.LOUDRED]: Pkm.WHISMUR,
  [Pkm.EXPLOUD]: Pkm.WHISMUR,
  [Pkm.TYMPOLE]: Pkm.TYMPOLE,
  [Pkm.PALPITOAD]: Pkm.TYMPOLE,
  [Pkm.SEISMITOAD]: Pkm.TYMPOLE,
  [Pkm.SEWADDLE]: Pkm.SEWADDLE,
  [Pkm.SWADLOON]: Pkm.SEWADDLE,
  [Pkm.LEAVANNY]: Pkm.SEWADDLE,
  //[Pkm.PIKIPEK]: Pkm.PIKIPEK,
  //[Pkm.TRUMBEAK]: Pkm.PIKIPEK,
  //[Pkm.TOUCANNON]: Pkm.PIKIPEK,
  [Pkm.FLABEBE]: Pkm.FLABEBE,
  [Pkm.FLOETTE]: Pkm.FLABEBE,
  [Pkm.FLORGES]: Pkm.FLABEBE,
  [Pkm.JANGMO_O]: Pkm.JANGMO_O,
  [Pkm.HAKAMO_O]: Pkm.JANGMO_O,
  [Pkm.KOMMO_O]: Pkm.JANGMO_O,
  [Pkm.MELOETTA]: Pkm.MELOETTA,
  [Pkm.ALTARIA]: Pkm.SWABLU,
  [Pkm.MEGA_ALTARIA]: Pkm.SWABLU,
  [Pkm.CASTFORM]: Pkm.CASTFORM,
  [Pkm.CASTFORM_SUN]: Pkm.CASTFORM,
  [Pkm.CASTFORM_RAIN]: Pkm.CASTFORM,
  [Pkm.CASTFORM_HAIL]: Pkm.CASTFORM,
  [Pkm.CORPHISH]: Pkm.CORPHISH,
  [Pkm.CRAWDAUNT]: Pkm.CORPHISH,
  [Pkm.JOLTIK]: Pkm.JOLTIK,
  [Pkm.GALVANTULA]: Pkm.JOLTIK,
  [Pkm.DEFAULT]: Pkm.DEFAULT,
  [Pkm.NINCADA]: Pkm.NINCADA,
  [Pkm.NINJASK]: Pkm.NINCADA,
  [Pkm.SHEDINJA]: Pkm.SHEDINJA,
  [Pkm.PONYTA]: Pkm.PONYTA,
  [Pkm.RAPIDASH]: Pkm.PONYTA,
  [Pkm.GALARIAN_PONYTA]: Pkm.GALARIAN_PONYTA,
  [Pkm.GALARIAN_RAPIDASH]: Pkm.GALARIAN_PONYTA,
  [Pkm.GENESECT]: Pkm.GENESECT,
  [Pkm.RELICANTH]: Pkm.RELICANTH,
  [Pkm.HATENNA]: Pkm.HATENNA,
  [Pkm.HATTREM]: Pkm.HATENNA,
  [Pkm.HATTERENE]: Pkm.HATENNA,
  [Pkm.FENNEKIN]: Pkm.FENNEKIN,
  [Pkm.BRAIXEN]: Pkm.FENNEKIN,
  [Pkm.DELPHOX]: Pkm.FENNEKIN,
  [Pkm.MAKUHITA]: Pkm.MAKUHITA,
  [Pkm.HARIYAMA]: Pkm.MAKUHITA,
  [Pkm.REGIELEKI]: Pkm.REGIELEKI,
  [Pkm.REGIDRAGO]: Pkm.REGIDRAGO,
  [Pkm.GUZZLORD]: Pkm.GUZZLORD,
  [Pkm.ETERNATUS]: Pkm.ETERNATUS,
  [Pkm.NOIBAT]: Pkm.NOIBAT,
  [Pkm.NOIVERN]: Pkm.NOIBAT,
  [Pkm.PUMPKABOO]: Pkm.PUMPKABOO,
  [Pkm.GOURGEIST]: Pkm.PUMPKABOO,
  [Pkm.CACNEA]: Pkm.CACNEA,
  [Pkm.CACTURNE]: Pkm.CACNEA,
  [Pkm.TAUROS]: Pkm.TAUROS,
  [Pkm.HAPPINY]: Pkm.HAPPINY,
  [Pkm.CHANSEY]: Pkm.HAPPINY,
  [Pkm.BLISSEY]: Pkm.HAPPINY,
  [Pkm.TAPU_KOKO]: Pkm.TAPU_KOKO,
  [Pkm.TAPU_LELE]: Pkm.TAPU_LELE,
  [Pkm.STAKATAKA]: Pkm.STAKATAKA,
  [Pkm.BLACEPHALON]: Pkm.BLACEPHALON,
  [Pkm.HOUNDOUR]: Pkm.HOUNDOUR,
  [Pkm.HOUNDOOM]: Pkm.HOUNDOUR,
  [Pkm.MEGA_HOUNDOOM]: Pkm.HOUNDOUR,
  [Pkm.CLAMPERL]: Pkm.CLAMPERL,
  [Pkm.HUNTAIL]: Pkm.CLAMPERL,
  [Pkm.GOREBYSS]: Pkm.CLAMPERL,
  [Pkm.SMOOCHUM]: Pkm.SMOOCHUM,
  [Pkm.JYNX]: Pkm.SMOOCHUM,
  [Pkm.SALANDIT]: Pkm.SALANDIT,
  [Pkm.SALAZZLE]: Pkm.SALANDIT,
  [Pkm.VENONAT]: Pkm.VENONAT,
  [Pkm.VENOMOTH]: Pkm.VENONAT,
  [Pkm.VOLTORB]: Pkm.VOLTORB,
  [Pkm.HISUI_VOLTORB]: Pkm.HISUI_VOLTORB,
  [Pkm.ELECTRODE]: Pkm.VOLTORB,
  [Pkm.HISUI_ELECTRODE]: Pkm.HISUI_VOLTORB,
  [Pkm.SLUGMA]: Pkm.SLUGMA,
  [Pkm.MAGCARGO]: Pkm.SLUGMA,
  [Pkm.SNEASEL]: Pkm.SNEASEL,
  [Pkm.WEAVILE]: Pkm.SNEASEL,
  [Pkm.CROAGUNK]: Pkm.CROAGUNK,
  [Pkm.TOXICROAK]: Pkm.CROAGUNK,
  [Pkm.CHINCHOU]: Pkm.CHINCHOU,
  [Pkm.LANTURN]: Pkm.CHINCHOU,
  [Pkm.POOCHYENA]: Pkm.POOCHYENA,
  [Pkm.MIGHTYENA]: Pkm.POOCHYENA,
  [Pkm.BRONZOR]: Pkm.BRONZOR,
  [Pkm.BRONZONG]: Pkm.BRONZOR,
  [Pkm.DRIFLOON]: Pkm.DRIFLOON,
  [Pkm.DRIFBLIM]: Pkm.DRIFLOON,
  [Pkm.SHROOMISH]: Pkm.SHROOMISH,
  [Pkm.BRELOOM]: Pkm.SHROOMISH,
  [Pkm.TENTACOOL]: Pkm.TENTACOOL,
  [Pkm.TENTACRUEL]: Pkm.TENTACOOL,
  [Pkm.SNUBULL]: Pkm.SNUBULL,
  [Pkm.GRANBULL]: Pkm.SNUBULL,
  [Pkm.SEVIPER]: Pkm.SEVIPER,
  [Pkm.VULPIX]: Pkm.VULPIX,
  [Pkm.NINETALES]: Pkm.VULPIX,
  [Pkm.ALOLAN_VULPIX]: Pkm.ALOLAN_VULPIX,
  [Pkm.ALOLAN_NINETALES]: Pkm.ALOLAN_VULPIX,
  [Pkm.BUIZEL]: Pkm.BUIZEL,
  [Pkm.FLOATZEL]: Pkm.BUIZEL,
  [Pkm.KECLEON]: Pkm.KECLEON,
  [Pkm.MAWILE]: Pkm.MAWILE,
  [Pkm.CARBINK]: Pkm.CARBINK,
  [Pkm.DIANCIE]: Pkm.CARBINK,
  [Pkm.CHATOT]: Pkm.CHATOT,
  [Pkm.GOOMY]: Pkm.GOOMY,
  [Pkm.SLIGOO]: Pkm.GOOMY,
  [Pkm.GOODRA]: Pkm.GOOMY,
  [Pkm.HISUI_SLIGGOO]: Pkm.GOOMY,
  [Pkm.HISUI_GOODRA]: Pkm.GOOMY,
  [Pkm.MEW]: Pkm.MEW,
  [Pkm.BOUNSWEET]: Pkm.BOUNSWEET,
  [Pkm.STEENEE]: Pkm.BOUNSWEET,
  [Pkm.TSAREENA]: Pkm.BOUNSWEET,
  [Pkm.VOLCANION]: Pkm.VOLCANION,
  [Pkm.APPLIN]: Pkm.APPLIN,
  [Pkm.APPLETUN]: Pkm.APPLIN,
  [Pkm.OSHAWOTT]: Pkm.OSHAWOTT,
  [Pkm.DEWOTT]: Pkm.OSHAWOTT,
  [Pkm.SAMUROTT]: Pkm.OSHAWOTT,
  [Pkm.SNOM]: Pkm.SNOM,
  [Pkm.FROSMOTH]: Pkm.SNOM,
  [Pkm.WAILMER]: Pkm.WAILMER,
  [Pkm.WAILORD]: Pkm.WAILMER,
  [Pkm.DREEPY]: Pkm.DREEPY,
  [Pkm.DRAKLOAK]: Pkm.DREEPY,
  [Pkm.DRAGAPULT]: Pkm.DREEPY,
  [Pkm.SNIVY]: Pkm.SNIVY,
  [Pkm.SERVINE]: Pkm.SNIVY,
  [Pkm.SERPERIOR]: Pkm.SNIVY,
  [Pkm.SCORBUNNY]: Pkm.SCORBUNNY,
  [Pkm.RABOOT]: Pkm.SCORBUNNY,
  [Pkm.CINDERACE]: Pkm.SCORBUNNY,
  [Pkm.POPPLIO]: Pkm.POPPLIO,
  [Pkm.BRIONNE]: Pkm.POPPLIO,
  [Pkm.PRIMARINA]: Pkm.POPPLIO,
  [Pkm.GOTHITA]: Pkm.GOTHITA,
  [Pkm.GOTHORITA]: Pkm.GOTHITA,
  [Pkm.GOTHITELLE]: Pkm.GOTHITA,
  [Pkm.SANDSHREW]: Pkm.SANDSHREW,
  [Pkm.SANDSLASH]: Pkm.SANDSHREW,
  [Pkm.FARFETCH_D]: Pkm.FARFETCH_D,
  [Pkm.UNOWN_A]: Pkm.UNOWN_A,
  [Pkm.UNOWN_B]: Pkm.UNOWN_A,
  [Pkm.UNOWN_C]: Pkm.UNOWN_A,
  [Pkm.UNOWN_D]: Pkm.UNOWN_A,
  [Pkm.UNOWN_E]: Pkm.UNOWN_A,
  [Pkm.UNOWN_F]: Pkm.UNOWN_A,
  [Pkm.UNOWN_G]: Pkm.UNOWN_A,
  [Pkm.UNOWN_H]: Pkm.UNOWN_A,
  [Pkm.UNOWN_I]: Pkm.UNOWN_A,
  [Pkm.UNOWN_J]: Pkm.UNOWN_A,
  [Pkm.UNOWN_K]: Pkm.UNOWN_A,
  [Pkm.UNOWN_L]: Pkm.UNOWN_A,
  [Pkm.UNOWN_M]: Pkm.UNOWN_A,
  [Pkm.UNOWN_N]: Pkm.UNOWN_A,
  [Pkm.UNOWN_O]: Pkm.UNOWN_A,
  [Pkm.UNOWN_P]: Pkm.UNOWN_A,
  [Pkm.UNOWN_Q]: Pkm.UNOWN_A,
  [Pkm.UNOWN_R]: Pkm.UNOWN_A,
  [Pkm.UNOWN_S]: Pkm.UNOWN_A,
  [Pkm.UNOWN_T]: Pkm.UNOWN_A,
  [Pkm.UNOWN_U]: Pkm.UNOWN_A,
  [Pkm.UNOWN_V]: Pkm.UNOWN_A,
  [Pkm.UNOWN_W]: Pkm.UNOWN_A,
  [Pkm.UNOWN_X]: Pkm.UNOWN_A,
  [Pkm.UNOWN_Y]: Pkm.UNOWN_A,
  [Pkm.UNOWN_Z]: Pkm.UNOWN_A,
  [Pkm.UNOWN_QUESTION]: Pkm.UNOWN_A,
  [Pkm.UNOWN_EXCLAMATION]: Pkm.UNOWN_A,
  [Pkm.TAPU_FINI]: Pkm.TAPU_FINI,
  [Pkm.TAPU_BULU]: Pkm.TAPU_BULU,
  [Pkm.DIGLETT]: Pkm.DIGLETT,
  [Pkm.ALOLAN_DIGLETT]: Pkm.ALOLAN_DIGLETT,
  [Pkm.DUGTRIO]: Pkm.DIGLETT,
  [Pkm.ALOLAN_DUGTRIO]: Pkm.ALOLAN_DIGLETT,
  [Pkm.ROWLET]: Pkm.ROWLET,
  [Pkm.DARTIX]: Pkm.ROWLET,
  [Pkm.DECIDUEYE]: Pkm.ROWLET,
  [Pkm.ZORUA]: Pkm.ZORUA,
  [Pkm.ZOROARK]: Pkm.ZORUA,
  [Pkm.FROAKIE]: Pkm.FROAKIE,
  [Pkm.FROGADIER]: Pkm.FROAKIE,
  [Pkm.GRENINJA]: Pkm.FROAKIE,
  [Pkm.TYROGUE]: Pkm.TYROGUE,
  [Pkm.HITMONLEE]: Pkm.TYROGUE,
  [Pkm.HITMONCHAN]: Pkm.TYROGUE,
  [Pkm.HITMONTOP]: Pkm.TYROGUE,
  [Pkm.MIMIKYU]: Pkm.MIMIKYU,
  [Pkm.MIMIKYU_BUSTED]: Pkm.MIMIKYU,
  [Pkm.GRIMER]: Pkm.GRIMER,
  [Pkm.MUK]: Pkm.GRIMER,
  [Pkm.SHARPEDO]: Pkm.CARVANHA,
  [Pkm.HISUI_ZORUA]: Pkm.HISUI_ZORUA,
  [Pkm.HISUI_ZOROARK]: Pkm.HISUI_ZORUA,
  [Pkm.ALOLAN_GRIMER]: Pkm.ALOLAN_GRIMER,
  [Pkm.ALOLAN_MUK]: Pkm.ALOLAN_GRIMER,
  [Pkm.PINECO]: Pkm.PINECO,
  [Pkm.FORRETRESS]: Pkm.PINECO,
  [Pkm.SEEL]: Pkm.SEEL,
  [Pkm.DEWGONG]: Pkm.SEEL,
  [Pkm.ALOLAN_GEODUDE]: Pkm.ALOLAN_GEODUDE,
  [Pkm.ALOLAN_GRAVELER]: Pkm.ALOLAN_GEODUDE,
  [Pkm.ALOLAN_GOLEM]: Pkm.ALOLAN_GEODUDE,
  [Pkm.EKANS]: Pkm.EKANS,
  [Pkm.ARBOK]: Pkm.EKANS,
  [Pkm.MIME_JR]: Pkm.MIME_JR,
  [Pkm.MR_MIME]: Pkm.MIME_JR,
  [Pkm.ORIGIN_GIRATINA]: Pkm.ORIGIN_GIRATINA,
  [Pkm.PIROUETTE_MELOETTA]: Pkm.MELOETTA,
  [Pkm.MELMETAL]: Pkm.MELMETAL,
  [Pkm.HOOPA]: Pkm.HOOPA,
  [Pkm.HOOPA_UNBOUND]: Pkm.HOOPA,
  [Pkm.SILVALLY]: Pkm.TYPE_NULL,
  [Pkm.ZERAORA]: Pkm.ZERAORA,
  [Pkm.XERNEAS]: Pkm.XERNEAS,
  [Pkm.YVELTAL]: Pkm.YVELTAL,
  [Pkm.TYPE_NULL]: Pkm.TYPE_NULL,
  [Pkm.MARSHADOW]: Pkm.MARSHADOW,
  [Pkm.HOOTHOOT]: Pkm.HOOTHOOT,
  [Pkm.NOCTOWL]: Pkm.HOOTHOOT,
  [Pkm.BONSLEY]: Pkm.BONSLEY,
  [Pkm.SUDOWOODO]: Pkm.BONSLEY,
  [Pkm.PHIONE]: Pkm.PHIONE,
  [Pkm.COMBEE]: Pkm.COMBEE,
  [Pkm.VESPIQUEEN]: Pkm.COMBEE,
  [Pkm.SHUCKLE]: Pkm.SHUCKLE,
  [Pkm.TEPIG]: Pkm.TEPIG,
  [Pkm.PIGNITE]: Pkm.TEPIG,
  [Pkm.EMBOAR]: Pkm.TEPIG,
  [Pkm.WYNAUT]: Pkm.WYNAUT,
  [Pkm.WOBBUFFET]: Pkm.WYNAUT,
  [Pkm.LUNATONE]: Pkm.LUNATONE,
  [Pkm.SOLROCK]: Pkm.SOLROCK,
  [Pkm.POLIWRATH]: Pkm.POLIWAG,
  [Pkm.SHAYMIN_SKY]: Pkm.SHAYMIN,
  [Pkm.WURMPLE]: Pkm.WURMPLE,
  [Pkm.SILCOON]: Pkm.WURMPLE,
  [Pkm.BEAUTIFLY]: Pkm.WURMPLE,
  [Pkm.CASCOON]: Pkm.WURMPLE,
  [Pkm.DUSTOX]: Pkm.WURMPLE,
  [Pkm.TINKATINK]: Pkm.TINKATINK,
  [Pkm.TINKATUFF]: Pkm.TINKATINK,
  [Pkm.TINKATON]: Pkm.TINKATINK,
  [Pkm.PARAS]: Pkm.PARAS,
  [Pkm.PARASECT]: Pkm.PARAS,
  [Pkm.MILTANK]: Pkm.MILTANK,
  [Pkm.MANKEY]: Pkm.MANKEY,
  [Pkm.PRIMEAPE]: Pkm.MANKEY,
  [Pkm.ANNIHILAPE]: Pkm.MANKEY,
  [Pkm.SUNKERN]: Pkm.SUNKERN,
  [Pkm.SUNFLORA]: Pkm.SUNKERN,
  [Pkm.MARACTUS]: Pkm.MARACTUS,
  [Pkm.PLUSLE]: Pkm.PLUSLE,
  [Pkm.MINUN]: Pkm.MINUN,
  [Pkm.PINSIR]: Pkm.PINSIR,
  [Pkm.NATU]: Pkm.NATU,
  [Pkm.XATU]: Pkm.NATU,
  [Pkm.GLIGAR]: Pkm.GLIGAR,
  [Pkm.GLISCOR]: Pkm.GLIGAR,
  [Pkm.SHELLDER]: Pkm.SHELLDER,
  [Pkm.CLOYSTER]: Pkm.SHELLDER,
  [Pkm.SENTRET]: Pkm.SENTRET,
  [Pkm.FURRET]: Pkm.SENTRET,
  [Pkm.SPECTRIER]: Pkm.SPECTRIER,
  [Pkm.TORKOAL]: Pkm.TORKOAL,
  [Pkm.DELIBIRD]: Pkm.DELIBIRD,
  [Pkm.IRON_BUNDLE]: Pkm.IRON_BUNDLE,
  [Pkm.KARTANA]: Pkm.KARTANA,
  [Pkm.CHINGLING]: Pkm.CHINGLING,
  [Pkm.CHIMECHO]: Pkm.CHINGLING,
  [Pkm.ALOLAN_RAICHU]: Pkm.PICHU,
  [Pkm.DHELMISE]: Pkm.DHELMISE,
  [Pkm.KOFFING]: Pkm.KOFFING,
  [Pkm.WEEZING]: Pkm.KOFFING,
  [Pkm.STARYU]: Pkm.STARYU,
  [Pkm.STARMIE]: Pkm.STARYU,
  [Pkm.NOSEPASS]: Pkm.NOSEPASS,
  [Pkm.PROBOPASS]: Pkm.NOSEPASS,
  [Pkm.WOOBAT]: Pkm.WOOBAT,
  [Pkm.SWOOBAT]: Pkm.WOOBAT,
  [Pkm.CLAUNCHER]: Pkm.CLAUNCHER,
  [Pkm.CLAWITZER]: Pkm.CLAUNCHER,
  [Pkm.YANMA]: Pkm.YANMA,
  [Pkm.YANMEGA]: Pkm.YANMA,
  [Pkm.HELIOPTILE]: Pkm.HELIOPTILE,
  [Pkm.HELIOLISK]: Pkm.HELIOPTILE,
  [Pkm.BIDOOF]: Pkm.BIDOOF,
  [Pkm.BIBAREL]: Pkm.BIDOOF,
  [Pkm.SPINDA]: Pkm.SPINDA,
  [Pkm.BALTOY]: Pkm.BALTOY,
  [Pkm.CLAYDOL]: Pkm.BALTOY,
  [Pkm.HERACROSS]: Pkm.HERACROSS,
  [Pkm.PURRLOIN]: Pkm.PURRLOIN,
  [Pkm.LIEPARD]: Pkm.PURRLOIN,
  [Pkm.BARBOACH]: Pkm.BARBOACH,
  [Pkm.WHISCASH]: Pkm.BARBOACH,
  [Pkm.SCRAGGY]: Pkm.SCRAGGY,
  [Pkm.SCRAFTY]: Pkm.SCRAGGY,
  [Pkm.FINNEON]: Pkm.FINNEON,
  [Pkm.LUMINEON]: Pkm.FINNEON,
  [Pkm.STUNKY]: Pkm.STUNKY,
  [Pkm.SKUNTANK]: Pkm.STUNKY,
  [Pkm.ILLUMISE]: Pkm.ILLUMISE,
  [Pkm.VOLBEAT]: Pkm.VOLBEAT,
  [Pkm.NECROZMA]: Pkm.NECROZMA,
  [Pkm.ULTRA_NECROZMA]: Pkm.NECROZMA,
  [Pkm.CHERRUBI]: Pkm.CHERRUBI,
  [Pkm.CHERRIM]: Pkm.CHERRUBI,
  [Pkm.CHERRIM_SUNLIGHT]: Pkm.CHERRUBI,
  [Pkm.MISDREAVUS]: Pkm.MISDREAVUS,
  [Pkm.MISMAGIUS]: Pkm.MISDREAVUS,
  [Pkm.DODUO]: Pkm.DODUO,
  [Pkm.DODRIO]: Pkm.DODUO,
  [Pkm.XURKITREE]: Pkm.XURKITREE,
  [Pkm.TANDEMAUS]: Pkm.TANDEMAUS,
  [Pkm.MAUSHOLD_THREE]: Pkm.TANDEMAUS,
  [Pkm.MAUSHOLD_FOUR]: Pkm.TANDEMAUS,
  [Pkm.KRICKETOT]: Pkm.KRICKETOT,
  [Pkm.KRICKETUNE]: Pkm.KRICKETOT,
  [Pkm.HIPPOPOTAS]: Pkm.HIPPOPOTAS,
  [Pkm.HIPPODOWN]: Pkm.HIPPOPOTAS,
  [Pkm.WINGULL]: Pkm.WINGULL,
  [Pkm.PELIPPER]: Pkm.WINGULL,
  [Pkm.NIHILEGO]: Pkm.NIHILEGO,
  [Pkm.SOBBLE]: Pkm.SOBBLE,
  [Pkm.DRIZZILE]: Pkm.SOBBLE,
  [Pkm.INTELEON]: Pkm.SOBBLE,
  [Pkm.TROPIUS]: Pkm.TROPIUS,
  [Pkm.EXEGGCUTE]: Pkm.EXEGGCUTE,
  [Pkm.EXEGGUTOR]: Pkm.EXEGGCUTE,
  [Pkm.ALOLAN_EXEGGUTOR]: Pkm.EXEGGCUTE,
  [Pkm.COMFEY]: Pkm.COMFEY,
  [Pkm.CARNIVINE]: Pkm.CARNIVINE,
  [Pkm.HISUIAN_QWILFISH]: Pkm.HISUIAN_QWILFISH,
  [Pkm.OVERQWIL]: Pkm.HISUIAN_QWILFISH,
  [Pkm.HISUIAN_TYPHLOSION]: Pkm.CYNDAQUIL,
  [Pkm.LILLIPUP]: Pkm.LILLIPUP,
  [Pkm.HERDIER]: Pkm.LILLIPUP,
  [Pkm.STOUTLAND]: Pkm.LILLIPUP,
  [Pkm.ZIGZAGOON]: Pkm.ZIGZAGOON,
  [Pkm.LINOONE]: Pkm.ZIGZAGOON,
  [Pkm.PHEROMOSA]: Pkm.PHEROMOSA,
  [Pkm.SABLEYE]: Pkm.SABLEYE,
  [Pkm.MEGA_SABLEYE]: Pkm.SABLEYE,
  [Pkm.DRACOVISH]: Pkm.DRACOVISH,
  [Pkm.CORSOLA]: Pkm.CORSOLA,
  [Pkm.GALAR_CORSOLA]: Pkm.CORSOLA,
  [Pkm.CURSOLA]: Pkm.CORSOLA,
  [Pkm.GIMMIGHOUL]: Pkm.GIMMIGHOUL,
  [Pkm.GHOLDENGO]: Pkm.GIMMIGHOUL,
  [Pkm.PHANTUMP]: Pkm.PHANTUMP,
  [Pkm.TREVENANT]: Pkm.PHANTUMP,
  [Pkm.SMEARGLE]: Pkm.SMEARGLE,
  [Pkm.TOXEL]: Pkm.TOXEL,
  [Pkm.TOXTRICITY]: Pkm.TOXEL,
  [Pkm.BRUXISH]: Pkm.BRUXISH,
  [Pkm.SUBSTITUTE]: Pkm.SUBSTITUTE,
  [Pkm.CYCLIZAR]: Pkm.CYCLIZAR,
  [Pkm.PAWNIARD]: Pkm.PAWNIARD,
  [Pkm.BISHARP]: Pkm.PAWNIARD,
  [Pkm.KINGAMBIT]: Pkm.PAWNIARD,
  [Pkm.MINIOR]: Pkm.MINIOR,
  [Pkm.MINIOR_KERNEL_RED]: Pkm.MINIOR,
  [Pkm.MINIOR_KERNEL_BLUE]: Pkm.MINIOR,
  [Pkm.MINIOR_KERNEL_ORANGE]: Pkm.MINIOR,
  [Pkm.MINIOR_KERNEL_GREEN]: Pkm.MINIOR,
  [Pkm.FEEBAS]: Pkm.FEEBAS,
  [Pkm.MILOTIC]: Pkm.FEEBAS,
  [Pkm.MORPEKO]: Pkm.MORPEKO,
  [Pkm.MORPEKO_HANGRY]: Pkm.MORPEKO,
  [Pkm.KANGASKHAN]: Pkm.KANGASKHAN,
  [Pkm.TEDDIURSA]: Pkm.TEDDIURSA,
  [Pkm.URSARING]: Pkm.TEDDIURSA,
  [Pkm.URSALUNA]: Pkm.TEDDIURSA,
  [Pkm.URSALUNA_BLOODMOON]: Pkm.TEDDIURSA,
  [Pkm.AIPOM]: Pkm.AIPOM,
  [Pkm.AMBIPOM]: Pkm.AIPOM,
  [Pkm.DEERLING]: Pkm.DEERLING,
  [Pkm.SAWSBUCK]: Pkm.DEERLING,
  [Pkm.LICKITUNG]: Pkm.LICKITUNG,
  [Pkm.LICKILICKY]: Pkm.LICKITUNG,
  [Pkm.PATRAT]: Pkm.PATRAT,
  [Pkm.WATCHOG]: Pkm.PATRAT,
  [Pkm.SPINARAK]: Pkm.SPINARAK,
  [Pkm.ARIADOS]: Pkm.SPINARAK,
  [Pkm.DEWPIDER]: Pkm.DEWPIDER,
  [Pkm.ARAQUANID]: Pkm.DEWPIDER,
  [Pkm.ROCKRUFF]: Pkm.ROCKRUFF,
  [Pkm.LYCANROC_DAY]: Pkm.ROCKRUFF,
  [Pkm.LYCANROC_DUSK]: Pkm.ROCKRUFF,
  [Pkm.LYCANROC_NIGHT]: Pkm.ROCKRUFF,
  [Pkm.DRUDDIGON]: Pkm.DRUDDIGON,
  [Pkm.COSMOG]: Pkm.COSMOG,
  [Pkm.COSMOEM]: Pkm.COSMOG,
  [Pkm.SOLGALEO]: Pkm.COSMOG,
  [Pkm.LUNALA]: Pkm.COSMOG,
  [Pkm.MAGEARNA]: Pkm.MAGEARNA,
  [Pkm.IMPIDIMP]: Pkm.IMPIDIMP,
  [Pkm.MORGREM]: Pkm.IMPIDIMP,
  [Pkm.GRIMMSNARL]: Pkm.IMPIDIMP,
  [Pkm.DEOXYS]: Pkm.DEOXYS,
  [Pkm.DEOXYS_DEFENSE]: Pkm.DEOXYS,
  [Pkm.DEOXYS_ATTACK]: Pkm.DEOXYS,
  [Pkm.DEOXYS_SPEED]: Pkm.DEOXYS,
  [Pkm.CRABRAWLER]: Pkm.CRABRAWLER,
  [Pkm.CRABOMINABLE]: Pkm.CRABRAWLER,
  [Pkm.CUTIEFLY]: Pkm.CUTIEFLY,
  [Pkm.RIBOMBEE]: Pkm.CUTIEFLY,
  [Pkm.ZANGOOSE]: Pkm.ZANGOOSE,
  [Pkm.NICKIT]: Pkm.NICKIT,
  [Pkm.THIEVUL]: Pkm.NICKIT,
  [Pkm.DROWZEE]: Pkm.DROWZEE,
  [Pkm.HYPNO]: Pkm.DROWZEE,
  [Pkm.WATTREL]: Pkm.WATTREL,
  [Pkm.KILOWATTREL]: Pkm.WATTREL,
  [Pkm.STANTLER]: Pkm.STANTLER,
  [Pkm.BURMY_PLANT]: Pkm.BURMY_PLANT,
  [Pkm.BURMY_SANDY]: Pkm.BURMY_SANDY,
  [Pkm.BURMY_TRASH]: Pkm.BURMY_TRASH,
  [Pkm.WORMADAM_PLANT]: Pkm.BURMY_PLANT,
  [Pkm.WORMADAM_SANDY]: Pkm.BURMY_SANDY,
  [Pkm.WORMADAM_TRASH]: Pkm.BURMY_TRASH,
  [Pkm.MOTHIM]: Pkm.BURMY_PLANT, // NOTE: converging evolution
  [Pkm.PALDEA_WOOPER]: Pkm.PALDEA_WOOPER,
  [Pkm.CLODSIRE]: Pkm.PALDEA_WOOPER,
  [Pkm.FUECOCO]: Pkm.FUECOCO,
  [Pkm.CROCALOR]: Pkm.FUECOCO,
  [Pkm.SKELEDIRGE]: Pkm.FUECOCO,
  [Pkm.TANGELA]: Pkm.TANGELA,
  [Pkm.TANGROWTH]: Pkm.TANGELA,
  [Pkm.PSYDUCK]: Pkm.PSYDUCK,
  [Pkm.GOLDUCK]: Pkm.PSYDUCK,
  [Pkm.PHANPY]: Pkm.PHANPY,
  [Pkm.DONPHAN]: Pkm.PHANPY,
  [Pkm.SPOINK]: Pkm.SPOINK,
  [Pkm.GRUMPIG]: Pkm.SPOINK,
  [Pkm.SINISTEA]: Pkm.SINISTEA,
  [Pkm.POLTEAGEIST]: Pkm.SINISTEA,
  [Pkm.FERROSEED]: Pkm.FERROSEED,
  [Pkm.FERROTHORN]: Pkm.FERROSEED,
  [Pkm.GOLETT]: Pkm.GOLETT,
  [Pkm.GOLURK]: Pkm.GOLETT,
  [Pkm.TRUBBISH]: Pkm.TRUBBISH,
  [Pkm.GARBODOR]: Pkm.TRUBBISH,
  [Pkm.GRUBBIN]: Pkm.GRUBBIN,
  [Pkm.CHARJABUG]: Pkm.GRUBBIN,
  [Pkm.VIKAVOLT]: Pkm.GRUBBIN,
  [Pkm.SHELLOS_WEST_SEA]: Pkm.SHELLOS_WEST_SEA,
  [Pkm.GASTRODON_WEST_SEA]: Pkm.SHELLOS_WEST_SEA,
  [Pkm.SHELLOS_EAST_SEA]: Pkm.SHELLOS_EAST_SEA,
  [Pkm.GASTRODON_EAST_SEA]: Pkm.SHELLOS_EAST_SEA,
  [Pkm.MUNNA]: Pkm.MUNNA,
  [Pkm.MUSHARNA]: Pkm.MUNNA,
  [Pkm.RUFFLET]: Pkm.RUFFLET,
  [Pkm.BRAVIARY]: Pkm.RUFFLET,
  [Pkm.HEATMOR]: Pkm.HEATMOR,
  [Pkm.KLEFKI]: Pkm.KLEFKI,
  [Pkm.HAWLUCHA]: Pkm.HAWLUCHA,
  [Pkm.MIENFOO]: Pkm.MIENFOO,
  [Pkm.MIENSHAO]: Pkm.MIENFOO,
  [Pkm.STONJOURNER]: Pkm.STONJOURNER,
  [Pkm.HISUI_SNEASEL]: Pkm.HISUI_SNEASEL,
  [Pkm.SNEASLER]: Pkm.HISUI_SNEASEL,
  [Pkm.PYUKUMUKU]: Pkm.PYUKUMUKU,
  [Pkm.POIPOLE]: Pkm.POIPOLE,
  [Pkm.NAGANADEL]: Pkm.POIPOLE,
  [Pkm.CRAMORANT]: Pkm.CRAMORANT,
  [Pkm.ARROKUDA]: Pkm.ARROKUDA,
  [Pkm.WISHIWASHI]: Pkm.WISHIWASHI,
  [Pkm.WISHIWASHI_SCHOOL]: Pkm.WISHIWASHI,
  [Pkm.PAWMI]: Pkm.PAWMI,
  [Pkm.PAWMO]: Pkm.PAWMI,
  [Pkm.PAWMOT]: Pkm.PAWMI,
  [Pkm.GOLDEEN]: Pkm.GOLDEEN,
  [Pkm.SEAKING]: Pkm.GOLDEEN,
  [Pkm.LUVDISC]: Pkm.LUVDISC,
  [Pkm.AUDINO]: Pkm.AUDINO,
  [Pkm.PETILIL]: Pkm.PETILIL,
  [Pkm.LILIGANT]: Pkm.PETILIL,
  [Pkm.MANTYKE]: Pkm.MANTYKE,
  [Pkm.MANTINE]: Pkm.MANTYKE,
  [Pkm.REMORAID]: Pkm.REMORAID,
  [Pkm.OCTILLERY]: Pkm.REMORAID,
  [Pkm.SIGILYPH]: Pkm.SIGILYPH,
  [Pkm.FRIGIBAX]: Pkm.FRIGIBAX,
  [Pkm.ARCTIBAX]: Pkm.FRIGIBAX,
  [Pkm.BAXCALIBUR]: Pkm.FRIGIBAX,
  [Pkm.BINACLE]: Pkm.BINACLE,
  [Pkm.BARBARACLE]: Pkm.BINACLE,
  [Pkm.SKARMORY]: Pkm.SKARMORY,
  [Pkm.DURANT]: Pkm.DURANT,
  [Pkm.OGERPON_TEAL]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_TEAL_MASK]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_WELLSPRING]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_WELLSPRING_MASK]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_HEARTHFLAME]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_HEARTHFLAME_MASK]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_CORNERSTONE]: Pkm.OGERPON_TEAL,
  [Pkm.OGERPON_CORNERSTONE_MASK]: Pkm.OGERPON_TEAL,
  [Pkm.IRON_HANDS]: Pkm.IRON_HANDS,
  [Pkm.ROOKIDEE]: Pkm.ROOKIDEE,
  [Pkm.CORVISQUIRE]: Pkm.ROOKIDEE,
  [Pkm.CORVIKNIGHT]: Pkm.ROOKIDEE,
  [Pkm.MURKROW]: Pkm.MURKROW,
  [Pkm.HONCHKROW]: Pkm.MURKROW,
  [Pkm.SANDYGAST]: Pkm.SANDYGAST,
  [Pkm.PALOSSAND]: Pkm.SANDYGAST,
  [Pkm.TURTONATOR]: Pkm.TURTONATOR,
  [Pkm.SKORUPI]: Pkm.SKORUPI,
  [Pkm.DRAPION]: Pkm.SKORUPI,
  [Pkm.DARUMAKA]: Pkm.DARUMAKA,
  [Pkm.DARMANITAN]: Pkm.DARUMAKA,
  [Pkm.DARMANITAN_ZEN]: Pkm.DARUMAKA,
  [Pkm.KRABBY]: Pkm.KRABBY,
  [Pkm.KINGLER]: Pkm.KRABBY,
  [Pkm.SIZZLIPEDE]: Pkm.SIZZLIPEDE,
  [Pkm.CENTISKORCH]: Pkm.SIZZLIPEDE,
  [Pkm.ZYGARDE_10]: Pkm.ZYGARDE_10,
  [Pkm.ZYGARDE_50]: Pkm.ZYGARDE_50,
  [Pkm.ZYGARDE_100]: Pkm.ZYGARDE_100,
  [Pkm.STUFFUL]: Pkm.STUFFUL,
  [Pkm.BEWEAR]: Pkm.STUFFUL,
  [Pkm.GLIMMET]: Pkm.GLIMMET,
  [Pkm.GLIMMORA]: Pkm.GLIMMET,
  [Pkm.FLETCHLING]: Pkm.FLETCHLING,
  [Pkm.FLETCHINDER]: Pkm.FLETCHLING,
  [Pkm.TALONFLAME]: Pkm.FLETCHLING,
  [Pkm.VULLABY]: Pkm.VULLABY,
  [Pkm.MANDIBUZZ]: Pkm.VULLABY,
  [Pkm.INKAY]: Pkm.INKAY,
  [Pkm.MALAMAR]: Pkm.INKAY,
  [Pkm.TIMBURR]: Pkm.TIMBURR,
  [Pkm.GURDURR]: Pkm.TIMBURR,
  [Pkm.CONKELDURR]: Pkm.TIMBURR,
  [Pkm.PILLAR_WOOD]: Pkm.PILLAR_WOOD,
  [Pkm.PILLAR_IRON]: Pkm.PILLAR_IRON,
  [Pkm.PILLAR_CONCRETE]: Pkm.PILLAR_CONCRETE,
  [Pkm.ELGYEM]: Pkm.ELGYEM,
  [Pkm.BEHEEYEM]: Pkm.ELGYEM,
  [Pkm.LITTEN]: Pkm.LITTEN,
  [Pkm.TORRACAT]: Pkm.LITTEN,
  [Pkm.INCINEROAR]: Pkm.LITTEN
}

export const PkmRegionalVariants: { [key in Pkm]?: readonly Pkm[] } = {
  [Pkm.RATTATA]: [Pkm.ALOLAN_RATTATA],
  [Pkm.GROWLITHE]: [Pkm.HISUI_GROWLITHE],
  [Pkm.VULPIX]: [Pkm.ALOLAN_VULPIX],
  [Pkm.GEODUDE]: [Pkm.ALOLAN_GEODUDE],
  [Pkm.DIGLETT]: [Pkm.ALOLAN_DIGLETT],
  [Pkm.ZORUA]: [Pkm.HISUI_ZORUA],
  [Pkm.GRIMER]: [Pkm.ALOLAN_GRIMER],
  [Pkm.NIDORANF]: [Pkm.NIDORANM],
  [Pkm.SNEASEL]: [Pkm.HISUI_SNEASEL],
  [Pkm.MEOWTH]: [Pkm.ALOLAN_MEOWTH],
  [Pkm.PONYTA]: [Pkm.GALARIAN_PONYTA],
  [Pkm.VOLTORB]: [Pkm.HISUI_VOLTORB]
} as const

export enum PkmDuo {
  LATIOS_LATIAS = "LATIOS_LATIAS",
  PLUSLE_MINUN = "PLUSLE_MINUN",
  ILLUMISE_VOLBEAT = "ILLUMISE_VOLBEAT",
  NINJASK_SHEDINJA = "NINJASK_SHEDINJA"
}

export type PkmProposition = Pkm | PkmDuo

export const PkmDuos = {
  [PkmDuo.LATIOS_LATIAS]: [Pkm.LATIOS, Pkm.LATIAS],
  [PkmDuo.PLUSLE_MINUN]: [Pkm.PLUSLE, Pkm.MINUN],
  [PkmDuo.ILLUMISE_VOLBEAT]: [Pkm.ILLUMISE, Pkm.VOLBEAT],
  [PkmDuo.NINJASK_SHEDINJA]: [Pkm.NINJASK, Pkm.SHEDINJA]
}

export const Unowns: Pkm[] = [
  Pkm.UNOWN_A,
  Pkm.UNOWN_B,
  Pkm.UNOWN_C,
  Pkm.UNOWN_D,
  Pkm.UNOWN_E,
  Pkm.UNOWN_F,
  Pkm.UNOWN_G,
  Pkm.UNOWN_H,
  Pkm.UNOWN_I,
  Pkm.UNOWN_J,
  Pkm.UNOWN_K,
  Pkm.UNOWN_L,
  Pkm.UNOWN_M,
  Pkm.UNOWN_N,
  Pkm.UNOWN_O,
  Pkm.UNOWN_P,
  Pkm.UNOWN_Q,
  Pkm.UNOWN_R,
  Pkm.UNOWN_S,
  Pkm.UNOWN_T,
  Pkm.UNOWN_U,
  Pkm.UNOWN_V,
  Pkm.UNOWN_W,
  Pkm.UNOWN_X,
  Pkm.UNOWN_Y,
  Pkm.UNOWN_Z,
  Pkm.UNOWN_QUESTION,
  Pkm.UNOWN_EXCLAMATION
]

export const UnownsStage1 = [
  Pkm.UNOWN_A,
  Pkm.UNOWN_C,
  Pkm.UNOWN_D,
  Pkm.UNOWN_E,
  Pkm.UNOWN_F,
  Pkm.UNOWN_G,
  Pkm.UNOWN_I,
  Pkm.UNOWN_O,
  Pkm.UNOWN_V,
  Pkm.UNOWN_W
]

export const UnownsStage2 = [
  Pkm.UNOWN_A,
  Pkm.UNOWN_B,
  Pkm.UNOWN_C,
  Pkm.UNOWN_D,
  Pkm.UNOWN_G,
  Pkm.UNOWN_H,
  Pkm.UNOWN_I,
  Pkm.UNOWN_J,
  Pkm.UNOWN_K,
  Pkm.UNOWN_L,
  Pkm.UNOWN_M,
  Pkm.UNOWN_N,
  Pkm.UNOWN_O,
  Pkm.UNOWN_P,
  Pkm.UNOWN_Q,
  Pkm.UNOWN_R,
  Pkm.UNOWN_S,
  Pkm.UNOWN_T,
  Pkm.UNOWN_U,
  Pkm.UNOWN_V,
  Pkm.UNOWN_W,
  Pkm.UNOWN_X,
  Pkm.UNOWN_Y,
  Pkm.UNOWN_Z,
  Pkm.UNOWN_QUESTION
]

export const UnownsStage3 = [
  Pkm.UNOWN_B,
  Pkm.UNOWN_G,
  Pkm.UNOWN_H,
  Pkm.UNOWN_J,
  Pkm.UNOWN_K,
  Pkm.UNOWN_L,
  Pkm.UNOWN_M,
  Pkm.UNOWN_N,
  Pkm.UNOWN_O,
  Pkm.UNOWN_P,
  Pkm.UNOWN_R,
  Pkm.UNOWN_S,
  Pkm.UNOWN_T,
  Pkm.UNOWN_U,
  Pkm.UNOWN_V,
  Pkm.UNOWN_W,
  Pkm.UNOWN_X,
  Pkm.UNOWN_Y,
  Pkm.UNOWN_Z,
  Pkm.UNOWN_QUESTION,
  Pkm.UNOWN_EXCLAMATION
]

export function getUnownsPoolPerStage(stageLevel: number) {
  if (stageLevel < 10) return UnownsStage1
  else if (stageLevel < 20) return UnownsStage2
  else return UnownsStage3
}

export const AnimationConfig: {
  [key in Pkm]: {
    attack: AnimationType
    ability: AnimationType
    emote: AnimationType
    shinyUnavailable?: boolean
    noShadow?: boolean
  }
} = {
  [Pkm.DEFAULT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Attack
  },
  [Pkm.EGG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.LostBalance,
    emote: AnimationType.LostBalance
  },
  [Pkm.DITTO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Hop
  },
  [Pkm.BULBASAUR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.IVYSAUR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.VENUSAUR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.CHARMANDER]: {
    attack: AnimationType.Kick,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.CHARMELEON]: {
    attack: AnimationType.Strike,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.CHARIZARD]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SQUIRTLE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.WARTORTLE]: {
    attack: AnimationType.Ricochet,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.BLASTOISE]: {
    attack: AnimationType.Ricochet,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GEODUDE]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GRAVELER]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOLEM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.AZURILL]: {
    attack: AnimationType.Swing,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.MARILL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.AZUMARILL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.ZUBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.GOLBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.CROBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MAREEP]: {
    attack: AnimationType.Emit,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.FLAFFY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Pose
  },
  [Pkm.AMPHAROS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CLEFFA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Dance
  },
  [Pkm.CLEFAIRY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Dance
  },
  [Pkm.CLEFABLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Dance
  },
  [Pkm.IGGLYBUFF]: {
    attack: AnimationType.Special1,
    ability: AnimationType.Special6,
    emote: AnimationType.Shoot
  },
  [Pkm.WIGGLYTUFF]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Sleep,
    emote: AnimationType.Shoot
  },
  [Pkm.JIGGLYPUFF]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CATERPIE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.METAPOD]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.BUTTERFREE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.WEEDLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.KAKUNA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BEEDRILL]: {
    attack: AnimationType.Jab,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.PIDGEY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.PIDGEOTTO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.PIDGEOT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.HOPPIP]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.SKIPLOOM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.JUMPLUFF]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SEEDOT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.NUZLEAF]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SHIFTRY]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.STARLY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.STARAVIA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.STARAPTOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CHIKORITA]: {
    attack: AnimationType.Slam,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.BAYLEEF]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGANIUM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shake,
    emote: AnimationType.Shoot
  },
  [Pkm.CYNDAQUIL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.QUILAVA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.TYPHLOSION]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.TOTODILE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.HitGround,
    emote: AnimationType.Shoot
  },
  [Pkm.CROCONAW]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.FERALIGATR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TREECKO]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.GROVYLE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Special17,
    emote: AnimationType.Shoot
  },
  [Pkm.SCEPTILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.TORCHIC]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.COMBUSKEN]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.BLAZIKEN]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Kick,
    emote: AnimationType.Shoot
  },
  [Pkm.MUDKIP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.MARSHTOMP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.SWAMPERT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.TURTWIG]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.GROTLE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.TORTERRA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.CHIMCHAR]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.MONFERNO]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.INFERNAPE]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.PIPLUP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.PRINPLUP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.EMPOLEON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDORANF]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDORINA]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDOQUEEN]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDORANM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDORINO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.NIDOKING]: {
    attack: AnimationType.Strike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.PICHU]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Shock,
    emote: AnimationType.Pose
  },
  [Pkm.PIKACHU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Pose
  },
  [Pkm.RAICHU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.MACHOP]: {
    attack: AnimationType.Kick,
    ability: AnimationType.Strike,
    emote: AnimationType.Yawn
  },
  [Pkm.MACHOKE]: {
    attack: AnimationType.Kick,
    ability: AnimationType.Punch,
    emote: AnimationType.Charge
  },
  [Pkm.MACHAMP]: {
    attack: AnimationType.Kick,
    ability: AnimationType.Punch,
    emote: AnimationType.Charge
  },
  [Pkm.HORSEA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SEADRA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KINGDRA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.TRAPINCH]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.VIBRAVA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.FLYGON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.SPHEAL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SEALEO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.WALREIN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ARON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.LAIRON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.AGGRON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DigOut,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGNEMITE]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGNETON]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGNEZONE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.RHYHORN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Stomp,
    emote: AnimationType.Shoot
  },
  [Pkm.RHYDON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Stomp,
    emote: AnimationType.Shoot
  },
  [Pkm.RHYPERIOR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Rumble,
    emote: AnimationType.Shoot
  },
  [Pkm.TOGEPI]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Dance,
    emote: AnimationType.Dance
  },
  [Pkm.TOGETIC]: {
    attack: AnimationType.Hover,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.TOGEKISS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.DUSKULL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.DUSCLOPS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.DUSKNOIR]: {
    attack: AnimationType.Emit,
    ability: AnimationType.Special3,
    emote: AnimationType.Shoot
  },
  [Pkm.LOTAD]: {
    attack: AnimationType.Shake,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.LOMBRE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.LUDICOLO]: {
    attack: AnimationType.Special0,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.SHINX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.LUXIO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.LUXRAY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shake
  },
  [Pkm.POLIWAG]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Appeal
  },
  [Pkm.POLIWHIRL]: {
    attack: AnimationType.RearUp,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.POLITOED]: {
    attack: AnimationType.RearUp,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ABRA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KADABRA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.SpAttack
  },
  [Pkm.ALAKAZAM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Special1
  },
  [Pkm.GASTLY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Lick,
    emote: AnimationType.Shoot
  },
  [Pkm.HAUNTER]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Lick
  },
  [Pkm.GENGAR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Special2,
    emote: AnimationType.Pose
  },
  [Pkm.DRATINI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.DRAGONAIR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DRAGONITE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.LARVITAR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.PUPITAR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TYRANITAR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SLAKOTH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Appeal,
    emote: AnimationType.Shoot
  },
  [Pkm.VIGOROTH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Dance,
    emote: AnimationType.Shoot
  },
  [Pkm.SLAKING]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Dance,
    emote: AnimationType.Shoot
  },
  [Pkm.RALTS]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Pull,
    emote: AnimationType.Shoot
  },
  [Pkm.KIRLIA]: {
    attack: AnimationType.Twirl,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.GARDEVOIR]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Special2,
    emote: AnimationType.Shoot
  },
  [Pkm.BAGON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Bite,
    emote: AnimationType.Shoot
  },
  [Pkm.SHELGON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SALAMENCE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BELDUM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.METANG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Shoot
  },
  [Pkm.METAGROSS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Ricochet,
    emote: AnimationType.Shoot
  },
  [Pkm.GIBLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GABITE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GARCHOMP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.ELEKID]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.ELECTABUZZ]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ELECTIVIRE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGBY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGMAR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGMORTAR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MUNCHLAX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SNORLAX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Stomp,
    emote: AnimationType.Shoot
  },
  [Pkm.GROWLITHE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Rumble
  },
  [Pkm.ARCANINE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Rumble
  },
  [Pkm.HISUI_GROWLITHE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HISUI_ARCANINE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.QuickStrike,
    emote: AnimationType.Shoot
  },
  [Pkm.ONIX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.STEELIX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_STEELIX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.SCYTHER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Slice,
    emote: AnimationType.Shoot
  },
  [Pkm.SCIZOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Shoot
  },
  [Pkm.KLEAVOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.RIOLU]: {
    attack: AnimationType.Strike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Pose
  },
  [Pkm.LUCARIO]: {
    attack: AnimationType.Strike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Pose
  },
  [Pkm.MAGIKARP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.RATTATA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_RATTATA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.TailWhip,
    emote: AnimationType.Shoot
  },
  [Pkm.RATICATE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_RATICATE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.TailWhip,
    emote: AnimationType.Shoot
  },
  [Pkm.SPEAROW]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.FEAROW]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.GYARADOS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.LUGIA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.SHADOW_LUGIA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Dance,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.GIRATINA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.ZAPDOS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Emit
  },
  [Pkm.MOLTRES]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Pose,
    emote: AnimationType.Pose
  },
  [Pkm.ARTICUNO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Pose
  },
  [Pkm.GALARIAN_ZAPDOS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.GALARIAN_MOLTRES]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge
  },
  [Pkm.GALARIAN_ARTICUNO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.RearUp,
    shinyUnavailable: true
  },
  [Pkm.DIALGA]: {
    attack: AnimationType.RearUp,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.PALKIA]: {
    attack: AnimationType.Special0,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.SUICUNE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.SpAttack
  },
  [Pkm.RAIKOU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.QuickStrike,
    emote: AnimationType.Roar
  },
  [Pkm.ENTEI]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.SpAttack
  },
  [Pkm.REGICE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.REGIROCK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.REGISTEEL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.KYOGRE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot
  },
  [Pkm.GROUDON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.RAYQUAZA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.REGIGIGAS]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.EEVEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.VAPOREON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.JOLTEON]: {
    attack: AnimationType.Shock,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.FLAREON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.ESPEON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.UMBREON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.LEAFEON]: {
    attack: AnimationType.QuickStrike,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.SYLVEON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.MEDITITE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.MEDICHAM]: {
    attack: AnimationType.Charge,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.NUMEL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.CAMERUPT]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_CAMERUPT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.DARKRAI]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Sink,
    emote: AnimationType.Shoot
  },
  [Pkm.LITWICK]: {
    attack: AnimationType.Sink,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.LAMPENT]: {
    attack: AnimationType.Emit,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.CHANDELURE]: {
    attack: AnimationType.Emit,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.SLOWPOKE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SLOWBRO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shake
  },
  [Pkm.SLOWKING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.RearUp
  },
  [Pkm.BELLSPROUT]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.WEEPINBELL]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.VICTREEBEL]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.SWINUB]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shake,
    emote: AnimationType.Shoot
  },
  [Pkm.PILOSWINE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.MAMOSWINE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.SNORUNT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.GLALIE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Bite,
    emote: AnimationType.Shoot
  },
  [Pkm.FROSLASS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SNOVER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.ABOMASNOW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_ABOMASNOW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.VANILLITE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.VANILLISH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Rotate
  },
  [Pkm.VANILLUXE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Rotate
  },
  [Pkm.GLACEON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.LARVESTA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.VOLCARONA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.LANDORUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.THUNDURUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TORNADUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ENAMORUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.KELDEO]: {
    attack: AnimationType.Swing,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.TERRAKION]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.VIRIZION]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.COBALION]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MANAPHY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.ROTOM]: {
    attack: AnimationType.Emit,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.ROTOM_DRONE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.SPIRITOMB]: {
    attack: AnimationType.Withdraw,
    ability: AnimationType.Special1,
    emote: AnimationType.Shoot
  },
  [Pkm.ABSOL]: {
    attack: AnimationType.QuickStrike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.LAPRAS]: {
    attack: AnimationType.Swing,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.LATIAS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.LATIOS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.MESPRIT]: {
    attack: AnimationType.Hover,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.AZELF]: {
    attack: AnimationType.Hover,
    ability: AnimationType.Special1,
    emote: AnimationType.Shoot
  },
  [Pkm.UXIE]: {
    attack: AnimationType.Hover,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MEWTWO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Punch,
    emote: AnimationType.Pose
  },
  [Pkm.KYUREM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.RESHIRAM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.ZEKROM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CELEBI]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Special0
  },
  [Pkm.VICTINI]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.JIRACHI]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.ARCEUS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Pose,
    emote: AnimationType.Shoot
  },
  [Pkm.SHAYMIN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.CRESSELIA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.HEATRAN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.HO_OH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Jab,
    emote: AnimationType.Shoot
  },
  [Pkm.AERODACTYL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.PRIMAL_KYOGRE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.PRIMAL_GROUDON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MEOWTH]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Pose,
    emote: AnimationType.DeepBreath
  },
  [Pkm.PERSIAN]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_MEOWTH]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Pose,
    emote: AnimationType.DeepBreath
  },
  [Pkm.ALOLAN_PERSIAN]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.DEINO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.ZWEILOUS]: {
    attack: AnimationType.Jab,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.HYDREIGON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SANDILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KROKOROK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.RearUp
  },
  [Pkm.KROOKODILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SOLOSIS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.DUOSION]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.REUNICLUS]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_RAYQUAZA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SWABLU]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.ODDISH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Twirl
  },
  [Pkm.GLOOM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Twirl
  },
  [Pkm.VILEPLUME]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Twirl
  },
  [Pkm.BELLOSSOM]: {
    attack: AnimationType.Special0,
    ability: AnimationType.Twirl,
    emote: AnimationType.Pose
  },
  [Pkm.AMAURA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.AURORUS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.ANORITH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Scratch,
    emote: AnimationType.Shoot
  },
  [Pkm.ARMALDO]: {
    attack: AnimationType.Scratch,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.ARCHEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.ARCHEOPS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.SHIELDON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.BASTIODON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  /*[Pkm.TIRTOUGA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.CARRACOSTA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },*/
  [Pkm.LILEEP]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.CRADILY]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.CRANIDOS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.RAMPARDOS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.KABUTO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KABUTOPS]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.OMANYTE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.OMASTAR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.TYRUNT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Bite,
    emote: AnimationType.RearUp,
    shinyUnavailable: true
  },
  [Pkm.TYRANTRUM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.BUDEW]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.ROSELIA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.ROSERADE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.BUNEARY]: {
    attack: AnimationType.QuickStrike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.LOPUNNY]: {
    attack: AnimationType.QuickStrike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_LOPUNNY]: {
    attack: AnimationType.QuickStrike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.AXEW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.FRAXURE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.HAXORUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.VENIPEDE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.WHIRLIPEDE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SCOLIPEDE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.PORYGON]: {
    attack: AnimationType.RearUp,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.PORYGON_2]: {
    attack: AnimationType.RearUp,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.PORYGON_Z]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.ELECTRIKE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.MANECTRIC]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_MANECTRIC]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.SHUPPET]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.BANETTE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_BANETTE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.HONEDGE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Head,
    emote: AnimationType.Shoot
  },
  [Pkm.DOUBLADE]: {
    attack: AnimationType.Slice,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.AEGISLASH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.AEGISLASH_BLADE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Special0,
    emote: AnimationType.Shoot
  },
  [Pkm.CUBONE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Pose
  },
  [Pkm.MAROWAK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.ALOLAN_MAROWAK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Punch,
    emote: AnimationType.Charge
  },
  [Pkm.WHISMUR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Appeal,
    emote: AnimationType.Shoot
  },
  [Pkm.LOUDRED]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Appeal,
    emote: AnimationType.Shoot
  },
  [Pkm.EXPLOUD]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TYMPOLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.PALPITOAD]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.SEISMITOAD]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SEWADDLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SWADLOON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.LEAVANNY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  /*[Pkm.PIKIPEK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.TRUMBEAK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.TOUCANNON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },*/
  [Pkm.FLABEBE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot
  },
  [Pkm.FLOETTE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.FLORGES]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.JANGMO_O]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.HAKAMO_O]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KOMMO_O]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MELOETTA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.PIROUETTE_MELOETTA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Twirl
  },
  [Pkm.ALTARIA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_ALTARIA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.CASTFORM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.CASTFORM_SUN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CASTFORM_RAIN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.CASTFORM_HAIL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.CORPHISH]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.CRAWDAUNT]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.JOLTIK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.GALVANTULA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.GENESECT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.RELICANTH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.HATENNA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.HATTREM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.HATTERENE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.FENNEKIN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BRAIXEN]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.DELPHOX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MAKUHITA]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HARIYAMA]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.REGIELEKI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Attack
  },
  [Pkm.REGIDRAGO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.GUZZLORD]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ETERNATUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge
  },
  [Pkm.PONYTA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Walk,
    emote: AnimationType.RearUp
  },
  [Pkm.RAPIDASH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Walk,
    emote: AnimationType.RearUp
  },
  [Pkm.GALARIAN_PONYTA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Walk,
    emote: AnimationType.RearUp
  },
  [Pkm.GALARIAN_RAPIDASH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Walk,
    emote: AnimationType.RearUp
  },
  [Pkm.NINCADA]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.NINJASK]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SHEDINJA]: {
    attack: AnimationType.Scratch,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.NOIBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.NOIVERN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.PUMPKABOO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOURGEIST]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.CACNEA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.CACTURNE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.TAUROS]: {
    attack: AnimationType.Stomp,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.HAPPINY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shake,
    emote: AnimationType.Shoot
  },
  [Pkm.CHANSEY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.BLISSEY]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TAPU_KOKO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.TAPU_LELE]: {
    attack: AnimationType.Hop,
    ability: AnimationType.Charge,
    emote: AnimationType.Pose
  },
  [Pkm.STAKATAKA]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Sleep,
    emote: AnimationType.Shoot
  },
  [Pkm.BLACEPHALON]: {
    attack: AnimationType.Hop,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HOUNDOUR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.HOUNDOOM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.MEGA_HOUNDOOM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shake,
    emote: AnimationType.Shoot
  },
  [Pkm.CLAMPERL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.HUNTAIL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOREBYSS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SMOOCHUM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Twirl
  },
  [Pkm.JYNX]: {
    attack: AnimationType.Slap,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SALANDIT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.SALAZZLE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.VENONAT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.VENOMOTH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.FlapAround
  },
  [Pkm.VOLTORB]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Pose
  },
  [Pkm.ELECTRODE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Twirl
  },
  [Pkm.HISUI_VOLTORB]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Pose,
    emote: AnimationType.Twirl
  },
  [Pkm.HISUI_ELECTRODE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Twirl,
    shinyUnavailable: true
  },
  [Pkm.SLUGMA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGCARGO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.SNEASEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.WEAVILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.CROAGUNK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.TOXICROAK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.CHINCHOU]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.LANTURN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.POOCHYENA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MIGHTYENA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.BRONZOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.BRONZONG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.DRIFLOON]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot
  },
  [Pkm.DRIFBLIM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Idle,
    emote: AnimationType.Shoot
  },
  [Pkm.SHROOMISH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.BRELOOM]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.TENTACOOL]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Shoot,
    emote: AnimationType.Twirl
  },
  [Pkm.TENTACRUEL]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SNUBULL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GRANBULL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SEVIPER]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.VULPIX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Pose
  },
  [Pkm.NINETALES]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.DeepBreath
  },
  [Pkm.ALOLAN_VULPIX]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Pose
  },
  [Pkm.ALOLAN_NINETALES]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.BUIZEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.FLOATZEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MAWILE]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.KECLEON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.CARBINK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Rotate
  },
  [Pkm.DIANCIE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.CHATOT]: {
    attack: AnimationType.Special0,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.GOOMY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SLIGOO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOODRA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.HISUI_SLIGGOO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.HISUI_GOODRA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MEW]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BOUNSWEET]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.STEENEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.TSAREENA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Kick,
    emote: AnimationType.Shoot
  },
  [Pkm.VOLCANION]: {
    attack: AnimationType.Charge,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.APPLIN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.APPLETUN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.OSHAWOTT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Pose
  },
  [Pkm.DEWOTT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.SAMUROTT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.SNOM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.FROSMOTH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.WAILMER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.WAILORD]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Shoot
  },
  [Pkm.DREEPY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DRAKLOAK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DRAGAPULT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SNIVY]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Appeal,
    emote: AnimationType.Shoot
  },
  [Pkm.SERVINE]: {
    attack: AnimationType.Slice,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SERPERIOR]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SCORBUNNY]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Kick,
    emote: AnimationType.Shoot
  },
  [Pkm.RABOOT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Kick,
    emote: AnimationType.Shoot
  },
  [Pkm.CINDERACE]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Kick,
    emote: AnimationType.Shoot
  },
  [Pkm.POPPLIO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BRIONNE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.PRIMARINA]: {
    attack: AnimationType.Charge,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOTHITA]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GOTHORITA]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.GOTHITELLE]: {
    attack: AnimationType.Appeal,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SANDSHREW]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.LostBalance
  },
  [Pkm.SANDSLASH]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.DeepBreath
  },
  [Pkm.FARFETCH_D]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.UNOWN_A]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_B]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_C]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_D]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_E]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_F]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_G]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_H]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_I]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_J]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_K]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_L]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_M]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_N]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_O]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_P]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_Q]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_R]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_S]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_T]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_U]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_V]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_W]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_X]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_Y]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_Z]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_QUESTION]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.UNOWN_EXCLAMATION]: {
    attack: AnimationType.Rotate,
    ability: AnimationType.Rotate,
    emote: AnimationType.Rotate
  },
  [Pkm.TAPU_FINI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TAPU_BULU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DIGLETT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DigIn,
    emote: AnimationType.Special0
  },
  [Pkm.ALOLAN_DIGLETT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DigIn,
    emote: AnimationType.Special0
  },
  [Pkm.DUGTRIO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DigIn,
    emote: AnimationType.Special0
  },
  [Pkm.ALOLAN_DUGTRIO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Walk,
    emote: AnimationType.Hop
  },
  [Pkm.ROWLET]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DARTIX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DECIDUEYE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ZORUA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.ZOROARK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HISUI_ZORUA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HISUI_ZOROARK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.FROAKIE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.FROGADIER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.GRENINJA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hurt,
    emote: AnimationType.Attack
  },
  [Pkm.TYROGUE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HITMONLEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Kick,
    emote: AnimationType.Withdraw
  },
  [Pkm.HITMONCHAN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Uppercut,
    emote: AnimationType.Punch
  },
  [Pkm.HITMONTOP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.MIMIKYU]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MIMIKYU_BUSTED]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GRIMER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.MUK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.ALOLAN_GRIMER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.ALOLAN_MUK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.CARVANHA]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot
  },
  [Pkm.SHARPEDO]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Swell,
    emote: AnimationType.Shoot
  },
  [Pkm.PINECO]: {
    attack: AnimationType.Ricochet,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.FORRETRESS]: {
    attack: AnimationType.Ricochet,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SEEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DEWGONG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_GEODUDE]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_GRAVELER]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ALOLAN_GOLEM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.EKANS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ARBOK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MIME_JR]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.MR_MIME]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ORIGIN_GIRATINA]: {
    attack: AnimationType.Scratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MELMETAL]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Punch,
    emote: AnimationType.Charge
  },
  [Pkm.HOOPA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Hop
  },
  [Pkm.HOOPA_UNBOUND]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Cringe
  },
  [Pkm.SILVALLY]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TYPE_NULL]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ZERAORA]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.XERNEAS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.YVELTAL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MARSHADOW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HOOTHOOT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.NOCTOWL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BONSLEY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SUDOWOODO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Slam,
    emote: AnimationType.Charge
  },
  [Pkm.PHIONE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.COMBEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.VESPIQUEEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Shoot
  },
  [Pkm.SHUCKLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.TEPIG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.PIGNITE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.EMBOAR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.WYNAUT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.WOBBUFFET]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.LUNATONE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.SOLROCK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.POLIWRATH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.SHAYMIN_SKY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.WURMPLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SILCOON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.BEAUTIFLY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.CASCOON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.DUSTOX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.FlapAround,
    emote: AnimationType.Shoot
  },
  [Pkm.TINKATINK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Strike,
    emote: AnimationType.Strike
  },
  [Pkm.TINKATUFF]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Strike,
    emote: AnimationType.Strike
  },
  [Pkm.TINKATON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Cringe
  },
  [Pkm.PARAS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.PARASECT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MILTANK]: {
    attack: AnimationType.Stomp,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MANKEY]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Charge,
    emote: AnimationType.Twirl
  },
  [Pkm.PRIMEAPE]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Charge,
    emote: AnimationType.Strike
  },
  [Pkm.ANNIHILAPE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.RearUp
  },
  [Pkm.SUNKERN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SUNFLORA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.MARACTUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.PLUSLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.MINUN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.PINSIR]: {
    attack: AnimationType.Slice,
    ability: AnimationType.Attack,
    emote: AnimationType.Slice
  },
  [Pkm.NATU]: {
    attack: AnimationType.Jab,
    ability: AnimationType.Charge,
    emote: AnimationType.Hover
  },
  [Pkm.XATU]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.GLIGAR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.MultiStrike,
    emote: AnimationType.Shoot
  },
  [Pkm.GLISCOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.SHELLDER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.CLOYSTER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.SENTRET]: {
    attack: AnimationType.Strike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.FURRET]: {
    attack: AnimationType.Strike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.SPECTRIER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.TORKOAL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.DELIBIRD]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.IRON_BUNDLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.KARTANA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.CHINGLING]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.CHIMECHO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Twirl,
    emote: AnimationType.Pose
  },
  [Pkm.ALOLAN_RAICHU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.DHELMISE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KOFFING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Gas,
    emote: AnimationType.Shoot
  },
  [Pkm.WEEZING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Gas,
    emote: AnimationType.Shoot
  },
  [Pkm.STARYU]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.STARMIE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.NOSEPASS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.PROBOPASS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.WOOBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Hover
  },
  [Pkm.SWOOBAT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.Hover
  },
  [Pkm.CLAUNCHER]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Double,
    emote: AnimationType.Charge
  },
  [Pkm.CLAWITZER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Double,
    emote: AnimationType.Shoot
  },
  [Pkm.YANMA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.YANMEGA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.QuickStrike,
    emote: AnimationType.Shoot
  },
  [Pkm.HELIOPTILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.HELIOLISK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.BIDOOF]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BIBAREL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SPINDA]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Twirl,
    emote: AnimationType.Shoot
  },
  [Pkm.BALTOY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.RearUp
  },
  [Pkm.CLAYDOL]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Rotate,
    emote: AnimationType.RearUp
  },
  [Pkm.HERACROSS]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Slam,
    emote: AnimationType.Charge
  },
  [Pkm.PURRLOIN]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.LIEPARD]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.BARBOACH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Dance
  },
  [Pkm.WHISCASH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Dance
  },
  [Pkm.SCRAGGY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SCRAFTY]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.FINNEON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.LUMINEON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.STUNKY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.SKUNTANK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Shoot
  },
  [Pkm.ILLUMISE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.VOLBEAT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Appeal,
    emote: AnimationType.Appeal
  },
  [Pkm.NECROZMA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Idle,
    emote: AnimationType.Charge
  },
  [Pkm.ULTRA_NECROZMA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Charge
  },
  [Pkm.CHERRUBI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shake
  },
  [Pkm.CHERRIM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shake
  },
  [Pkm.CHERRIM_SUNLIGHT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Dance
  },
  [Pkm.MISDREAVUS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.MISMAGIUS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.Charge
  },
  [Pkm.DODUO]: {
    attack: AnimationType.Jab,
    ability: AnimationType.Charge,
    emote: AnimationType.MultiStrike
  },
  [Pkm.DODRIO]: {
    attack: AnimationType.Jab,
    ability: AnimationType.Charge,
    emote: AnimationType.MultiStrike
  },
  [Pkm.XURKITREE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.TANDEMAUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.MAUSHOLD_THREE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.MAUSHOLD_FOUR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.KRICKETOT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Shoot
  },
  [Pkm.KRICKETUNE]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.HIPPOPOTAS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.HIPPODOWN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.WINGULL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.PELIPPER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.SpAttack
  },
  [Pkm.NIHILEGO]: {
    attack: AnimationType.Slam,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SOBBLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DRIZZILE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.INTELEON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Strike
  },
  [Pkm.TROPIUS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.EXEGGCUTE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Twirl
  },
  [Pkm.EXEGGUTOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Dance
  },
  [Pkm.ALOLAN_EXEGGUTOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Dance
  },
  [Pkm.COMFEY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Hop
  },
  [Pkm.CARNIVINE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.HISUIAN_QWILFISH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Withdraw
  },
  [Pkm.OVERQWIL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.HISUIAN_TYPHLOSION]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.LILLIPUP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.HERDIER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.STOUTLAND]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.ZIGZAGOON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.LINOONE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.PHEROMOSA]: {
    attack: AnimationType.Kick,
    ability: AnimationType.Swing,
    emote: AnimationType.Charge
  },
  [Pkm.SABLEYE]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.MEGA_SABLEYE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.DRACOVISH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Hop,
    shinyUnavailable: true
  },
  [Pkm.CORSOLA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shake,
    emote: AnimationType.Shake
  },
  [Pkm.GALAR_CORSOLA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shake
  },
  [Pkm.CURSOLA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.SpAttack
  },
  [Pkm.GIMMIGHOUL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.RearUp
  },
  [Pkm.GHOLDENGO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.PHANTUMP]: {
    attack: AnimationType.Strike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.TREVENANT]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Swing,
    emote: AnimationType.Charge
  },
  [Pkm.SMEARGLE]: {
    attack: AnimationType.SpAttack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Pose
  },
  [Pkm.TOXEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TOXTRICITY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.BRUXISH]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.SUBSTITUTE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.CYCLIZAR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.PAWNIARD]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Appeal
  },
  [Pkm.BISHARP]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Pose
  },
  [Pkm.KINGAMBIT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.MINIOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.MINIOR_KERNEL_RED]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.MINIOR_KERNEL_BLUE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.MINIOR_KERNEL_ORANGE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.MINIOR_KERNEL_GREEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.FEEBAS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Dance,
    emote: AnimationType.Charge
  },
  [Pkm.MILOTIC]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.RearUp,
    emote: AnimationType.RearUp
  },
  [Pkm.MORPEKO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Shoot
  },
  [Pkm.MORPEKO_HANGRY]: {
    attack: AnimationType.Swing,
    ability: AnimationType.Special0,
    emote: AnimationType.Rotate
  },
  [Pkm.KANGASKHAN]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.TEDDIURSA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Appeal
  },
  [Pkm.URSARING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiStrike,
    emote: AnimationType.Shoot
  },
  [Pkm.URSALUNA]: {
    attack: AnimationType.Idle, // TEMP while waiting for anims to be drawn
    ability: AnimationType.Idle, // TEMP while waiting for anims to be drawn
    emote: AnimationType.Idle // TEMP while waiting for anims to be drawn
  },
  [Pkm.URSALUNA_BLOODMOON]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.AIPOM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Dance,
    emote: AnimationType.Dance
  },
  [Pkm.AMBIPOM]: {
    attack: AnimationType.MultiStrike,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.DEERLING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.SAWSBUCK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.LICKITUNG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Lick,
    emote: AnimationType.Shoot
  },
  [Pkm.LICKILICKY]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Withdraw
  },
  [Pkm.PATRAT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.WATCHOG]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Rotate,
    emote: AnimationType.Shoot
  },
  [Pkm.SPINARAK]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ARIADOS]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.DEWPIDER]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ARAQUANID]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.ROCKRUFF]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.DeepBreath
  },
  [Pkm.LYCANROC_DAY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.LYCANROC_DUSK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.LYCANROC_NIGHT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.QuickStrike,
    emote: AnimationType.Shoot
  },
  [Pkm.DRUDDIGON]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.COSMOG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Rotate
  },
  [Pkm.COSMOEM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Rotate
  },
  [Pkm.SOLGALEO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Attack
  },
  [Pkm.LUNALA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MAGEARNA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.IMPIDIMP]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.MORGREM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.GRIMMSNARL]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge
  },
  [Pkm.DEOXYS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Emit
  },
  [Pkm.DEOXYS_DEFENSE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Withdraw,
    emote: AnimationType.Shoot
  },
  [Pkm.DEOXYS_ATTACK]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.DEOXYS_SPEED]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.CRABRAWLER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.CRABOMINABLE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.CUTIEFLY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.RIBOMBEE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.ZANGOOSE]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.NICKIT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.THIEVUL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.DROWZEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.HYPNO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.WATTREL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.FlapAround
  },
  [Pkm.KILOWATTREL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.FlapAround
  },
  [Pkm.STANTLER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.BURMY_PLANT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Attack
  },
  [Pkm.BURMY_SANDY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Attack
  },
  [Pkm.BURMY_TRASH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Attack
  },
  [Pkm.WORMADAM_PLANT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shake
  },
  [Pkm.WORMADAM_SANDY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shake
  },
  [Pkm.WORMADAM_TRASH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Swing,
    emote: AnimationType.Shake
  },
  [Pkm.MOTHIM]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Hover
  },
  [Pkm.PALDEA_WOOPER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Pose
  },
  [Pkm.CLODSIRE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.FUECOCO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.CROCALOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.SKELEDIRGE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp,
    shinyUnavailable: true
  },
  [Pkm.TANGELA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shake
  },
  [Pkm.TANGROWTH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Emit
  },
  [Pkm.PSYDUCK]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.GOLDUCK]: {
    attack: AnimationType.MultiScratch,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.PHANPY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.TumbleBack,
    emote: AnimationType.Eat
  },
  [Pkm.DONPHAN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.TumbleBack,
    emote: AnimationType.Idle
  },
  [Pkm.SPOINK]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Twirl
  },
  [Pkm.GRUMPIG]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.SINISTEA]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot
  },
  [Pkm.POLTEAGEIST]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hop,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.FERROSEED]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Special0,
    emote: AnimationType.DeepBreath
  },
  [Pkm.FERROTHORN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Special0,
    emote: AnimationType.Special0
  },
  [Pkm.GOLETT]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Tumble,
    emote: AnimationType.Pose
  },
  [Pkm.GOLURK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.TRUBBISH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Pose,
    shinyUnavailable: true
  },
  [Pkm.GARBODOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Idle,
    shinyUnavailable: true
  },
  [Pkm.GRUBBIN]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.CHARJABUG]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.VIKAVOLT]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.SHELLOS_WEST_SEA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.GASTRODON_WEST_SEA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.SHELLOS_EAST_SEA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.GASTRODON_EAST_SEA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MUNNA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.MUSHARNA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Shoot
  },
  [Pkm.KLEFKI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Attack
  },
  [Pkm.RUFFLET]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.BRAVIARY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Shoot
  },
  [Pkm.HEATMOR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.HAWLUCHA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Cringe,
    emote: AnimationType.Kick
  },
  [Pkm.MIENFOO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Charge
  },
  [Pkm.MIENSHAO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.STONJOURNER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shake,
    emote: AnimationType.Shake
  },
  [Pkm.HISUI_SNEASEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Pose
  },
  [Pkm.SNEASLER]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Attack,
    emote: AnimationType.Charge
  },
  [Pkm.POIPOLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.NAGANADEL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Shoot
  },
  [Pkm.CRAMORANT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.ARROKUDA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.WISHIWASHI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Shoot
  },
  [Pkm.WISHIWASHI_SCHOOL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.RearUp,
    shinyUnavailable: true
  },
  [Pkm.PAWMI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.PAWMO]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Charge
  },
  [Pkm.PAWMOT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shock,
    emote: AnimationType.Charge
  },
  [Pkm.PYUKUMUKU]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Rumble
  },
  [Pkm.GOLDEEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.RearUp
  },
  [Pkm.SEAKING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.RearUp
  },
  [Pkm.LUVDISC]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.Twirl
  },
  [Pkm.AUDINO]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Pose,
    emote: AnimationType.Pose
  },
  [Pkm.PETILIL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Twirl,
    emote: AnimationType.Pose
  },
  [Pkm.LILIGANT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.DeepBreath,
    emote: AnimationType.Pose
  },
  [Pkm.MANTYKE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Strike
  },
  [Pkm.MANTINE]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Hover,
    emote: AnimationType.Strike
  },
  [Pkm.REMORAID]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.RearUp
  },
  [Pkm.OCTILLERY]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Attack,
    emote: AnimationType.RearUp
  },
  [Pkm.SIGILYPH]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.FRIGIBAX]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.ARCTIBAX]: {
    attack: AnimationType.Scratch,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.BAXCALIBUR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.BINACLE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.RearUp,
    emote: AnimationType.Charge
  },
  [Pkm.BARBARACLE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.SKARMORY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.DURANT]: {
    attack: AnimationType.Bite,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.OGERPON_TEAL]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.OGERPON_TEAL_MASK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.OGERPON_WELLSPRING]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.OGERPON_WELLSPRING_MASK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.OGERPON_HEARTHFLAME]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.OGERPON_HEARTHFLAME_MASK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.OGERPON_CORNERSTONE]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.OGERPON_CORNERSTONE_MASK]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.IRON_HANDS]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Hop
  },
  [Pkm.ROOKIDEE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.CORVISQUIRE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.CORVIKNIGHT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.MURKROW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.HONCHKROW]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.SANDYGAST]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.PALOSSAND]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.TURTONATOR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Idle
  },
  [Pkm.SKORUPI]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Jab,
    emote: AnimationType.Shoot
  },
  [Pkm.DRAPION]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Jab,
    emote: AnimationType.Shoot
  },
  [Pkm.DARUMAKA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Attack,
    emote: AnimationType.Dance
  },
  [Pkm.DARMANITAN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.QuickStrike,
    emote: AnimationType.Rumble
  },
  [Pkm.DARMANITAN_ZEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.KRABBY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Slam,
    emote: AnimationType.Shoot
  },
  [Pkm.KINGLER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot
  },
  [Pkm.ZYGARDE_10]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.RearUp
  },
  [Pkm.ZYGARDE_50]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.ZYGARDE_100]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Emit,
    emote: AnimationType.Emit,
    shinyUnavailable: true
  },
  [Pkm.SIZZLIPEDE]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.CENTISKORCH]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.STUFFUL]: {
    attack: AnimationType.Attack,
    ability: AnimationType.SpAttack,
    emote: AnimationType.Charge
  },
  [Pkm.BEWEAR]: {
    attack: AnimationType.Slap,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.GLIMMET]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Twirl,
    emote: AnimationType.Charge
  },
  [Pkm.GLIMMORA]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.FLETCHLING]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Shoot
  },
  [Pkm.FLETCHINDER]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.TALONFLAME]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Hover,
    emote: AnimationType.Shoot
  },
  [Pkm.VULLABY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge,
    shinyUnavailable: true
  },
  [Pkm.MANDIBUZZ]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.INKAY]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Charge,
    emote: AnimationType.Twirl
  },
  [Pkm.MALAMAR]: {
    attack: AnimationType.Strike,
    ability: AnimationType.Shoot,
    emote: AnimationType.Charge
  },
  [Pkm.TIMBURR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Swing,
    emote: AnimationType.Pose
  },
  [Pkm.GURDURR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Punch,
    emote: AnimationType.RearUp,
    shinyUnavailable: true
  },
  [Pkm.CONKELDURR]: {
    attack: AnimationType.Attack,
    ability: AnimationType.Strike,
    emote: AnimationType.Shoot,
    shinyUnavailable: true
  },
  [Pkm.PILLAR_WOOD]: {
    attack: AnimationType.Idle,
    ability: AnimationType.Idle,
    emote: AnimationType.Idle,
    shinyUnavailable: true,
    noShadow: true
  },
  [Pkm.PILLAR_IRON]: {
    attack: AnimationType.Idle,
    ability: AnimationType.Idle,
    emote: AnimationType.Idle,
    shinyUnavailable: true,
    noShadow: true
  },
  [Pkm.PILLAR_CONCRETE]: {
    attack: AnimationType.Idle,
    ability: AnimationType.Idle,
    emote: AnimationType.Idle,
    shinyUnavailable: true,
    noShadow: true
  },
  [Pkm.ELGYEM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.Charge,
    emote: AnimationType.DeepBreath
  },
  [Pkm.BEHEEYEM]: {
    attack: AnimationType.Shoot,
    ability: AnimationType.SpAttack,
    emote: AnimationType.SpAttack
  },
  [Pkm.LITTEN]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Pose
  },
  [Pkm.TORRACAT]: {
    attack: AnimationType.Attack,
    ability: AnimationType.MultiScratch,
    emote: AnimationType.Emit
  },
  [Pkm.INCINEROAR]: {
    attack: AnimationType.Punch,
    ability: AnimationType.Ricochet,
    emote: AnimationType.Pose,
    shinyUnavailable: true
  }
}
